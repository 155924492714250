import { RemittanceStatus } from './remittance-status';
import { Deserializable } from '../protocols/deserializable';
import { Searchable } from '../protocols/searchable';
import { RemittanceStatusPill } from '../shared/remittance-status-pill';
import { NumberUtils } from '../../utils/number-utils';
import { RemittanceStatusIdEnum } from '../enum/shared/remittance-status-id.enum';

export class ScheduledRemittanceLinkedRemittance implements Deserializable, Searchable {
  public id!: number;
  public submittedDate!: Date;
  public statusId!: number;
  public status!: RemittanceStatus;

  // not from API
  public statusPill!: RemittanceStatusPill;
  public formattedId!: string;

  // Searchable
  public title!: string;
  public value!: any;

  public isProcessing(): boolean {
    return this.status.id !== RemittanceStatusIdEnum.Pending;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.status = Deserialize?.instanceOf(RemittanceStatus, this.status);
    this.submittedDate = new Date(this.submittedDate);
    this.title = this.status.name;
    this.value = this.id;
    this.statusPill = new RemittanceStatusPill(this.status.id, this.status.name);
    this.formattedId = `R-${NumberUtils.formatNumberWithLeadingZeros(this.id, 6)}`;
  }
}
