<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky modal-container" cdkScrollable data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="modal-title">{{ reorderOptions.title }}</div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div #modalBody class="sticky-body-container reorder-container">
    <!-- Modal Body -->
    <div class="reorder-subtitle" [hidden]="reorderOptions.subTitle === ''">
      {{ reorderOptions.subTitle }}
    </div>
    <div class="reorder-body">
      {{ reorderOptions.bodyText }}
    </div>
    <!--  Reorder list  -->
    <app-reorder-list
      [items]="items"
      [showIcons]="reorderOptions.displayIcons"
      [orderPrefix]="reorderOptions.orderPrefix"
      [showVisibility]="reorderOptions.showVisibility">
    </app-reorder-list>
  </div>

  <div class="sticky-footer-container">
    <div class="reorder-footer">
      <div>
        <button [hidden]="!reorderOptions.showReset" class="primary-paddingless-text-button">
          {{ 'Reset to Default Order' | localization }}
        </button>
      </div>
      <div class="modal-footer-flex-end" [style.gap.rem]="1">
        <lib-button-neutral (buttonClicked)="cancel()">
          {{ reorderOptions.cancelText }}
        </lib-button-neutral>
        <lib-button-primary (buttonClicked)="continue()">
          {{ reorderOptions.confirmText }}
        </lib-button-primary>
      </div>
    </div>
  </div>
</div>
