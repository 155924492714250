export enum AlertBackgroundColorEnum {
  CardPurple = 1,
  CardBlue = 2,
  CardGreen = 3,
  CardYellow = 4,
  BannerPurple = 5,
  BannerBlue = 6,
  BannerGreen = 7,
  BannerYellow = 8
}
