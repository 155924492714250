import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MemberAuthViewModel } from '../../../../viewModels/member-auth-view-model';
import { AssetSize } from '../../../../../../models/enum/dto/asset-size.enum';

@Component({
  selector: 'app-member-send-email-verification',
  templateUrl: './member-send-email-verification.component.html',
  styleUrls: ['./member-send-email-verification.component.scss', '../../auth.component.scss']
})
export class MemberSendEmailVerificationComponent extends BaseComponent {
  viewModel = inject(MemberAuthViewModel);

  constructor() {
    super();
  }

  setupBindings(): void {}

  setupViews(): void {}

  protected readonly AssetSize = AssetSize;
}
