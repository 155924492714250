<div class="modal-body sticky modal-container" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row justify-content-between">
      <div class="modal-title">{{ title }}</div>
      <app-close-button *ngIf="!hideClose" (closeClicked)="closeClicked.emit()"></app-close-button>
    </div>
  </div>
  <div class="sticky-body-container">
    <ng-content></ng-content>
  </div>
  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral
      [ngClass]="{ 'secondary-button': !hidePrimary }"
      (buttonClicked)="secondaryClicked.emit()"
      *ngIf="!hideSecondary">
      {{ secondaryLabel }}
    </lib-button-neutral>
    <lib-button-primary (buttonClicked)="primaryClicked.emit()" appThrottleClick *ngIf="!hidePrimary">
      {{ primaryLabel }}
    </lib-button-primary>
  </div>
</div>
