import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MemberEmploymentHistoryFormViewModel } from './member-employment-history-form-view-model';
import { EmploymentHistory } from '../../../../models/account/dto/employment-history';
import { BaseMemberRecordCardComponent } from '../../../records/components/members/view-member-record/base-member-record-card-component';
import { map } from 'rxjs/operators';
import { EmploymentHistoryStatus } from '../../../../models/account/enum/employment-history-status';
import { StringExtensions } from '../../../../utils/string.extensions';

@Component({
  selector: 'app-member-employment-history-form',
  templateUrl: './member-employment-history-form.component.html',
  styleUrls: [
    './member-employment-history-form.component.scss',
    '../../../records/components/members/view-member-record/member-overview/member-overview.component.scss'
  ],
  providers: [MemberEmploymentHistoryFormViewModel]
})
export class MemberEmploymentHistoryFormComponent extends BaseMemberRecordCardComponent implements OnChanges {
  @Input() employmentHistory!: EmploymentHistory;
  @Input() showEditButton: boolean = true;

  public viewModel = inject(MemberEmploymentHistoryFormViewModel);
  public req$ = this.viewModel.employmentHistory$;
  public employmentStatusDropdowns$ = this.viewModel.employmentStatusDropdowns$;
  public leaveTypeDropdowns$ = this.viewModel.leaveTypeDropdowns$;
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  public showLeaveDetails$ = this.viewModel.selectedEmploymentStatus$.pipe(
    map(status => status === EmploymentHistoryStatus.Leave)
  );

  public listenToCloseAccordion = this.viewModel.historyUpdated$.subscribeWhileAlive({
    owner: this,
    next: h => {
      if (!!h) {
        this.accordionStatusService.closeAllAccordions();
      }
    }
  });

  public idIndex = Math.floor(Math.random() * 101);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.employmentHistory) this.viewModel.setEmploymentHistory(this.employmentHistory);
  }

  public isLoading$ = this.viewModel.isLoading$;
  public loadingOpts$ = this.viewModel.loadingOpts$;
  public panelName: string = 'employmentHistory' + this.idIndex;

  formSubmitted(req: EmploymentHistory): void {
    this.viewModel.formSubmitted(req);
  }

  public setEmploymentStatus(employmentStatusId: number): void {
    this.viewModel.setEmploymentStatus(employmentStatusId);
  }

  protected readonly StringExtensions = StringExtensions;
}
