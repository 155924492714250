import { Deserializable } from '../../protocols/deserializable';
import * as moment from 'moment/moment';
import { LeaveDetailsType } from '../../types/dto/leave-details-type';

export class LeaveDetails implements Deserializable {
  public id!: string;
  public leaveTypeId!: number;
  public leaveType!: LeaveDetailsType;
  public startDate!: Date;
  public expectedReturnDate!: Date | null;

  // Not from API
  public formattedStartDate!: string;
  public formattedExpectedReturnDate!: string;

  onDeserialize(): void {
    if (!!this.startDate) {
      const date = moment(this.startDate.toString().split('T')[0]);
      this.startDate = new Date(this.startDate);
      this.formattedStartDate = date.format('DD / MM / YYYY');
    }
    if (!!this.expectedReturnDate) {
      const date = moment(this.expectedReturnDate.toString().split('T')[0]);
      this.expectedReturnDate = new Date(this.expectedReturnDate);
      this.formattedExpectedReturnDate = date.format('DD / MM / YYYY');
    }
    if (!!this.leaveType) {
      this.leaveType = window?.injector?.Deserialize?.instanceOf(LeaveDetailsType, this.leaveType);
    }
  }
}
