import { inject, Injectable } from '@angular/core';
import { EmploymentHistory } from '../../../../models/account/dto/employment-history';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MembersDomainModel } from '../../../../domainModels/members-domain-model';
import { BaseMemberRecordCardViewModel } from '../../../records/components/members/view-member-record/base-member-record-card-view-model';
import { TypeService } from '../../../../services/type/type-service';
import { map } from 'rxjs/operators';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';
import { ToastService } from '../../../../services/toast-service';

@Injectable()
export class MemberEmploymentHistoryFormViewModel extends BaseMemberRecordCardViewModel {
  constructor() {
    super();
  }

  private membersDomainModel = inject(MembersDomainModel);
  private typeService = inject(TypeService);
  private toastService = inject(ToastService);

  private _employmentHistory = new BehaviorSubject<EmploymentHistory | null>(null);
  public employmentHistory$ = this._employmentHistory as Observable<EmploymentHistory | null>;
  public setEmploymentHistory = (eh: EmploymentHistory) => this._employmentHistory.next(eh);

  private _selectedEmploymentStatus = new BehaviorSubject<number>(0);
  public selectedEmploymentStatus$ = this._selectedEmploymentStatus as Observable<number>;

  public historyUpdated$ = new Subject<EmploymentHistory>();

  private listenToEmploymentHistory = this.employmentHistory$.subscribeWhileAlive({
    owner: this,
    next: eh => {
      if (!!eh?.leaveDetails) this.setEmploymentStatus(eh?.employmentStatusId);
    }
  });

  public employmentStatusDropdowns$ = this.typeService.memberEmploymentStatusTypes$.pipe(
    map(types => {
      return types.map(t => new DropDownItem(t?.name, t?.id));
    })
  );

  public leaveTypeDropdowns$ = this.typeService.memberEmploymentLeaveTypes$.pipe(
    map(types => {
      return types.map(t => new DropDownItem(t?.name, t?.id));
    })
  );

  formSubmitted(req: EmploymentHistory): void {
    const lm = '';
    this._loadingOpts.addRequest(lm);
    req.startDate = new Date(req.formattedStartDate);
    req.endDate = req.formattedEndDate ? new Date(req.formattedEndDate) : null;
    if (!!req.leaveDetailsId && !!req.leaveDetails) {
      req.leaveDetails.startDate = new Date(req.leaveDetails.formattedStartDate);
      req.leaveDetails.expectedReturnDate = new Date(req.leaveDetails.formattedExpectedReturnDate);
    }
    this.membersDomainModel.updateEmployerHistory(req).subscribe({
      next: updated => {
        this.toastService.publishSuccessMessage($localize`Employment history updated successfully`);
        this.historyUpdated$.next(updated);
        this._employmentHistory.next(updated);
      },
      error: err => this.toastService.publishError(err),
      complete: () => this._loadingOpts.removeRequest(lm)
    });
  }

  public setEmploymentStatus(id: number): void {
    this._selectedEmploymentStatus.next(id);
  }
}
