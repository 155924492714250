import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { animate, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-sliding-window',
  templateUrl: './sliding-window.component.html',
  styleUrls: ['./sliding-window.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }), // Slide in from the right
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }), // Start from the current position
        animate('200ms ease-out', style({ transform: 'translateX(100%)' })) // Slide out to the right
      ])
    ])
  ]
})
export class SlidingWindowComponent extends BaseComponent implements OnChanges {
  @Input() toggleWindow: boolean | null = false;
  @Input() closeTrigger: Subject<void> = new Subject<void>();

  constructor() {
    super();
  }

  private _showWindow = new BehaviorSubject<boolean>(false);
  public readonly showWindow$ = this._showWindow as Observable<boolean>;

  setupBindings(): void {
    const closeSub = this.closeTrigger.subscribe(() => {
      this.closeWindow();
    });
    this.pushSub(closeSub);
  }

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toggleWindow) {
      this._showWindow.next(!!this.toggleWindow);
    }
  }

  closeWindow(): void {
    this._showWindow.next(false);
  }
}
