import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { BaseCommentRequest } from '../../../../models/base/base-comment-request';
import { BaseComment } from '../../../../models/base/base-comment';
import { BaseComponent } from '../../../../models/base/base-component';
import { UserTaggingDropdownItem } from '../../../../models/shared/user-tagging-dropdown-item';
import { TabBarItem } from '../tab-bar/tab-bar-item';

import { SlidingWindowCommentsAndHistoryViewModel } from './sliding-window-comments-and-history-view-model';
import { BaseRemittanceHistory } from '../../../../models/remittances/base-remittance-history';

@Component({
  selector: 'app-sliding-window-comments-and-history',
  templateUrl: './sliding-window-comments-and-history.component.html',
  styleUrls: ['./sliding-window-comments-and-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidingWindowCommentsAndHistoryComponent extends BaseComponent implements OnChanges, OnDestroy {
  private viewModel = inject(SlidingWindowCommentsAndHistoryViewModel);

  @Input() isLoading: boolean = false;
  @Input() history: BaseRemittanceHistory[] = [];
  @Input() comments: BaseComment[] = [];
  @Input() commentReq: BaseCommentRequest = new BaseCommentRequest();
  @Input() dropdownItems: UserTaggingDropdownItem[] = [];
  @Input() buttonMarginBottomRem: number = 1.5;

  @Output() newComment = new EventEmitter<BaseCommentRequest>();
  @Output() filterText = new EventEmitter<string>();

  public activeTab$ = this.viewModel.activeTab$;
  public tabs$ = this.viewModel.tabs$;

  public showCommentWindow$ = this.viewModel.showCommentWindow$;

  public commentWindowOptions$ = this.viewModel.commentWindowOptions$;

  public setActiveTab(tab: TabBarItem): void {
    this.viewModel.setActiveTab(tab);
  }

  public toggleCommentWindow(): void {
    this.viewModel.toggleCommentWindow();
  }

  public primaryButtonClicked() {
    this.viewModel.commentInProgress$.once(commentInProgress => {
      if (commentInProgress) {
        this.viewModel.submitCommentForm();
      } else {
        this.toggleCommentWindow();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.comments) {
      this.viewModel.setComments(this.comments);
    }
    if (!!changes.commentReq) {
      this.viewModel.setCommentReq(this.commentReq);
    }
    if (!!changes.dropdownItems) {
      this.viewModel.setUserDropdownItems(this.dropdownItems);
    }
    if (!!changes.isLoading) {
      this.viewModel.setIsLoadingComments(this.isLoading);
    }
    if (!!changes.history) {
      this.viewModel.setHistory(this.history);
    }
  }

  setupBindings(): void {
    this.viewModel.filterText$.subscribeWhileAlive({
      owner: this,
      next: f => this.filterText.next(f)
    });

    this.viewModel.newComment$.subscribeWhileAlive({
      owner: this,
      next: r => this.newComment.next(r)
    });
  }

  setupViews(): void {}
}
