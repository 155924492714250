import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-view-rows-dropdown',
  templateUrl: './view-rows-dropdown.component.html',
  styleUrls: ['./view-rows-dropdown.component.scss']
})
export class ViewRowsDropdownComponent extends BaseComponent implements OnChanges {
  @Input() initialValue: number = 10;
  @Output() selectionChanged = new EventEmitter<number>();

  public options: number[] = [10, 25, 50, 75, 100];
  public top: number = 10;

  private _showDropdown = new BehaviorSubject<boolean>(false);
  public showDropdown$ = this._showDropdown as Observable<boolean>;

  public toggleDropdown(): void {
    this.showDropdown$.once(s => this._showDropdown.next(!s));
  }

  public closeDropdown(): void {
    this._showDropdown.next(false);
  }

  public setSelection(option: number): void {
    this.top = option;
    this.selectionChanged.emit(option);
    this.closeDropdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue.firstChange) {
      this.top = this.initialValue;
    }
  }

  setupBindings(): void {}

  setupViews(): void {}
}
