<ngb-datepicker
  class="date-picker"
  (dateSelect)="setCurrentDate($event)"
  [displayMonths]="1"
  [dayTemplate]="t"
  [contentTemplate]="c"
  [footerTemplate]="f"
  [firstDayOfWeek]="7"
  [startDate]="fromDate!"
  tabindex="-1" />
<ng-template #t let-date let-currentMonth="currentMonth">
  <div
    [class.from-date]="isFromDate(date)"
    [class.to-date]="isToDate(date)"
    class="range-background"
    *ngIf="showBackground(date)"></div>
  <span
    class="custom-day"
    [class.focused]="isFocused(date)"
    [class.from-date]="isFromDate(date)"
    [class.to-date]="isToDate(date)"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    [class.outside]="date.month !== currentMonth"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #f>
  <div class="action-buttons">
    <button type="button" i18n (click)="reset()">Reset</button>
    <button type="button" i18n (click)="confirm()">Confirm</button>
  </div>
</ng-template>

<ng-template #c let-dp>
  <div class="p-3">
    <div class="navigation">
      <button type="button" class="navigation-button" (click)="navigate(dp, -1)">
        <img [src]="'assets/icons/dark/Left Chevron.svg'" />
      </button>
      <span class="month">{{ dp.state.focusedDate.month | monthName }}</span>
      <button type="button" class="navigation-button" (click)="navigate(dp, 1)">
        <img [src]="'assets/icons/dark/Right Chevron.svg'" />
      </button>
    </div>
    <div *ngFor="let month of dp.state.months">
      <ngb-datepicker-month [month]="month"></ngb-datepicker-month>
    </div>
  </div>
</ng-template>
