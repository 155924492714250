import { UniquelyIdentifiable } from '../../../../../../models/protocols/uniquely-identifiable';

export class NavSubItem implements UniquelyIdentifiable {
  constructor(name: string, relativeRouterPath: string, iconSrc?: string, isSignOutItem?: boolean) {
    this.name = name;
    this.relativeRouterPath = relativeRouterPath;
    this.iconSrc = iconSrc || '';
    this.isSignOutItem = isSignOutItem || false;
  }

  public name: string;
  // Ie, if parent path is /#/company, and relativeRouterPath = 'general', then total route is /#/company/general
  // therefore, don't include leading slash, WRONG: /general, CORRECT: general
  public relativeRouterPath: string;
  public animating?: boolean = false;
  public savedFragment!: string;
  public dumpCacheOnNavigate: boolean = false;
  public subItemFragment!: string;
  public iconSrc!: string;
  public isSignOutItem: boolean = false;

  public saveSubFragment(fragment: string) {
    this.savedFragment = fragment?.replace('#', '');
  }

  getUniqueIdentifier(...opts: any): string {
    return `
      -name: ${this.name}
      -relativeRouterPath: ${this.relativeRouterPath}
    `;
  }
}
