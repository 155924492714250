import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { ReleaseNoteDocument } from '../models/release-notes/release-note-document';
import { ReleaseNotesAPI } from '../api/release-notes-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { catchError, map, tap } from 'rxjs/operators';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ReleaseNoteDocumentVersion } from '../models/release-notes/release-note-document-version';
import { HttpResponse } from '@angular/common/http';
import { CreateReleaseNoteDocumentRequest } from '../models/release-notes/requests/create-release-note-document-request';
import { UpdateReleaseNoteDocumentRequest } from '../models/release-notes/requests/update-release-note-document-request';
import * as moment from 'moment';
import { PortalTypeIdEnum } from '../models/enum/shared/portal-type-id.enum';

@Injectable()
export class ReleaseNotesDomainModel extends BaseDomainModel {
  constructor() {
    super();
    this.fetchLatestReleaseNoteDocument();
  }

  private releaseNotesAPI = inject(ReleaseNotesAPI);

  private _releaseNoteDocuments = new BehaviorSubject<ReleaseNoteDocument[] | null>(null);
  public releaseNoteDocuments$ = this._releaseNoteDocuments as Observable<ReleaseNoteDocument[] | null>;

  private _releaseNoteDocumentsTotalCount = new BehaviorSubject<number>(0);
  public releaseNoteDocumentsTotalCount$ = this._releaseNoteDocumentsTotalCount as Observable<number>;

  private _releaseNoteDocumentVersions = new BehaviorSubject<ReleaseNoteDocumentVersion[]>([]);
  public releaseNoteDocumentVersions$ = this._releaseNoteDocumentVersions as Observable<ReleaseNoteDocumentVersion[]>;

  private _latestReleaseNoteDocument = new BehaviorSubject<ReleaseNoteDocument | null>(null);
  public latestReleaseNoteDocument$ = this._latestReleaseNoteDocument as Observable<ReleaseNoteDocument | null>;

  public getReleaseNoteDocuments(oDataParams: ODataQueryOptions): Observable<ODataResponse<ReleaseNoteDocument>> {
    return this.releaseNotesAPI.getReleaseNoteDocuments(oDataParams).pipe(
      tap(response => {
        const latestVersionsMap = new Map<PortalTypeIdEnum, ReleaseNoteDocument>();

        response.value.forEach(doc => {
          if (doc.isReleased) {
            const existing = latestVersionsMap.get(doc.portalTypeId);
            if (!existing || moment(doc.releaseDate).isAfter(existing.releaseDate)) {
              latestVersionsMap.set(doc.portalTypeId, doc);
            }
          }
        });

        response.value.forEach(doc => {
          doc.isLatestReleasedVersion = latestVersionsMap.get(doc.portalTypeId) === doc;
        });

        this._releaseNoteDocuments.next(response.value);
      }),
      tap(response => this._releaseNoteDocumentsTotalCount.next(response['@odata.count'] ?? 0)),
      catchError(err => {
        this._releaseNoteDocuments.next([]);
        this._releaseNoteDocumentsTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getUniqueVersions(filterString: string): Observable<ReleaseNoteDocumentVersion[]> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setFilter(`contains(version, '${filterString}')`);
    return this.releaseNotesAPI.getUniqueVersions(queryOptions).pipe(map(res => res.value));
  }

  public getReleaseNoteById(id: string): Observable<ReleaseNoteDocument> {
    return this.releaseNotesAPI.getReleaseNoteDocumentById(id);
  }

  public getReleaseNoteDocumentVersions(): Observable<ODataResponse<ReleaseNoteDocumentVersion>> {
    return this.releaseNotesAPI
      .getReleaseNoteDocumentVersions()
      .pipe(tap(response => this._releaseNoteDocumentVersions.next(response.value)));
  }

  public createReleaseNoteDocument(req: CreateReleaseNoteDocumentRequest): Observable<ReleaseNoteDocument> {
    return this.releaseNotesAPI.createReleaseNoteDocument(req);
  }

  public updateReleaseNoteDocument(req: UpdateReleaseNoteDocumentRequest): Observable<ReleaseNoteDocument> {
    return this.releaseNotesAPI.updateReleaseNoteDocument(req);
  }

  public deleteReleaseNoteDocument(id: string): Observable<HttpResponse<any>> {
    return this.releaseNotesAPI.deleteReleaseNoteDocument(id);
  }

  private fetchLatestReleaseNoteDocument(): void {
    this.releaseNotesAPI.getLatestReleaseNoteDocument().once(doc => this._latestReleaseNoteDocument.next(doc));
  }
}
