import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setHas'
})
export class SetHasPipe<T> implements PipeTransform {
  transform(set: Set<T>, value: T): boolean {
    return set?.has(value) ?? false;
  }
}
