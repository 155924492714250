import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';

@Component({
  selector: 'app-breadcrumb-header',
  templateUrl: './breadcrumb-header.component.html',
  styleUrls: ['./breadcrumb-header.component.scss']
})
export class BreadcrumbHeaderComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() title: string = '';
  @Input() includeBanner: boolean = false;
}
