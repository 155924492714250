import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalUsersDomainModel } from '../../../../domainModels/internal-users-domain-model.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ChangeEmailRequest } from '../../../../models/account/requests/change-email-request';
import { InternalUser } from '../../../../models/account/dto/internal-user';
import { switchMap } from 'rxjs/operators';
import { CustomError } from '../../../../models/shared/custom-error';
import { ToastService } from '../../../../services/toast-service';
import { EmployersDomainModel } from '../../../../domainModels/employers-domain-model';
import { BaseUser } from '../../../../models/base/base-user';
import { EmployerUser } from '../../../../models/account/dto/employer-user';
import { MemberUser } from '../../../../models/account/dto/member-user';
import _default from 'chart.js/dist/core/core.layouts';
import update = _default.update;
import { MembersDomainModel } from '../../../../domainModels/members-domain-model';

@Injectable()
export class ChangeUserEmailModalViewModel extends BaseModalViewModel {
  constructor(
    public router: Router,
    public ngbModal: NgbModal,
    private internalUserDomainModel: InternalUsersDomainModel,
    private employerDomainModel: EmployersDomainModel,
    private memberDomainModel: MembersDomainModel,
    private toastService: ToastService
  ) {
    super(router, ngbModal);
  }

  private _changeEmailReq = new BehaviorSubject<ChangeEmailRequest>(new ChangeEmailRequest());
  public changeEmailReq$ = this._changeEmailReq as Observable<ChangeEmailRequest>;

  private _user = new BehaviorSubject<any>({} as InternalUser);
  public readonly user$ = this._user as Observable<EmployerUser | MemberUser | InternalUser>;

  public setUser<T extends BaseUser>(user: T): void {
    this._user.next(user);
  }

  public formSubmitted(req: ChangeEmailRequest) {
    const lm = $localize`Changing Email`;
    this.user$
      .pipe(
        switchMap(user => {
          if (!!user) {
            this._loadingOpts.addRequest(lm);
            return this.getChangeEmailRoute(user, req);
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: (updatedUser: BaseUser | null) => {
          if (updatedUser) {
            this.toastService.publishSuccessMessage($localize`Success!`, $localize`Email Changed!`);
            this._loadingOpts.removeRequest(lm);
            this.closeWithReturnValue(updatedUser);
          } else {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishErrorMessage($localize`Error`, $localize`Failed to change email`);
            this.closeModal();
          }
        },
        error: (err: CustomError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          this.closeModal();
        },
        complete: () => this._loadingOpts.removeRequest(lm)
      });
  }

  private getChangeEmailRoute(user: BaseUser, req: ChangeEmailRequest): Observable<BaseUser | null> {
    if (user instanceof InternalUser) {
      return this.internalUserDomainModel.forceResetInternalUserEmail(req, user);
    } else if (user instanceof EmployerUser) {
      return this.employerDomainModel.forceChangeEmail(user, req);
    } else if (user instanceof MemberUser) {
      return this.memberDomainModel.forceChangeEmail(user, req);
    } else {
      return of(null);
    }
  }

  public setCurrentEmail(email: string): void {
    this.changeEmailReq$.once(req => {
      req.currentEmail = email;
      this._changeEmailReq.next(req);
    });
  }
}
