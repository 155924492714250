import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { NavItem } from '../nav-items/nav-item/models/nav-item';
import { DefaultNavItem } from '../nav-items/nav-item/models/default-nav-item';
import { NavItemId } from '../../../../models/enum/shared/nav-item-id.enum';
import { NavigationEnd, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { ScreenService } from '../../../../services/screen-service.service';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { PortalService } from '../../../../services/portal/portal.service';
import { PortalType } from '../../../../models/enum/shared/portal-type';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { DropDownNavItem } from '../nav-items/nav-item/models/drop-down-nav-item';
import { NavSubItem } from '../nav-items/nav-sub-item/models/nav-sub-item';
import { PermissionService } from '../../../../services/permission-service';

@Injectable({ providedIn: 'root' })
export class NavbarViewModel extends BaseViewModel {
  constructor(private router: Router) {
    super();
    this.init();
  }

  private screenService = inject(ScreenService);
  private userDomainModel = inject(UserDomainModel);
  private portalService = inject(PortalService);
  private permissionService = inject(PermissionService);

  public _loadingOpts = new BehaviorSubject(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));

  public navItems$ = combineLatest([this.portalService.portalType$, this.permissionService.permissionMapReady$]).pipe(
    switchMap(([pt, mapReady]) => {
      if (mapReady) {
        switch (pt) {
          case PortalType.Employer:
            return this.getEmployerNavItems();
          case PortalType.Member:
            return this.getMemberNavItems();
          case PortalType.Internal:
            return this.getInternalUserNavItems();
          default:
            return of([]);
        }
      } else {
        return of([]);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _isNavbarCollapsed = new BehaviorSubject(true);
  public readonly isNavbarCollapsed$ = this._isNavbarCollapsed as Observable<boolean>;

  public isMobile$ = this.screenService.isMobile$;

  public init() {
    super.init();
    const lm = $localize`Loading...`;
    this._loadingOpts.addRequest(lm);
    this.permissionService.permissionMapReady$.subscribeWhileAlive({
      owner: this,
      next: ready => {
        if (ready) {
          this._loadingOpts.removeRequest(lm);
        }
      }
    });

    this.router.events.subscribeWhileAlive({
      owner: this,
      next: events => {
        if (events instanceof NavigationEnd) {
          this._isNavbarCollapsed.next(true);
        }
      }
    });
  }

  private getEmployerNavItems(): Observable<NavItem[]> {
    return combineLatest([
      this.userDomainModel.user$,
      this.permissionService.permissionGranted$([2001]),
      this.permissionService.permissionGranted$([2006]),
      this.permissionService.permissionGranted$([2009])
    ]).pipe(
      map(([u, canViewEmployeesTable, canViewRemittances, canViewEmployerUsersTable]) => {
        return [
          this.getHomeNavItem(),
          this.getEmployersNavItem(),
          canViewEmployeesTable ? this.getEmployeesNavItem() : null,
          canViewRemittances ? this.getRemittanceNavItem() : null,
          this.getDocumentsNavItem(),
          this.getMessagingNavItem(),
          canViewEmployerUsersTable ? this.getSettingsNavItem() : null,
          this.getProfileNavItem(u.fullName)
        ].filter((navItem): navItem is NavItem => !!navItem);
      })
    );
  }

  private getMemberNavItems(): Observable<NavItem[]> {
    return this.userDomainModel.user$.pipe(
      map(u => {
        const navItems = [];
        navItems.push(
          this.getHomeNavItem(),
          this.getAccountsNavItem(),
          this.getDocumentsNavItem(true),
          this.getContactUsNavItem(),
          this.getProfileNavItem(u.fullName)
        );
        return navItems;
      })
    );
  }

  private getInternalUserNavItems(): Observable<NavItem[]> {
    return combineLatest([
      this.userDomainModel.user$,
      this.permissionService.permissionGranted$([1001]),
      this.permissionService.permissionGranted$([1002]),
      this.permissionService.permissionGranted$([1026]),
      this.permissionService.permissionGranted$([1005]),
      this.permissionService.permissionGranted$([1013]),
      this.permissionService.permissionGranted$([1018]),
      this.permissionService.permissionGranted$([1024]),
      this.permissionService.permissionGranted$([1030])
    ]).pipe(
      map(
        ([
          u,
          canViewSubmissions,
          canViewRemittances,
          canViewAlerts,
          canViewMembersTable,
          canViewEmployersTable,
          canViewInternalUsersTable,
          canViewRoles,
          canViewReleaseNotes
        ]) => {
          const navItems = [];
          navItems.push(this.getHomeNavItem());
          if (canViewSubmissions || canViewRemittances) {
            navItems.push(this.getWorkNavItem(canViewSubmissions, canViewRemittances));
          }
          if (canViewMembersTable || canViewEmployersTable) {
            navItems.push(this.getRecordsDropdownNavItem(canViewMembersTable, canViewEmployersTable));
          }
          navItems.push(this.getMessagingNavItem());
          if (canViewAlerts || canViewInternalUsersTable || canViewRoles || canViewReleaseNotes) {
            navItems.push(
              this.getInternalUserSettingsDropdownNavItems(
                canViewAlerts,
                canViewInternalUsersTable,
                canViewRoles,
                canViewReleaseNotes
              )
            );
          }
          navItems.push(this.getProfileNavItem(u.fullName));
          return navItems;
        }
      )
    );
  }

  private getHomeNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Home,
      name: $localize`Home`,
      subItems: [],
      routerPath: '/dashboard',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getAccountsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Accounts,
      name: $localize`My Account`,
      subItems: [],
      routerPath: '/accounts',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getEmployeesNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Members,
      name: $localize`Employees`,
      subItems: [],
      routerPath: '/records/employees',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getEmployersNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Employers,
      name: $localize`Employer Profile`,
      subItems: [],
      routerPath: '/employers',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getRemittanceNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Remittance,
      name: $localize`Remittances`,
      subItems: [],
      routerPath: `remittance`,
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getSettingsNavItem(): NavItem {
    return new DropDownNavItem({
      id: NavItemId.Settings,
      name: $localize`Settings`,
      subItems: [new NavSubItem($localize`Employer Users`, 'employer-users')],
      routerPath: '/settings',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getDocumentsNavItem(memberDocuments: boolean = false): NavItem {
    const label = memberDocuments ? $localize`My Documents` : $localize`Documents`;
    return new DefaultNavItem({
      id: NavItemId.Documents,
      name: label,
      subItems: [],
      routerPath: '/documents',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getMessagingNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.Messaging,
      name: $localize`Messaging`,
      subItems: [],
      routerPath: '/messaging',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getInternalUserSettingsDropdownNavItems(
    canViewAlerts: boolean,
    canViewInternalUsers: boolean,
    canViewRoles: boolean,
    canViewReleaseNotes: boolean
  ): NavItem {
    const subItems: NavSubItem[] = [];
    if (canViewRoles) {
      const rolesSubItem = new NavSubItem($localize`Roles`, '');
      rolesSubItem.subItemFragment = 'roles';
      subItems.push(rolesSubItem);
    }

    if (canViewAlerts) subItems.push(new NavSubItem($localize`Alerts`, 'alerts'));
    if (canViewInternalUsers) subItems.push(new NavSubItem($localize`Internal Users`, 'internal-users'));
    if (canViewReleaseNotes) subItems.push(new NavSubItem($localize`Release Notes`, 'release-notes'));

    return new DropDownNavItem({
      id: NavItemId.Settings,
      name: $localize`Settings`,
      subItems,
      routerPath: '/settings',
      iconSrc: '',
      iconFilledSrc: '',
      baseFragmentRoute: '/settings/menu'
    });
  }

  private getRecordsDropdownNavItem(canViewMembersTable: boolean, canViewEmployersTable: boolean): NavItem {
    const subItems = [
      canViewMembersTable ? new NavSubItem($localize`Members`, 'members') : null,
      canViewEmployersTable ? new NavSubItem($localize`Employers`, 'employers') : null
    ].filter((subItem): subItem is NavSubItem => !!subItem);
    return new DropDownNavItem({
      id: NavItemId.Records,
      name: $localize`Records`,
      subItems,
      routerPath: '/records',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getWorkNavItem(canViewSubmissions: boolean, canViewRemittances: boolean): NavItem {
    return new DropDownNavItem({
      id: NavItemId.Work,
      name: $localize`Work`,
      subItems: [
        canViewSubmissions ? new NavSubItem($localize`Submissions`, 'work/submissions') : null,
        canViewRemittances ? new NavSubItem($localize`Remittances`, 'remittance') : null
      ].filter((subItem): subItem is NavSubItem => !!subItem),
      routerPath: '',
      iconSrc: '',
      iconFilledSrc: '',
      itemMapsToRoute: false
    });
  }

  private getContactUsNavItem(): NavItem {
    return new DefaultNavItem({
      id: NavItemId.ContactUs,
      name: $localize`Contact Us`,
      subItems: [],
      routerPath: '/contact-us',
      iconSrc: '',
      iconFilledSrc: ''
    });
  }

  private getProfileNavItem(userName: string): NavItem {
    const subItems: NavSubItem[] = [];
    const profileSubItem = new NavSubItem($localize`View Profile`, '', 'assets/nav/profile-icon.svg');
    profileSubItem.subItemFragment = 'memberOverview';
    subItems.push(profileSubItem);
    subItems.push(new NavSubItem($localize`Sign Out`, '/sign-out', 'assets/nav/sign-out-icon.svg', true));
    return new DropDownNavItem({
      id: NavItemId.Profile,
      name: userName,
      subItems,
      routerPath: '/profile',
      iconSrc: 'assets/nav/profile-icon.svg',
      iconFilledSrc: 'assets/nav/profile-icon-filled.svg',
      baseFragmentRoute: '/profile/view'
    });
  }

  public navigateToRoot() {
    this.router.navigate(['']).then(() => {});
  }

  public toggleNavbarCollapse() {
    this._isNavbarCollapsed.once(collapse => {
      this._isNavbarCollapsed.next(!collapse);
    });
  }
}
