import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { inject, Injectable } from '@angular/core';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { Remittance } from '../models/remittances/remittance';
import { Endpoints } from './endpoints';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { RemittanceOwner } from '../models/remittances/remittance-owner';
import { Contribution } from '../models/remittances/contribution';
import { ContributionSummary } from '../models/remittances/contribution-summary';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { ContributionEntry } from '../models/remittances/contribution-entry';

@Injectable({
  providedIn: 'root'
})
export class RemittancesAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  public serviceName = 'RemittancesAPI';

  public getOpenRemittancesForEmployer(employerId: number): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getOpenRemittancesForEmployer(employerId);
    const oDataQueryOptions = new ODataQueryOptions();
    oDataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner, Transactions');

    return this.apiClient.getOdata(url, Remittance, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getClosedRemittancesForEmployer(employerId);
    return this.apiClient.getOdata(url, Remittance, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    const url = Endpoints.createRemittance();
    return this.apiClient.postObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    const url = Endpoints.getRemittanceById(remittanceId);
    return this.apiClient.getOdataObj(url, Remittance, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceOwners(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<RemittanceOwner>> {
    const url = Endpoints.getRemittanceOwners();
    return this.apiClient.getOdata(url, RemittanceOwner, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    const url = Endpoints.getRemittanceHistory(remittanceId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionHistory(remittanceId: string, contributionId: string): Observable<RemittanceHistory[]> {
    const url = Endpoints.getHistoryForContribution(remittanceId, contributionId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Contribution>> {
    const url = Endpoints.getContributionsForRemittance(remittanceId);
    queryOptions.setExpand('NewMemberDetails, Entries($expand=Code;$count=true)');
    return this.apiClient.getOdata(url, Contribution, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionSummaryForRemittance(remittanceId: string): Observable<ContributionSummary> {
    const url = Endpoints.getContributionSummaryForRemittance(remittanceId);
    return this.apiClient.getOdataObj(url, ContributionSummary).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    const url = Endpoints.addContributionToRemittance(remittanceId);
    return this.apiClient.postObj(Contribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContribution(remittanceId: number, contributionId: string): Observable<any> {
    const url = Endpoints.deleteContribution(remittanceId, contributionId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    const url = Endpoints.createContributionEntry(remittanceId, contributionId);
    return this.apiClient.postObj(Contribution, url, entries).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    const url = Endpoints.updateContributionEntry(remittanceId, contributionId, entry.id);
    return this.apiClient.putObj(ContributionEntry, url, entry).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    const url = Endpoints.deleteContributionEntry(remittanceId, contributionId, entryId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
