<div *ngIf="viewModel.isMobile$ | async" class="mobile-navbar">
  <div class="wrapper">
    <img
      (click)="viewModel.navigateToRoot()"
      [src]="'assets/logo/purple/spp-logo.svg'"
      alt="logo"
      class="mobile-nav-logo" />
    <button
      *ngIf="(viewModel.navItems$ | async)?.length > 0"
      class="toggle-button"
      (click)="viewModel.toggleNavbarCollapse()"
      [attr.aria-expanded]="!(viewModel.isNavbarCollapsed$ | async)"
      aria-controls="mobile-collapse">
      <div id="hamburger-icon" [class.open]="!(viewModel.isNavbarCollapsed$ | async)">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  </div>
  <div id="mobile-collapse" [ngbCollapse]="viewModel.isNavbarCollapsed$ | async">
    <ul class="mobile-nav-list">
      <li *ngFor="let navItem of viewModel.navItems$ | async">
        <app-nav-item *ngIf="navItem | isDefaultNavItem" [navItem]="navItem"></app-nav-item>
        <app-profile-nav-item *ngIf="navItem | isProfileNavItem" [navItem]="navItem"></app-profile-nav-item>
        <app-sign-out-nav-item *ngIf="navItem | isSignOutNavItem" [navItem]="navItem"></app-sign-out-nav-item>
        <app-mobile-drop-down-nav-item *ngIf="navItem | isDropDownNavItem" [navItem]="navItem">
        </app-mobile-drop-down-nav-item>
      </li>
    </ul>
  </div>
</div>
