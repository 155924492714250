import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { inject, Injectable } from '@angular/core';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { Remittance } from '../models/remittances/remittance';
import { Endpoints } from './endpoints';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { RemittanceOwner } from '../models/remittances/remittance-owner';
import { Contribution } from '../models/remittances/contribution';
import { ContributionSummary } from '../models/remittances/contribution-summary';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import {
  RemittancePotentialMatchedMember,
  RemittanceVerificationMember
} from '../models/remittances/remittance-verification-member';
import { VerifyContributionRequest } from '../models/remittances/verify-contribution-request';
import { UpdateContributionRequest } from '../models/remittances/update-contribution-request';
import { ContributionEntry } from '../models/remittances/contribution-entry';
import { RemittanceAddDatesRequest } from '../models/remittances/remittance-add-dates-request';
import { RemittanceNewMember } from '../models/remittances/remittance-new-member';
import { RemittanceUpdateNewMemberRequest } from '../models/remittances/remittance-update-new-member-request';
import { RemittanceCreateNewMemberRequest } from '../models/remittances/remittance-create-new-member-request';
import { RemittanceFrequencyEnum } from '../models/remittances/remittance-frequency';
import { CreateScheduledRemittanceRequest } from '../models/remittances/create-scheduled-remittance-request';
import { ScheduledRemittance } from '../models/remittances/scheduled-remittance';
import { RemittanceOwnerUpdateRequest } from '../models/account/requests/remittance-owner-update-request';
import { FailRemittanceRequest } from '../models/account/requests/fail-remittance-request';
import { RemittanceComment } from '../models/remittances/remittance-comment';
import { RemittanceCommentRequest } from '../models/remittances/remittance-comment-request';
import { CreateRemittancePaymentRequest } from '../models/payments/create-remittance-payment-request';
import { RemittancePayment } from '../models/payments/remittance-payment';
import { ScheduledRemittanceNewMember } from '../models/remittances/scheduled-remittance-new-member';
import { UpdateScheduledRemittanceRequest } from '../models/remittances/update-scheduled-remittance-request';
import { ScheduledRemittanceContribution } from '../models/remittances/scheduled-remittance-contribution';
import { AddScheduledRemittanceContributionRequest } from '../models/account/requests/add-scheduled-remittance-contribution-request';
import { ScheduledRemittanceContributionSummary } from '../models/remittances/scheduled-remittance-contribution-summary';
import { ScheduledRemittanceHistory } from '../models/remittances/scheduled-remittance-history';
import { ScheduledRemittanceCommentRequest } from '../models/remittances/scheduled-remittance-comment-request';

@Injectable({
  providedIn: 'root'
})
export class RemittancesAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  public serviceName = 'RemittancesAPI';

  public getOpenRemittancesForEmployer(employerId: number): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getOpenRemittancesForEmployer(employerId);
    const oDataQueryOptions = new ODataQueryOptions();
    oDataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner, Transactions');
    oDataQueryOptions.setOrderBy('SubmittedDate desc');
    return this.apiClient.getOdata(url, Remittance, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getClosedRemittancesForEmployer(employerId);
    return this.apiClient.getOdata(url, Remittance, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    const url = Endpoints.createRemittance();
    return this.apiClient.postObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createScheduledRemittance(req: CreateScheduledRemittanceRequest): Observable<ScheduledRemittance> {
    const url = Endpoints.createScheduledRemittance();
    return this.apiClient.postObj(ScheduledRemittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public submitRemittance(remittanceId: number): Observable<Remittance> {
    const url = Endpoints.submitRemittance(remittanceId);
    return this.apiClient.putObj(Remittance, url, {} as Remittance).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    const url = Endpoints.getRemittanceById(remittanceId);
    return this.apiClient.getOdataObj(url, Remittance, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceOwners(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<RemittanceOwner>> {
    const url = Endpoints.getRemittanceOwners();
    return this.apiClient.getOdata(url, RemittanceOwner, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    const url = Endpoints.getRemittanceHistory(remittanceId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getOverlappingScheduledRemittanceCount(
    employerId: number,
    frequencyId: RemittanceFrequencyEnum,
    startDate: string
  ): Observable<number> {
    const url = Endpoints.getOverlappingScheduledRemittanceCount(employerId, frequencyId, startDate);
    return this.apiClient.getOdata(url, Remittance).pipe(
      map(res => res['@odata.count'] ?? 0),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getNextFiveRemittanceDates(
    frequencyId: RemittanceFrequencyEnum,
    startDate: string,
    endDate?: string
  ): Observable<string[]> {
    const url = Endpoints.getNextFiveRemittanceDates(frequencyId, startDate, endDate);
    return this.apiClient.simpleGet(url).pipe(
      map(res => res.body as string[]),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getNewMembersOnRemittance(remittanceId: number): Observable<ODataResponse<RemittanceNewMember>> {
    const url = Endpoints.getNewMembersOnRemittance(remittanceId);
    const queryOptions = new ODataQueryOptions();
    queryOptions.setCount(true);
    queryOptions.setExpand('NewMemberDetails');
    return this.apiClient.getOdata(url, RemittanceNewMember, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittanceNewMember(
    remittanceId: number,
    req: RemittanceCreateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    const url = Endpoints.createRemittanceNewMember(remittanceId, req.contributionId);
    return this.apiClient.postObj(RemittanceNewMember, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceNewMember(
    remittanceId: number,
    req: RemittanceUpdateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    const url = Endpoints.updateRemittanceNewMember(remittanceId, req.contributionId, req.id);
    return this.apiClient.putObj(RemittanceNewMember, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionHistory(remittanceId: number, contributionId: string): Observable<RemittanceHistory[]> {
    const url = Endpoints.getHistoryForContribution(remittanceId, contributionId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduledRemittanceContributionHistory(
    scheduleId: number,
    contributionId: string
  ): Observable<ScheduledRemittanceHistory[]> {
    const url = Endpoints.getHistoryForScheduledContribution(scheduleId, contributionId);
    return this.apiClient.getOdata(url, ScheduledRemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Contribution>> {
    const url = Endpoints.getContributionsForRemittance(remittanceId);
    queryOptions.setExpand('NewMemberDetails, Entries($expand=Code;$count=true)');
    return this.apiClient.getOdata(url, Contribution, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionsForScheduledRemittance(
    scheduleId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<ScheduledRemittanceContribution>> {
    const url = Endpoints.getContributionsForScheduledRemittance(scheduleId);
    queryOptions.setExpand('NewMemberDetails, Entries($expand=Code;$count=true)');
    return this.apiClient.getOdata(url, ScheduledRemittanceContribution, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionSummaryForRemittance(remittanceId: string): Observable<ContributionSummary> {
    const url = Endpoints.getContributionSummaryForRemittance(remittanceId);
    return this.apiClient.getOdataObj(url, ContributionSummary).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionSummaryForScheduledRemittance(
    scheduleId: string
  ): Observable<ScheduledRemittanceContributionSummary> {
    const url = Endpoints.getContributionSummaryForScheduledRemittance(scheduleId);
    return this.apiClient.getOdataObj(url, ScheduledRemittanceContributionSummary).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    const url = Endpoints.addContributionToRemittance(remittanceId);
    return this.apiClient.postObj(Contribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addScheduledRemittanceContribution(
    scheduleId: number,
    req: AddScheduledRemittanceContributionRequest
  ): Observable<ScheduledRemittanceContribution> {
    const url = Endpoints.addContributionToScheduledRemittance(scheduleId);
    return this.apiClient.postObj(ScheduledRemittanceContribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceMembersToVerify(remittanceId: string): Observable<ODataResponse<RemittanceVerificationMember>> {
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('PotentialMatches');
    odataOptions.setCount(true);
    const url = Endpoints.getRemittanceMembersToVerify(remittanceId);
    return this.apiClient.getOdata(url, RemittanceVerificationMember, odataOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduleMembersToVerify(scheduleId: string): Observable<ODataResponse<RemittanceVerificationMember>> {
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('PotentialMatches');
    odataOptions.setCount(true);
    const url = Endpoints.getScheduleMembersToVerify(scheduleId);
    return this.apiClient.getOdata(url, RemittanceVerificationMember, odataOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getPotentialMatchesForContribution(
    remittanceId: string,
    contributionId: string
  ): Observable<ODataResponse<RemittancePotentialMatchedMember>> {
    const url = Endpoints.getPotentialMatchesForContribution(remittanceId, contributionId);
    return this.apiClient.getOdata(url, RemittancePotentialMatchedMember).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getPotentialMatchesForScheduledContribution(
    scheduleId: string,
    contributionId: string
  ): Observable<ODataResponse<RemittancePotentialMatchedMember>> {
    const url = Endpoints.getPotentialMatchesForScheduledContribution(scheduleId, contributionId);
    return this.apiClient.getOdata(url, RemittancePotentialMatchedMember).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyContribution(
    remittanceId: string,
    contributionId: string,
    req: VerifyContributionRequest
  ): Observable<Contribution> {
    const url = Endpoints.verifyContribution(remittanceId, contributionId);
    return this.apiClient.putObj(Contribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyScheduleContribution(
    scheduleId: string,
    contributionId: string,
    req: VerifyContributionRequest
  ): Observable<ScheduledRemittanceContribution> {
    const url = Endpoints.verifyScheduleContribution(scheduleId, contributionId);
    return this.apiClient.putObj(ScheduledRemittanceContribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateContribution(
    remittanceId: number,
    contributionId: string,
    req: UpdateContributionRequest
  ): Observable<Contribution> {
    const url = Endpoints.updateContribution(remittanceId, contributionId);
    return this.apiClient.putObj(Contribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateScheduleContribution(
    scheduleId: number,
    contributionId: string,
    req: UpdateContributionRequest
  ): Observable<ScheduledRemittanceContribution> {
    const url = Endpoints.updateScheduleContribution(scheduleId, contributionId);
    return this.apiClient.putObj(ScheduledRemittanceContribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContribution(remittanceId: number, contributionId: string): Observable<any> {
    const url = Endpoints.deleteContribution(remittanceId, contributionId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteScheduledRemittanceContribution(scheduleId: number, contributionId: string): Observable<any> {
    const url = Endpoints.deleteScheduledRemittanceContribution(scheduleId, contributionId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    const url = Endpoints.createContributionEntry(remittanceId, contributionId);
    return this.apiClient.postObj(Contribution, url, entries).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createScheduledRemittanceContributionEntry(
    scheduleId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<ScheduledRemittanceContribution> {
    const url = Endpoints.createScheduledRemittanceContributionEntry(scheduleId, contributionId);
    return this.apiClient.postObj(ScheduledRemittanceContribution, url, entries).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    const url = Endpoints.updateContributionEntry(remittanceId, contributionId, entry.id);
    return this.apiClient.putObj(ContributionEntry, url, entry).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateScheduledRemittanceContributionEntry(
    scheduleId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    const url = Endpoints.updateScheduledRemittanceContributionEntry(scheduleId, contributionId, entry.id);
    return this.apiClient.putObj(ContributionEntry, url, entry).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    const url = Endpoints.deleteContributionEntry(remittanceId, contributionId, entryId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteScheduledRemittanceContributionEntry(
    scheduleId: number,
    contributionId: string,
    entryId: string
  ): Observable<any> {
    const url = Endpoints.deleteScheduledRemittanceContributionEntry(scheduleId, contributionId, entryId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addTaxationYearToRemittance(remittanceId: number, req: RemittanceAddDatesRequest): Observable<Remittance> {
    const url = Endpoints.addTaxationYearToRemittance(remittanceId);
    return this.apiClient.putObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addTaxationYearToScheduledRemittance(
    scheduleId: number,
    req: RemittanceAddDatesRequest
  ): Observable<ScheduledRemittance> {
    const url = Endpoints.addTaxationYearToScheduledRemittance(scheduleId);
    return this.apiClient.putObj(ScheduledRemittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteRemittance(remittanceId: number): Observable<any> {
    const url = Endpoints.deleteRemittance(remittanceId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceOwner(req: RemittanceOwnerUpdateRequest): Observable<Remittance> {
    const url = Endpoints.updateRemittanceOwner(req.id);
    return this.apiClient.putObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceStatusInReview(remittanceId: number): Observable<Remittance> {
    const url = Endpoints.updateRemittanceStatusInReview(remittanceId);
    return this.apiClient.putObj(Remittance, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceStatusApproved(remittanceId: number): Observable<Remittance> {
    const url = Endpoints.updateRemittanceStatusApproved(remittanceId);
    return this.apiClient.putObj(Remittance, url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceStatusFailed(remittanceId: number, req: FailRemittanceRequest): Observable<Remittance> {
    const url = Endpoints.updateRemittanceStatusFailed(remittanceId);
    return this.apiClient.putObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getAllRemittances(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getAllRemittances();
    odataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner');
    return this.apiClient.getOdata(url, Remittance, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduledRemittancesByStatusId(statusId: number) {
    const url = Endpoints.getScheduledRemittances();
    const queryOptions = new ODataQueryOptions();
    queryOptions.setFilter(`StatusId eq ${statusId}`);
    queryOptions.setExpand(`Status, Source, Frequency, LinkedRemittances($expand=Status)`);
    return this.apiClient.getOdata(url, ScheduledRemittance, queryOptions).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduledRemittanceById(id: number, odataQueryOptions: ODataQueryOptions) {
    const url = Endpoints.getScheduledRemittanceById(id);
    return this.apiClient.getOdataObj(url, ScheduledRemittance, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateScheduledRemittance(req: UpdateScheduledRemittanceRequest): Observable<ScheduledRemittance> {
    const url = Endpoints.updateScheduledRemittance(req.id);
    return this.apiClient.putObj(ScheduledRemittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittancePayment(req: CreateRemittancePaymentRequest): Observable<RemittancePayment> {
    const url = Endpoints.createRemittancePayment();
    return this.apiClient.postObj(RemittancePayment, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getNewMembersOnScheduledRemittance(
    scheduledRemittanceId: number
  ): Observable<ODataResponse<ScheduledRemittanceNewMember>> {
    const url = Endpoints.getNewMembersOnScheduledRemittance(scheduledRemittanceId);
    const queryOptions = new ODataQueryOptions();
    queryOptions.setCount(true);
    queryOptions.setExpand('NewMemberDetails');
    return this.apiClient.getOdata(url, ScheduledRemittanceNewMember, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createScheduledRemittanceNewMember(
    scheduledRemittanceId: number,
    req: RemittanceCreateNewMemberRequest
  ): Observable<ScheduledRemittanceNewMember> {
    const url = Endpoints.createScheduledRemittanceNewMember(scheduledRemittanceId, req.contributionId);
    return this.apiClient.postObj(ScheduledRemittanceNewMember, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateScheduledRemittanceNewMember(
    scheduledRemittanceId: number,
    req: RemittanceUpdateNewMemberRequest
  ): Observable<ScheduledRemittanceNewMember> {
    const url = Endpoints.updateScheduledRemittanceNewMember(scheduledRemittanceId, req.contributionId, req.id);
    return this.apiClient.putObj(ScheduledRemittanceNewMember, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  private processCommentsResponse(res: any): RemittanceComment[] {
    const comments = Array.isArray(res.value)
      ? res.value
      : window?.injector?.Deserialize?.arrayOf(RemittanceComment, res.body);
    return comments.sort(
      (a: RemittanceComment, b: RemittanceComment) =>
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
    );
  }

  public getScheduledRemittanceComments(remittanceId: number): Observable<RemittanceComment[]> {
    const url = Endpoints.getCommentsForScheduledRemittance(remittanceId);
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('Mentions');
    return this.apiClient.getOdata(url, RemittanceComment, odataOptions).pipe(
      map(res => this.processCommentsResponse(res)),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addScheduledRemittanceComment(req: ScheduledRemittanceCommentRequest): Observable<RemittanceComment[]> {
    const url = Endpoints.addScheduledRemittanceComment(req.scheduledRemittanceId);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => this.processCommentsResponse(res)),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceComments(remittanceId: number): Observable<RemittanceComment[]> {
    const url = Endpoints.getCommentsForRemittance(remittanceId);
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('Mentions');
    return this.apiClient.getOdata(url, RemittanceComment, odataOptions).pipe(
      map(res => this.processCommentsResponse(res)),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addRemittanceComment(req: RemittanceCommentRequest): Observable<RemittanceComment[]> {
    const url = Endpoints.addRemittanceComment(req.remittanceId);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => this.processCommentsResponse(res)),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduledRemittanceHistory(scheduledRemittanceId: number): Observable<ScheduledRemittanceHistory[]> {
    const url = Endpoints.getScheduledRemittanceHistory(scheduledRemittanceId);
    return this.apiClient.getOdata(url, ScheduledRemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteScheduledRemittance(scheduledRemittanceId: number): Observable<any> {
    const url = Endpoints.deleteScheduledRemittance(scheduledRemittanceId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public cancelScheduledRemittance(scheduledRemittanceId: number): Observable<ScheduledRemittance> {
    const url = Endpoints.cancelScheduledRemittance(scheduledRemittanceId);
    return this.apiClient.bodylessPut<ScheduledRemittance>(ScheduledRemittance, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public setScheduledRemittanceToScheduled(scheduledRemittanceId: number): Observable<any> {
    const url = Endpoints.setScheduledRemittanceToScheduled(scheduledRemittanceId);
    return this.apiClient.simpleBodylessPut(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
