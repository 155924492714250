import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent extends BaseCardComponent implements OnChanges {
  @Input() isExpanded: boolean = false;
  @Input() disabled: boolean = false;
  @Output() clicked = new EventEmitter<void>();

  private _isExpanded = new BehaviorSubject<boolean>(false);
  public isExpanded$ = this._isExpanded as Observable<boolean>;

  private _isDisabled = new BehaviorSubject<boolean>(false);
  public isDisabled$ = this._isDisabled as Observable<boolean>;

  public buttonText$ = this.isExpanded$.pipe(
    map(expanded => {
      return expanded ? $localize`Cancel` : $localize`Edit`;
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded) this._isExpanded.next(this.isExpanded);
    if (changes.disabled) this._isDisabled.next(this.disabled);
  }

  protected handleClick(): void {
    this.isDisabled$.once(isDisabled => {
      if (!isDisabled) this.clicked.emit();
    });
  }
}
