import { Deserializable } from '../protocols/deserializable';
import { BaseRemittanceContributionSummary } from './base-remittance-contribution-summary';

export class ContributionSummary extends BaseRemittanceContributionSummary implements Deserializable {
  public remittanceId!: number;
}

export class EntrySummary implements Deserializable {
  public entryTypeId!: number;
  public entryType!: string;
  public entryCount!: number;
  public amount!: number;

  onDeserialize() {}
}
