import { Deserializable } from '../protocols/deserializable';
import { Link } from './link';

export class Attachment implements Deserializable {
  public id!: string;
  fileName!: string;
  fileSize!: number;
  mediaType!: string;
  md5Hash!: string | null;
  attachmentTypeId!: number;
  attachmentType!: string;
  isInput!: boolean;
  links!: Link[];

  // Not from API
  fileSizeFormatted!: string;

  onDeserialize(): void {
    if (!!this.fileSize) {
      this.fileSizeFormatted = (this.fileSize / 1000000).toFixed(2);
    }
  }
}
