import { MemberUser } from '../../dto/member-user';
import { SubmissionRequest } from './submission-request';

export class ChangePersonalInfoRequest extends SubmissionRequest {
  public socialInsuranceNumber: string = '';
  public birthdate: string = '';
  public genderId: number = 0;
  public pronounId: number | null = 0;

  // Not From API
  public files: File[] = [];

  constructor(user: MemberUser, sin: string) {
    super();
    this.memberId = user.id;
    this.socialInsuranceNumber = sin;
    this.birthdate = user?.formattedBirthDate?.replace(new RegExp(' ', 'g'), '');
    this.genderId = user.genderId;
    this.pronounId = user.pronounId;
  }
}
