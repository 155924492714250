<div class="list-item">
  <div class="label">{{ label }}</div>
  <div class="text-value sin">
    <div [style.width.rem]="6.5">{{ sin | maskSinNumber : (showFullSin$ | async)! : true }}</div>
    <div>
      <button class="eye" (click)="toggleShowFullSin()">
        <img [src]="iconSrc$ | async" alt="eyeball icon" i18n-alt [style.width.rem]="1.25" [style.height.rem]="1.25" />
      </button>
    </div>
  </div>
</div>
