import { BaseViewModel } from '../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { PermissionDomainModel } from '../../../../domainModels/permission-domain-model';
import { PermissionTypeMap } from '../../../../models/permissions/base/permission-type-map';
import { AddRoleMap } from '../../../../models/shared/add-role-map';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, takeUntil } from 'rxjs/operators';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { DropDownItem } from '../../../../models/shared/stylesheet/drop-down-item';
import { Role } from '../../../../models/roles/role';
import { BaseUser } from '../../../../models/base/base-user';
import { PermissionService } from '../../../../services/permission-service';

@Injectable()
export class AddRemoveRoleFormViewModel extends BaseViewModel {
  constructor() {
    super();
  }

  private permissionDomainModel = inject(PermissionDomainModel);
  private permissionService = inject(PermissionService);

  private roles$ = this.permissionDomainModel.roles$;

  private _permissionMap = new BehaviorSubject(new Map<number, PermissionTypeMap>());
  public readonly permissionMap$ = this._permissionMap as Observable<Map<number, PermissionTypeMap>>;

  private _roleMap = new BehaviorSubject(new AddRoleMap());
  public readonly roleMap$ = this._roleMap as Observable<AddRoleMap>;

  private _user = new BehaviorSubject<BaseUser | null>(null);
  public readonly user$ = this._user as Observable<BaseUser>;

  public canEditUserRole$ = this.permissionService.permissionGranted$([9]);

  public roleDropdownOptions$ = combineLatest([
    this.roles$.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)),
    this.roleMap$.pipe(map(arm => arm.roleMap))
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    map(([roles, roleMap]) => {
      return roles.map(role => {
        const isAssignedToUser = Array.from(roleMap.values()).some(r => r?.id === role.id);
        return new DropDownItem(role.name, role.id, isAssignedToUser);
      });
    })
  );

  public possibleNumOfRoles$ = this.roles$.pipe(
    map(roles => {
      return roles?.length;
    })
  );

  public roleDropDownFormItems$ = this.roleMap$.pipe(
    map(internalUserRoleMap => {
      const roleMap = internalUserRoleMap.roleMap;
      const nonUndefinedValues = Array.from(roleMap.values()).filter(value => value !== undefined);
      return nonUndefinedValues ?? [];
    })
  );

  public hideAddRoleButton$ = combineLatest([this.possibleNumOfRoles$, this.roleDropDownFormItems$]).pipe(
    map(([possibleNumOfRoles, roleDropDowns]) => possibleNumOfRoles === roleDropDowns.length)
  );

  public populateRoleMap = combineLatest([
    this.permissionDomainModel.roles$.notNull(),
    this.user$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable))
  ])
    .pipe(
      map(([internalUserRoles, u]) => {
        const newMap = new AddRoleMap().roleMap;
        internalUserRoles?.forEach((_, i) => {
          newMap.set(i, undefined);
        });
        if (u?.roles?.length > 0) {
          u?.roles?.forEach((role, i) => {
            const index = internalUserRoles.findIndex(r => r.id === role.roleId);
            if (index !== -1) {
              newMap.set(i, internalUserRoles[index]);
            }
          });
        } else {
          newMap.set(0, new Role());
        }
        const newRoleMap = new AddRoleMap(newMap);
        this._roleMap.next(newRoleMap);
      }),
      takeUntil(this.onDestroy)
    )
    .subscribe();

  public addAdditionalRole(index: number): void {
    this.roleMap$.once(addRoleMap => {
      const roleMap = addRoleMap.roleMap;
      roleMap.set(index + 1, new Role());
      const newMap = Object.assign(roleMap, { roleMap });
      this._roleMap.next(newMap);
    });
  }

  public removeRole(index: number): void {
    this.roleMap$.once(addRoleMap => {
      const roleMap = addRoleMap.roleMap;
      roleMap.set(index, undefined);
      const newMap = Object.assign(roleMap, { roleMap });
      this._roleMap.next(newMap);
    });
  }

  public roleSelected(roleId: number, index: number): void {
    combineLatest([this.permissionDomainModel.roles$, this.roleMap$]).once(([roles, addRoleMap]) => {
      const roleMap = addRoleMap.roleMap;
      roleMap.set(index, roles.find(r => r.id === roleId) ?? new Role());
      const newMap = Object.assign(roleMap, { roleMap });
      this._roleMap.next(newMap);
    });
  }

  public setPermissionMap(permissionMap: Map<number, PermissionTypeMap>): void {
    this._permissionMap.next(permissionMap);
  }

  public setRoleMap(roleMap: AddRoleMap): void {
    this._roleMap.next(roleMap);
  }

  public setUser(user: BaseUser): void {
    this._user.next(user);
  }
}
