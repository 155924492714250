import { Deserializable } from '../protocols/deserializable';
import { RemittanceStatusPill } from '../shared/remittance-status-pill';
import { RemittancePaymentStatus } from './remittance-payment-status';
import { RemittancePaymentStatusPill } from '../shared/remittance-payment-status-pill';
import { RemittanceType } from './remittance-type';
import { Contribution } from './contribution';
import { RemittanceOwner } from './remittance-owner';
import { Employer } from '../account/dto/employer';
import { RemittanceStatusIdEnum } from '../enum/shared/remittance-status-id.enum';
import { Transaction } from '../transactions/transaction';
import { PortalType } from '../enum/shared/portal-type';
import { BaseRemittance } from './base-remittance';

export class Remittance extends BaseRemittance implements Deserializable {
  public typeId!: number;
  public paymentStatusId!: number;
  public ownerId!: number;
  public owner: RemittanceOwner | null = null;
  public type!: RemittanceType;
  public paymentStatus!: RemittancePaymentStatus;
  public employer!: Employer;
  public contributions: Contribution[] = [];
  public transactions: Transaction[] = [];

  // not from API
  public statusPill: RemittanceStatusPill | undefined;
  public paymentStatusPill: RemittancePaymentStatusPill | undefined;

  public contributionEntriesAreEditable(portal: PortalType): boolean {
    if (portal === PortalType.Internal) {
      return this.statusId === RemittanceStatusIdEnum.Submitted;
    } else {
      return this.statusId === RemittanceStatusIdEnum.Pending;
    }
  }

  public isEditable(portal: PortalType): boolean {
    if (portal === PortalType.Internal) {
      return ['Submitted', 'In Review', 'Pending', 'Needs Attention'].includes(this.status?.name);
    } else if (portal === PortalType.Employer) {
      return ['Pending', 'In Review'].includes(this.status?.name);
    } else {
      return false;
    }
  }

  public isProcessing(): boolean {
    return this.status.id !== RemittanceStatusIdEnum.Pending;
  }

  public onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    if (!!this.paymentStatus) {
      this.paymentStatus = Deserialize?.instanceOf(RemittancePaymentStatus, this.paymentStatus);
      this.paymentStatusPill = new RemittancePaymentStatusPill(this.paymentStatus.id, this.paymentStatus.name);
    }
    if (!!this.status) this.statusPill = new RemittanceStatusPill(this.status.id, this.status.name);
    if (!!this.type) {
      this.type = Deserialize?.instanceOf(RemittanceType, this.type);
    }
    if (!!this.employer) {
      this.employer = Deserialize?.instanceOf(Employer, this.employer);
    }
    this.contributions = Deserialize?.arrayOf(Contribution, this.contributions);
    if (!!this.owner) {
      this.owner = Deserialize?.instanceOf(RemittanceOwner, this.owner);
    }
    if (!!this.transactions) {
      this.transactions = Deserialize?.arrayOf(Transaction, this.transactions);
    }
  }
}
