import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TransactionEntry } from '../../../models/transactions/transaction-entry';

@Pipe({
  name: 'entryFirstCurrencySymbol'
})
export class EntryFirstCurrencySymbolPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'CAD'
  ) {}

  transform(
    entry: TransactionEntry,
    entriesArr: TransactionEntry[],
    debitOrCredit: 'debitAmount' | 'creditAmount'
  ): string | null {
    const amount = entry[debitOrCredit];
    if (amount === null) {
      return null;
    }
    const relevantEntries = entriesArr.filter(e => e[debitOrCredit] !== null);
    const isFirstEntry = relevantEntries.first()?.id === entry.id;

    if (isFirstEntry) {
      return new Intl.NumberFormat(this._locale, {
        style: 'currency',
        currency: this._defaultCurrencyCode
      }).format(amount);
    }

    const resolvedOptions = new Intl.NumberFormat(this._locale, {
      style: 'currency',
      currency: this._defaultCurrencyCode
    }).resolvedOptions();
    const maximumFractionDigits = resolvedOptions.maximumFractionDigits;
    const minimumFractionDigits = resolvedOptions.minimumFractionDigits;

    return amount.toLocaleString(this._locale, {
      maximumFractionDigits,
      minimumFractionDigits
    });
  }
}
