import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { AssetSize } from '../../../../../models/enum/dto/asset-size.enum';
import { Asset } from '../../../../../models/asset/dto/asset';

@Component({
  selector: 'app-employer-logo',
  templateUrl: './employer-logo.component.html',
  styleUrls: ['./employer-logo.component.scss', '../../auth/auth.component.scss']
})
export class EmployerLogoComponent extends BaseComponent {
  @Input() employerLogo: Asset | null = null;

  setupBindings(): void {}

  setupViews(): void {}

  protected readonly AssetSize = AssetSize;
}
