import { inject, Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { RemittancesAPI } from '../api/remittances-api';
import { Observable, throwError } from 'rxjs';
import { Remittance } from '../models/remittances/remittance';
import { map } from 'rxjs/operators';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { Contribution } from '../models/remittances/contribution';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { ContributionSummary } from '../models/remittances/contribution-summary';
import { RemittanceOwner } from '../models/remittances/remittance-owner';
import {
  RemittancePotentialMatchedMember,
  RemittanceVerificationMember
} from '../models/remittances/remittance-verification-member';
import { VerifyContributionRequest } from '../models/remittances/verify-contribution-request';
import { UpdateContributionRequest } from '../models/remittances/update-contribution-request';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { ContributionEntry } from '../models/remittances/contribution-entry';
import { RemittanceAddDatesRequest } from '../models/remittances/remittance-add-dates-request';
import { RemittanceUpdateNewMemberRequest } from '../models/remittances/remittance-update-new-member-request';
import { RemittanceCreateNewMemberRequest } from '../models/remittances/remittance-create-new-member-request';
import { RemittanceFrequencyEnum } from '../models/remittances/remittance-frequency';
import { CreateScheduledRemittanceRequest } from '../models/remittances/create-scheduled-remittance-request';
import { ScheduledRemittance } from '../models/remittances/scheduled-remittance';
import { RemittanceStatusIdEnum } from '../models/enum/shared/remittance-status-id.enum';
import { FailRemittanceRequest } from '../models/account/requests/fail-remittance-request';
import { CustomError } from '../models/shared/custom-error';
import { RemittanceOwnerUpdateRequest } from '../models/account/requests/remittance-owner-update-request';
import { RemittanceComment } from '../models/remittances/remittance-comment';
import { RemittanceCommentRequest } from '../models/remittances/remittance-comment-request';
import { CreateRemittancePaymentRequest } from '../models/payments/create-remittance-payment-request';
import { RemittancePayment } from '../models/payments/remittance-payment';
import { UpdateScheduledRemittanceRequest } from '../models/remittances/update-scheduled-remittance-request';
import { ScheduledRemittanceContribution } from '../models/remittances/scheduled-remittance-contribution';
import { RemittanceNewMember } from '../models/remittances/remittance-new-member';
import { ScheduledRemittanceNewMember } from '../models/remittances/scheduled-remittance-new-member';
import { AddScheduledRemittanceContributionRequest } from '../models/account/requests/add-scheduled-remittance-contribution-request';
import { ScheduledRemittanceContributionSummary } from '../models/remittances/scheduled-remittance-contribution-summary';
import { ScheduledRemittanceHistory } from '../models/remittances/scheduled-remittance-history';
import { ScheduledRemittanceCommentRequest } from '../models/remittances/scheduled-remittance-comment-request';

@Injectable()
export class RemittanceDomainModel extends BaseDomainModel {
  private remittancesAPI = inject(RemittancesAPI);

  public getOpenRemittancesForEmployer(employerId: number): Observable<Remittance[]> {
    return this.remittancesAPI.getOpenRemittancesForEmployer(employerId).pipe(map(response => response.value));
  }

  public getClosedRemittanceCountForEmployer(employerId: number): Observable<number> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setCount(true);
    queryOptions.setTop(0);
    return this.remittancesAPI
      .getClosedRemittancesForEmployer(employerId, queryOptions)
      .pipe(map(response => response['@odata.count'] ?? 0));
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    oDataOpts: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    oDataOpts.setExpand('Type, Status, PaymentStatus, Source, Owner');
    return this.remittancesAPI.getClosedRemittancesForEmployer(employerId, oDataOpts);
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    return this.remittancesAPI.createRemittance(req);
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    return this.remittancesAPI.getRemittanceById(remittanceId, queryOptions);
  }

  public getRemittanceOwners(
    oDataQueryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<RemittanceOwner[]> {
    return this.remittancesAPI.getRemittanceOwners(oDataQueryOptions).pipe(map(response => response.value));
  }

  public getRemittanceComments(remittanceId: number): Observable<RemittanceComment[]> {
    return this.remittancesAPI.getRemittanceComments(remittanceId);
  }

  public addRemittanceComment(req: RemittanceCommentRequest): Observable<RemittanceComment[]> {
    return this.remittancesAPI.addRemittanceComment(req);
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<ODataResponse<Contribution>> {
    queryOptions.setOrderBy('memberLastName asc');
    return this.remittancesAPI.getContributionsForRemittance(remittanceId, queryOptions);
  }

  public getContributionsForScheduledRemittance(
    scheduleId: number,
    queryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<ODataResponse<ScheduledRemittanceContribution>> {
    queryOptions.setOrderBy('memberLastName asc');
    return this.remittancesAPI.getContributionsForScheduledRemittance(scheduleId, queryOptions);
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    return this.remittancesAPI
      .getRemittanceHistory(remittanceId)
      .pipe(map(history => history.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())));
  }

  public createRemittanceNewMember(
    remittanceId: number,
    req: RemittanceCreateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    return this.remittancesAPI.createRemittanceNewMember(remittanceId, req);
  }

  public updateRemittanceNewMember(
    remittanceId: number,
    req: RemittanceUpdateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    return this.remittancesAPI.updateRemittanceNewMember(remittanceId, req);
  }

  public getNewMembersOnRemittance(remittanceId: number): Observable<RemittanceNewMember[]> {
    return this.remittancesAPI.getNewMembersOnRemittance(remittanceId).pipe(map(response => response.value));
  }

  public getContributionHistory(remittanceId: number, contributionId: string): Observable<RemittanceHistory[]> {
    return this.remittancesAPI.getContributionHistory(remittanceId, contributionId);
  }

  public getScheduledRemittanceContributionHistory(
    scheduleId: number,
    contributionId: string
  ): Observable<ScheduledRemittanceHistory[]> {
    return this.remittancesAPI.getScheduledRemittanceContributionHistory(scheduleId, contributionId);
  }

  public getContributionSummaryForRemittance(remittanceId: number): Observable<ContributionSummary> {
    return this.remittancesAPI.getContributionSummaryForRemittance(remittanceId.toString(10));
  }

  public getContributionSummaryForScheduledRemittance(
    scheduleId: number
  ): Observable<ScheduledRemittanceContributionSummary> {
    return this.remittancesAPI.getContributionSummaryForScheduledRemittance(scheduleId.toString(10));
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    return this.remittancesAPI.addContribution(remittanceId, req);
  }

  public addSheduledRemittanceContribution(
    scheduleId: number,
    req: AddScheduledRemittanceContributionRequest
  ): Observable<ScheduledRemittanceContribution> {
    return this.remittancesAPI.addScheduledRemittanceContribution(scheduleId, req);
  }

  public getRemittanceMembersToVerify(remittanceId: string): Observable<ODataResponse<RemittanceVerificationMember>> {
    return this.remittancesAPI.getRemittanceMembersToVerify(remittanceId);
  }

  public getScheduleMembersToVerify(scheduleId: string): Observable<ODataResponse<RemittanceVerificationMember>> {
    return this.remittancesAPI.getScheduleMembersToVerify(scheduleId);
  }

  public getOverlappingScheduledRemittanceCount(
    employerId: number,
    frequencyId: RemittanceFrequencyEnum,
    startDate: string
  ): Observable<number> {
    return this.remittancesAPI.getOverlappingScheduledRemittanceCount(employerId, frequencyId, startDate);
  }

  public getNextFiveRemittanceDates(
    frequencyId: RemittanceFrequencyEnum,
    startDate: string,
    endDate?: string
  ): Observable<string[]> {
    return this.remittancesAPI.getNextFiveRemittanceDates(frequencyId, startDate, endDate);
  }

  public getPotentialMatchesForContribution(
    remittanceId: string,
    contributionId: string
  ): Observable<ODataResponse<RemittancePotentialMatchedMember>> {
    return this.remittancesAPI.getPotentialMatchesForContribution(remittanceId, contributionId);
  }

  public getPotentialMatchesForScheduledContribution(
    scheduleId: string,
    contributionId: string
  ): Observable<ODataResponse<RemittancePotentialMatchedMember>> {
    return this.remittancesAPI.getPotentialMatchesForScheduledContribution(scheduleId, contributionId);
  }

  public verifyContribution(
    remittanceId: string,
    contributionId: string,
    req: VerifyContributionRequest
  ): Observable<Contribution> {
    return this.remittancesAPI.verifyContribution(remittanceId, contributionId, req);
  }

  public verifyScheduleContribution(
    scheduleId: string,
    contributionId: string,
    req: VerifyContributionRequest
  ): Observable<ScheduledRemittanceContribution> {
    return this.remittancesAPI.verifyScheduleContribution(scheduleId, contributionId, req);
  }

  public updateContribution(
    remittanceId: number,
    contributionId: string,
    req: UpdateContributionRequest
  ): Observable<Contribution> {
    return this.remittancesAPI.updateContribution(remittanceId, contributionId, req);
  }

  public updateScheduleContribution(
    scheduleId: number,
    contributionId: string,
    req: UpdateContributionRequest
  ): Observable<ScheduledRemittanceContribution> {
    return this.remittancesAPI.updateScheduleContribution(scheduleId, contributionId, req);
  }

  public deleteContribution(remittanceId: number, contributionId: string): Observable<any> {
    return this.remittancesAPI.deleteContribution(remittanceId, contributionId);
  }

  public deleteScheduledRemittanceContribution(scheduleId: number, contributionId: string): Observable<any> {
    return this.remittancesAPI.deleteScheduledRemittanceContribution(scheduleId, contributionId);
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    return this.remittancesAPI.createContributionEntry(remittanceId, contributionId, entries);
  }

  public createScheduledRemittanceContributionEntry(
    scheduleId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<ScheduledRemittanceContribution> {
    return this.remittancesAPI.createScheduledRemittanceContributionEntry(scheduleId, contributionId, entries);
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    return this.remittancesAPI.updateContributionEntry(remittanceId, contributionId, entry);
  }

  public updateScheduledRemittanceContributionEntry(
    scheduleId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    return this.remittancesAPI.updateScheduledRemittanceContributionEntry(scheduleId, contributionId, entry);
  }

  public submitRemittance(remittanceId: number): Observable<Remittance> {
    return this.remittancesAPI.submitRemittance(remittanceId);
  }

  public deleteRemittance(remittanceId: number): Observable<any> {
    return this.remittancesAPI.deleteRemittance(remittanceId);
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    return this.remittancesAPI.deleteContributionEntry(remittanceId, contributionId, entryId);
  }

  public deleteScheduledRemittanceContributionEntry(
    scheduleId: number,
    contributionId: string,
    entryId: string
  ): Observable<any> {
    return this.remittancesAPI.deleteScheduledRemittanceContributionEntry(scheduleId, contributionId, entryId);
  }

  public addTaxationYearToRemittance(remittanceId: number, req: RemittanceAddDatesRequest): Observable<Remittance> {
    return this.remittancesAPI.addTaxationYearToRemittance(remittanceId, req);
  }

  public addTaxationYearToScheduledRemittance(
    scheduleId: number,
    req: RemittanceAddDatesRequest
  ): Observable<ScheduledRemittance> {
    return this.remittancesAPI.addTaxationYearToScheduledRemittance(scheduleId, req);
  }

  public updateRemittanceStatus(
    remittanceId: number,
    remittanceStatusId: RemittanceStatusIdEnum
  ): Observable<Remittance> {
    switch (remittanceStatusId) {
      case RemittanceStatusIdEnum.InReview:
        return this.remittancesAPI.updateRemittanceStatusInReview(remittanceId);
      case RemittanceStatusIdEnum.Failed:
        const req = new FailRemittanceRequest(remittanceId, 'Marked as Failed');
        return this.remittancesAPI.updateRemittanceStatusFailed(remittanceId, req);
      case RemittanceStatusIdEnum.Approved:
        return this.remittancesAPI.updateRemittanceStatusApproved(remittanceId);
      default:
        const err = new CustomError();
        err.title = $localize`Invalid Status`;
        return throwError(() => err);
    }
  }

  public updateRemittanceOwner(req: RemittanceOwnerUpdateRequest): Observable<Remittance> {
    return this.remittancesAPI.updateRemittanceOwner(req);
  }

  public createScheduledRemittance(req: CreateScheduledRemittanceRequest): Observable<ScheduledRemittance> {
    return this.remittancesAPI.createScheduledRemittance(req);
  }

  public getScheduledRemittancesByStatusId(statusId: number) {
    return this.remittancesAPI.getScheduledRemittancesByStatusId(statusId);
  }

  public getScheduledRemittanceById(
    remittanceId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ScheduledRemittance> {
    return this.remittancesAPI.getScheduledRemittanceById(remittanceId, queryOptions);
  }

  public updateScheduledRemittance(req: UpdateScheduledRemittanceRequest): Observable<ScheduledRemittance> {
    return this.remittancesAPI.updateScheduledRemittance(req);
  }

  public createRemittancePayment(req: CreateRemittancePaymentRequest): Observable<RemittancePayment> {
    return this.remittancesAPI.createRemittancePayment(req);
  }

  public getAllRemittances(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Remittance>> {
    oDataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner');
    return this.remittancesAPI.getAllRemittances(oDataQueryOptions);
  }

  public createScheduledRemittanceNewMember(
    remittanceId: number,
    req: RemittanceCreateNewMemberRequest
  ): Observable<ScheduledRemittanceNewMember> {
    return this.remittancesAPI.createScheduledRemittanceNewMember(remittanceId, req);
  }

  public updateScheduledRemittanceNewMember(
    remittanceId: number,
    req: RemittanceUpdateNewMemberRequest
  ): Observable<ScheduledRemittanceNewMember> {
    return this.remittancesAPI.updateScheduledRemittanceNewMember(remittanceId, req);
  }

  public getNewMembersOnScheduledRemittance(remittanceId: number): Observable<ScheduledRemittanceNewMember[]> {
    return this.remittancesAPI.getNewMembersOnScheduledRemittance(remittanceId).pipe(map(response => response.value));
  }

  public getScheduledRemittanceComments(scheduledRemittanceId: number): Observable<RemittanceComment[]> {
    return this.remittancesAPI.getScheduledRemittanceComments(scheduledRemittanceId);
  }

  public addScheduledRemittanceComment(req: ScheduledRemittanceCommentRequest): Observable<RemittanceComment[]> {
    return this.remittancesAPI.addScheduledRemittanceComment(req);
  }

  public getScheduledRemittanceHistory(scheduledRemittanceId: number): Observable<ScheduledRemittanceHistory[]> {
    return this.remittancesAPI
      .getScheduledRemittanceHistory(scheduledRemittanceId)
      .pipe(map(history => history.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())));
  }

  public deleteScheduledRemittance(remittanceId: number): Observable<any> {
    return this.remittancesAPI.deleteScheduledRemittance(remittanceId);
  }

  public cancelScheduledRemittance(remittanceId: number): Observable<ScheduledRemittance> {
    return this.remittancesAPI.cancelScheduledRemittance(remittanceId);
  }

  public setScheduledRemittanceToScheduled(remittanceId: number): Observable<any> {
    return this.remittancesAPI.setScheduledRemittanceToScheduled(remittanceId);
  }
}
