import { PermissionTypeMap } from '../base/permission-type-map';
import { PermissionType } from '../base/permission-type';
import { PermissionMap } from '../base/permission-map';

export class InternalUserPermissionMap extends PermissionMap {
  public map: Map<number, PermissionTypeMap> = new Map<number, PermissionTypeMap>();

  constructor() {
    super();
    this.map.set(1, new PermissionTypeMap(1, PermissionType.Route, 'internal-users'));
    this.map.set(2, new PermissionTypeMap(2, PermissionType.Field, 'internalUserEmail'));
    this.map.set(3, new PermissionTypeMap(3, PermissionType.Field, 'internalUserRole'));
    this.map.set(4, new PermissionTypeMap(4, PermissionType.Field, 'internalUserStatus'));
    this.map.set(5, new PermissionTypeMap(5, PermissionType.Route, 'internal-users:internalId'));
    this.map.set(6, new PermissionTypeMap(6, PermissionType.Action, 'createInternalUser'));
    this.map.set(7, new PermissionTypeMap(7, PermissionType.Field, 'internalUserFirstName'));
    this.map.set(8, new PermissionTypeMap(8, PermissionType.Field, 'internalUserLastName'));
    this.map.set(9, new PermissionTypeMap(9, PermissionType.Field, 'internalUserRole'));
    this.map.set(10, new PermissionTypeMap(10, PermissionType.Field, 'internalUserStatus'));
    this.map.set(11, new PermissionTypeMap(11, PermissionType.Action, 'updateInternalUserEmail'));
    this.map.set(12, new PermissionTypeMap(12, PermissionType.Action, 'resetInternalUserPassword'));
    this.map.set(13, new PermissionTypeMap(13, PermissionType.Action, 'resendInternalUserInvite'));
    this.map.set(14, new PermissionTypeMap(14, PermissionType.Route, 'internal-user-roles'));
    this.map.set(15, new PermissionTypeMap(15, PermissionType.Field, 'internalUserRoleName'));
    this.map.set(16, new PermissionTypeMap(16, PermissionType.Field, 'internalUserRoleAssignedUserCount'));
    this.map.set(17, new PermissionTypeMap(17, PermissionType.Route, 'internal-user-roles:internalUsersRoleId'));
    this.map.set(18, new PermissionTypeMap(18, PermissionType.Route, 'submissions'));
    this.map.set(19, new PermissionTypeMap(19, PermissionType.Action, 'viewSubmissionPermissions'));
    this.map.set(20, new PermissionTypeMap(20, PermissionType.Action, 'editSubmissionPermissions'));
    this.map.set(21, new PermissionTypeMap(21, PermissionType.Route, 'records/members:memberId'));
    this.map.set(22, new PermissionTypeMap(22, PermissionType.Field, 'memberAddress'));
    this.map.set(23, new PermissionTypeMap(23, PermissionType.Field, 'memberPhoneNumbers'));
    this.map.set(24, new PermissionTypeMap(24, PermissionType.Field, 'memberEmail'));
    this.map.set(25, new PermissionTypeMap(25, PermissionType.Action, 'changeMemberEmail'));
    this.map.set(26, new PermissionTypeMap(26, PermissionType.Action, 'resetMemberPassword'));
    this.map.set(27, new PermissionTypeMap(27, PermissionType.Field, 'memberGender'));
    this.map.set(28, new PermissionTypeMap(28, PermissionType.Field, 'memberPronouns'));
    this.map.set(29, new PermissionTypeMap(29, PermissionType.Field, 'memberBirthdate'));
    this.map.set(30, new PermissionTypeMap(30, PermissionType.Field, 'memberPartialSin'));
    this.map.set(31, new PermissionTypeMap(31, PermissionType.Field, 'memberFullSin'));
    this.map.set(32, new PermissionTypeMap(32, PermissionType.Field, 'memberAccounts'));
    this.map.set(33, new PermissionTypeMap(33, PermissionType.Card, 'memberEmploymentHistory'));
    this.map.set(34, new PermissionTypeMap(34, PermissionType.Card, 'viewMemberSubmissionsCard'));
    this.map.set(35, new PermissionTypeMap(35, PermissionType.Card, 'hideMemberSubmissionsCard'));
    this.map.set(36, new PermissionTypeMap(36, PermissionType.Card, 'viewEmployerSubmissionsCard'));
    this.map.set(37, new PermissionTypeMap(37, PermissionType.Card, 'hideEmployerSubmissionsCard'));
    this.map.set(38, new PermissionTypeMap(38, PermissionType.Card, 'viewRemittancesCard'));
    this.map.set(39, new PermissionTypeMap(39, PermissionType.Card, 'hideRemittancesCard'));
    this.map.set(40, new PermissionTypeMap(40, PermissionType.Card, 'viewReportsCard'));
    this.map.set(41, new PermissionTypeMap(41, PermissionType.Card, 'hideReportsCard'));
    this.map.set(42, new PermissionTypeMap(42, PermissionType.Route, 'settings/alerts'));
    this.map.set(43, new PermissionTypeMap(43, PermissionType.Action, 'createAlerts'));
    this.map.set(44, new PermissionTypeMap(44, PermissionType.Route, 'settings/alerts/:alertId')); // TODO
    this.map.set(45, new PermissionTypeMap(45, PermissionType.Action, 'canEditAlerts')); // TODO
  }
}
