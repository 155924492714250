export enum RemittancePaymentStatusPrimaryColorEnum {
  Pending = '#B24819',
  Paid = '#395200',
  PastDue = '#9D0209',
  Failed = '#9D0209',
  Cancelled = '#3B3B3C',
  Partial = '#104255',
  Refunded = '#5E0F5E',
  NotApplicable = '#3B3B3C'
}

export enum RemittancePaymentStatusSecondaryColorEnum {
  Pending = '#FFEAD1',
  Paid = '#E8F7D9',
  PastDue = '#FFEAEB',
  Failed = '#FFEAEB',
  Cancelled = '#FAFAFA',
  Partial = '#DCEBFA',
  Refunded = '#FADCFA',
  NotApplicable = '#FAFAFA'
}

export enum RemittancePaymentStatusBorderColorEnum {
  Pending = '#FB932A',
  Paid = '#78AB00',
  PastDue = '#FF8E93',
  Failed = '#FF8E93',
  Cancelled = '#B9B9B9',
  Partial = '#68C3EB',
  Refunded = '#C97DC9',
  NotApplicable = '#B9B9B9'
}
/* eslint-disable @typescript-eslint/naming-convention */
export const RemittancePaymentStatusPrimaryColorMap: Record<number, string> = {
  1: RemittancePaymentStatusPrimaryColorEnum.Pending,
  2: RemittancePaymentStatusPrimaryColorEnum.Paid,
  3: RemittancePaymentStatusPrimaryColorEnum.PastDue,
  4: RemittancePaymentStatusPrimaryColorEnum.Failed,
  5: RemittancePaymentStatusPrimaryColorEnum.Cancelled,
  6: RemittancePaymentStatusPrimaryColorEnum.Partial,
  7: RemittancePaymentStatusPrimaryColorEnum.Refunded,
  8: RemittancePaymentStatusPrimaryColorEnum.NotApplicable
};

export const RemittancePaymentStatusSecondaryColorMap: Record<number, string> = {
  1: RemittancePaymentStatusSecondaryColorEnum.Pending,
  2: RemittancePaymentStatusSecondaryColorEnum.Paid,
  3: RemittancePaymentStatusSecondaryColorEnum.PastDue,
  4: RemittancePaymentStatusSecondaryColorEnum.Failed,
  5: RemittancePaymentStatusSecondaryColorEnum.Cancelled,
  6: RemittancePaymentStatusSecondaryColorEnum.Partial,
  7: RemittancePaymentStatusSecondaryColorEnum.Refunded,
  8: RemittancePaymentStatusSecondaryColorEnum.NotApplicable
};
export const RemittancePaymentStatusBorderColorMap: Record<number, string> = {
  1: RemittancePaymentStatusBorderColorEnum.Pending,
  2: RemittancePaymentStatusBorderColorEnum.Paid,
  3: RemittancePaymentStatusBorderColorEnum.PastDue,
  4: RemittancePaymentStatusBorderColorEnum.Failed,
  5: RemittancePaymentStatusBorderColorEnum.Cancelled,
  6: RemittancePaymentStatusBorderColorEnum.Partial,
  7: RemittancePaymentStatusBorderColorEnum.Refunded,
  8: RemittancePaymentStatusBorderColorEnum.NotApplicable
};
/* eslint-enable @typescript-eslint/naming-convention */
