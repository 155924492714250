import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { ODataQueryOptions } from '../../../../../models/shared/odata-query-options';
import { BehaviorSubject, combineLatest, Observable, of, skip, Subject } from 'rxjs';
import { SlidingWindowOptions } from '../../../../../models/shared/sliding-window-options';
import { CustomError } from '../../../../../models/shared/custom-error';
import { ToastService } from '../../../../../services/toast-service';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PermissionService } from '../../../../../services/permission-service';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { EmployerUserTableFilterRequest } from '../../../../../models/shared/odata-filter-requests/employer-user-table-filter-request';
import { EmployerUserTableOrderbyRequest } from '../../../../../models/shared/odata-sorting-requests/employer-user-table-orderby-request';
import { EmployersDomainModel } from '../../../../../domainModels/employers-domain-model';
import { EmployerUser } from '../../../../../models/account/dto/employer-user';
import { PermissionDomainModel } from '../../../../../domainModels/permission-domain-model';
import { OdataFilterGenerator } from '../../../../../interfaces/odata-filter-generator';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';

@Injectable()
export class EmployerUsersDataTableViewModel extends BaseViewModel {
  constructor(private toastService: ToastService, private router: Router) {
    super();
  }

  private employersDomainModel = inject(EmployersDomainModel);
  private permissionService = inject(PermissionService);
  private userDomainModel = inject(UserDomainModel);
  private permissionDomainModel = inject(PermissionDomainModel);

  private employerId$ = this.userDomainModel.user$.pipe(map(u => (u as EmployerUser)?.employerId?.toString(10)));

  public employerUsers$ = this.employersDomainModel.employerUsers$.pipe(skip(1));
  public employerUserTotalCount$ = this.employersDomainModel.employerUsersTotalCount$;

  private _showFilterWindow = new BehaviorSubject<boolean>(false);
  public readonly showFilterWindow$ = this._showFilterWindow as Observable<boolean>;

  private _activeFilterCount = new BehaviorSubject<number>(0);
  public readonly activeFilterCount$ = this._activeFilterCount as Observable<number>;

  private _activeSearchCriteria = new BehaviorSubject<number>(0);
  public readonly activeSearchCriteria$ = this._activeSearchCriteria as Observable<number>;

  public filterWindowOptions$ = of(
    new SlidingWindowOptions($localize`Filter Users`, $localize`Clear All`, $localize`Apply Filters`)
  );

  public roles$ = this.permissionDomainModel.roles$;

  private _loadingEmployerUsers = new BehaviorSubject<boolean>(false);
  public loadingEmployerUsers$ = this._loadingEmployerUsers as Observable<boolean>;

  public showEmployerUserRole$ = this.permissionService.permissionGranted$([3]);
  public showEmployerUserEmail$ = this.permissionService.permissionGranted$([2]);
  public showEmployerUserStatus$ = this.permissionService.permissionGranted$([4]);
  public showEmployerUserViewDetails$ = this.permissionService.permissionGranted$([5]);

  private _employerUserFilterRequest = new BehaviorSubject<EmployerUserTableFilterRequest>(
    new EmployerUserTableFilterRequest()
  );
  public readonly employerUserFilterRequest$ = this._employerUserFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _employerUserOrderbyRequest = new BehaviorSubject<EmployerUserTableOrderbyRequest>(
    new EmployerUserTableOrderbyRequest()
  );
  public readonly employerUserOrderbyRequest$ = this
    ._employerUserOrderbyRequest as Observable<EmployerUserTableOrderbyRequest>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private _searchCriteriaChangedSubject = new Subject<EmployerUserTableFilterRequest>();
  public readonly searchCriteriaChanged$ = this
    ._searchCriteriaChangedSubject as Observable<EmployerUserTableFilterRequest>;

  public statusDropdowns$ = of([
    new DropDownItem($localize`Active`, 'true', false),
    new DropDownItem($localize`Inactive`, 'false', false)
  ]);

  private searchCriteriaChangedSubscription = this.searchCriteriaChanged$
    .distinctUniquelyIdentifiable()
    .pipe(debounceTime(500))
    .subscribeWhileAlive({
      owner: this,
      next: req => {
        this._employerUserFilterRequest.next(req);
        this._activeSearchCriteria.next(req.getSearchCount());
      }
    });

  private listenToFetchEmployerUsers = combineLatest([
    this.employerUserFilterRequest$,
    this.employerUserOrderbyRequest$,
    this.currPageNumber$,
    this.pageSize$,
    this.employerId$.notNull()
  ])
    .pipe(debounceTime(100))
    .subscribe(([filterRequest, orderbyRequest, currPage, pageSize, employerId]) => {
      this.fetchEmployerUsers(filterRequest, orderbyRequest, currPage, pageSize, employerId);
    });

  public toggleFilterWindow(): void {
    this._showFilterWindow.once(show => {
      this._showFilterWindow.next(!show);
    });
  }

  public employerUserClicked(u: EmployerUser) {
    this.userDomainModel.userId$.once(uId => {
      if (Number.parseInt(uId, 10) === u?.id) {
        this.router.navigate(['/profile']).then();
      } else {
        this.router.navigate([`/settings/employer-user/${u.id}`]).then();
      }
    });
  }

  public searchCriteriaChanged(req: OdataFilterGenerator): void {
    const copy = Object.assign(new EmployerUserTableFilterRequest(), req);
    this._searchCriteriaChangedSubject.next(copy as EmployerUserTableFilterRequest);
  }

  public filterFormSubmitted(req: EmployerUserTableFilterRequest): void {
    const copy = Object.assign(new EmployerUserTableFilterRequest(), req);
    this._activeFilterCount.next(copy.getFilterCount());
    this._employerUserFilterRequest.next(copy);
    this.toggleFilterWindow();
  }

  public resetFilterForm(): void {
    this.employerUserFilterRequest$.once(req => {
      const reqCopy = Object.assign(new EmployerUserTableFilterRequest(), req);
      reqCopy.clearFilters();
      this._employerUserFilterRequest.next(reqCopy as EmployerUserTableFilterRequest);
      this._activeFilterCount.next(0);
    });
  }

  public resetFilterField(fieldKeys: string[]): void {
    this.employerUserFilterRequest$.once(req => {
      const reqCopy = Object.assign(new EmployerUserTableFilterRequest(), req);
      fieldKeys.forEach(k => {
        if (k.includes('date' || 'search')) {
          reqCopy[k] = '';
        } else {
          reqCopy[k] = [];
        }
      });
      this._employerUserFilterRequest.next(reqCopy as EmployerUserTableFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public handleSortReq(sortReq: string): void {
    this._employerUserOrderbyRequest.next(new EmployerUserTableOrderbyRequest(sortReq));
  }

  public clearAllFilters(): void {
    this._employerUserFilterRequest.next(new EmployerUserTableFilterRequest());
    this._activeSearchCriteria.next(0);
    this._activeFilterCount.next(0);
  }

  public setPageSize(size: number): void {
    this._pageSize.next(size);
  }

  private fetchEmployerUsers(
    filterReq: EmployerUserTableFilterRequest,
    orderbyReq: EmployerUserTableOrderbyRequest,
    currPage: number,
    pageSize: number,
    employerId: string,
    showSpinner = true
  ): void {
    const lm = $localize`Loading Employer Users`;
    if (showSpinner) {
      this._loadingOpts.addRequest(lm);
    }
    this._loadingEmployerUsers.next(true);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterReq.getFilterString();
    const orderbyString = orderbyReq.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    this.employersDomainModel.getEmployerUsers(oDataParams, employerId).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this._loadingEmployerUsers.next(false);
      },
      error: (error: CustomError) => {
        this._loadingOpts.removeRequest(lm);
        this._loadingEmployerUsers.next(false);
        this.toastService.publishError(error);
      }
    });
  }

  public refreshEmployerUsers(): void {
    combineLatest([
      this.employerUserFilterRequest$,
      this.employerUserOrderbyRequest$,
      this.currPageNumber$,
      this.pageSize$,
      this.employerId$
    ]).once(([filterRequest, orderbyRequest, currPage, pageSize, employerId]) => {
      this.fetchEmployerUsers(filterRequest, orderbyRequest, currPage, pageSize, employerId, false);
    });
  }
}
