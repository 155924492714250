import { Directive, Inject } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appUniqueEmailValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UniqueEmailValidatorDirective, multi: true }]
})
export class UniqueEmailValidatorDirective {
  static key: string = 'uniqueEmail';

  constructor(@Inject('existingEmail') public existingEmail: string) {
    this.existingEmail = existingEmail;
  }

  getErrorMsg(existingEmail: string, value: string): string | null {
    if (existingEmail?.toLowerCase() === value?.toLowerCase()) return $localize`Email cannot already be used.`;
    return null;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const errorMsg = this.getErrorMsg(this.existingEmail, control.value);
    if (errorMsg) return { [UniqueEmailValidatorDirective.key]: errorMsg };
    return null;
  }
}
