import { BaseViewModel } from '../../../../models/base/base-view-model';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { BaseCommentRequest } from '../../../../models/base/base-comment-request';
import { BaseComment } from '../../../../models/base/base-comment';
import { PortalService } from '../../../../services/portal/portal.service';
import { TabBarItem } from '../tab-bar/tab-bar-item';
import { filter, map, tap } from 'rxjs/operators';
import { SlidingWindowCommentsTabComponent } from './sliding-window-comments-tab/sliding-window-comments-tab.component';
import { SlidingWindowHistoryTabComponent } from './sliding-window-history-tab/sliding-window-history-tab.component';
import { UserTaggingDropdownItem } from '../../../../models/shared/user-tagging-dropdown-item';
import { SlidingWindowOptions } from '../../../../models/shared/sliding-window-options';
import { StringExtensions } from '../../../../utils/string.extensions';
import { ScreenService } from '../../../../services/screen-service.service';
import { BaseRemittanceHistory } from '../../../../models/remittances/base-remittance-history';

enum CommentHistoryWindowTabEnum {
  Comments = 'Comments',
  History = 'History'
}

export const commentHistoryWindowTabMap: Record<CommentHistoryWindowTabEnum, TabBarItem> = {
  [CommentHistoryWindowTabEnum.Comments]: new TabBarItem(SlidingWindowCommentsTabComponent, $localize`Comments`, ''),
  [CommentHistoryWindowTabEnum.History]: new TabBarItem(
    SlidingWindowHistoryTabComponent,
    $localize`Remittance History`,
    ''
  )
};

@Injectable()
export class SlidingWindowCommentsAndHistoryViewModel extends BaseViewModel implements OnDestroy {
  private portalService = inject(PortalService);
  private screenService = inject(ScreenService);

  private _showCommentWindow = new BehaviorSubject<boolean>(false);
  public readonly showCommentWindow$ = this._showCommentWindow as Observable<boolean>;

  private _isTypingComment = new BehaviorSubject<boolean>(false);
  public isTypingComment$ = this._isTypingComment as Observable<boolean>;

  private _commentReq = new BehaviorSubject<BaseCommentRequest>(new BaseCommentRequest());
  public commentReq$ = this._commentReq as Observable<BaseCommentRequest>;

  private _comments = new BehaviorSubject<BaseComment[]>([]);
  public comments$ = this._comments as Observable<BaseComment[]>;

  private _history = new BehaviorSubject<BaseRemittanceHistory[]>([]);
  public history$ = this._history as Observable<BaseRemittanceHistory[]>;

  private _userDropdownItems = new BehaviorSubject<UserTaggingDropdownItem[]>([]);
  public userDropdownItems$ = this._userDropdownItems as Observable<UserTaggingDropdownItem[]>;

  private _activeTab = new BehaviorSubject<TabBarItem | null>(null);
  public activeTab$ = this._activeTab.pipe(filter((tab): tab is TabBarItem => tab !== null));

  private _isLoadingComments = new BehaviorSubject<boolean>(false);
  public isLoadingComments$ = this._isLoadingComments as Observable<boolean>;

  public newComment$ = new Subject<BaseCommentRequest>();
  public filterText$ = new Subject<string>();
  public submitCommentForm$ = new Subject<void>();

  public tabs$ = this.portalService.isEmployer$.pipe(
    tap(() => {
      Object.values(commentHistoryWindowTabMap).forEach(tab => {
        tab.active = false;
      });
    }),
    map(isEmployer => {
      const tabs = [];
      if (!isEmployer) {
        tabs.push(commentHistoryWindowTabMap.Comments);
      }
      tabs.push(commentHistoryWindowTabMap.History);
      return tabs;
    })
  );

  public commentInProgress$ = combineLatest([this.isTypingComment$, this.activeTab$]).pipe(
    map(([isTypingComment, tab]) => {
      const isCommentsTab = tab.componentType === SlidingWindowCommentsTabComponent;
      return isTypingComment && isCommentsTab;
    })
  );

  public commentWindowOptions$ = this.commentInProgress$.pipe(
    map(commentInProgress => {
      const ctaText = commentInProgress ? $localize`Post` : $localize`Done`;
      return new SlidingWindowOptions($localize`Communication Log`, $localize`Cancel`, ctaText);
    })
  );

  public toggleCommentWindow(): void {
    this.showCommentWindow$.once(value => {
      value ? this.screenService.enableScroll() : this.screenService.disableScroll();
      this._showCommentWindow.next(!value);
    });
  }

  public setActiveTab(tab: TabBarItem): void {
    this.tabs$.once(tabs => {
      tabs.forEach(t => {
        t.active = t === tab;
      });
      this._activeTab.next(tab);
      this._isTypingComment.next(false);
    });
  }

  public onCommentValueChange(comment: string): void {
    this._isTypingComment.next(!StringExtensions.isEmptyOrWhitespace(comment));
  }

  public setComments(comments: BaseComment[]) {
    this._comments.next(comments);
  }

  public setCommentReq(req: BaseCommentRequest) {
    this._commentReq.next(req);
  }

  public submitCommentForm() {
    this.submitCommentForm$.next();
    this._isTypingComment.next(false);
  }

  public newComment(commentReq: BaseCommentRequest) {
    this.newComment$.next(commentReq);
  }

  public filterText(filterString: string) {
    this.filterText$.next(filterString);
  }

  public setUserDropdownItems(userDropdownItems: UserTaggingDropdownItem[]) {
    this._userDropdownItems.next(userDropdownItems);
  }

  public setIsLoadingComments(isLoadingComments: boolean) {
    this._isLoadingComments.next(isLoadingComments);
  }

  public setHistory(history: BaseRemittanceHistory[]) {
    this._history.next(history);
  }

  public ngOnDestroy() {
    this.screenService.enableScroll();
  }
}
