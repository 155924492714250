export class ConfirmationOptions {
  // Text
  public title: string = '';
  public bodyText: string = '';
  // CTAs
  public cancelText: string = $localize`Cancel`;
  public cancelButtonClass: string = 'neutral-button';
  public showContinue: boolean = true;
  public showCancel: boolean = true;
  public continueText: string = $localize`Continue`;
  public continueButtonClass: string = 'primary-button';
}
