<app-sliding-window [toggleWindow]="showCommentWindow$ | async" [contentPaddingRem]="0">
  <div header>
    <app-sliding-window-header
      [slidingWindowOptions]="commentWindowOptions$ | async"
      (closeButtonClicked)="toggleCommentWindow()">
    </app-sliding-window-header>
    <app-tab-bar [tabs]="tabs$ | async" [skipFragmentAppending]="true" (selectedTab)="setActiveTab($event)">
    </app-tab-bar>
    <hr class="my-0" />
  </div>
  <div content>
    <app-tab-content [showContent]="true" [tab]="(activeTab$ | async) ?? undefined"> </app-tab-content>
  </div>
  <app-sliding-window-footer
    footer
    [slidingWindowOptions]="commentWindowOptions$ | async"
    (primaryButtonClicked)="primaryButtonClicked()"
    (secondaryButtonClicked)="toggleCommentWindow()">
  </app-sliding-window-footer>
</app-sliding-window>
