import 'src/app/utils/array.extensions';
import 'src/app/utils/map.extensions';
import 'src/app/utils/behavior-subject.extensions';
import 'src/app/utils/replay-subject.extensions';
import 'src/app/utils/observable.extensions';
import 'src/app/utils/string.extensions';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './views/layout/components/dashboard/layout.component';
import { AuthModule } from './views/auth/auth.module';
import {
  NgbAccordionBody,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionItem,
  NgbAccordionToggle,
  NgbActiveModal,
  NgbCollapse
} from '@ng-bootstrap/ng-bootstrap';
import { NavSubItemComponent } from './views/layout/components/nav-items/nav-sub-item/nav-sub-item.component';
import { NavItemComponent } from './views/layout/components/nav-items/nav-item/nav-item.component';
import { SharedModule } from './views/shared/shared.module';
import { HashLocationStrategy, LocationStrategy, NgOptimizedImage } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthInterceptorInterceptor } from './services/interceptors/auth-interceptor.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './services/strategy/cache-route-reuse.strategy';
import { ConfirmationModule } from './modules/confirmation/confirmation.module';
import { BannerEventsModule } from './modules/banner-events/banner-events.module';
import { IsDefaultNavItemPipe } from './views/layout/components/nav-items/nav-item/pipes/is-base-nav-item.pipe';
import { SignOutNavItemComponent } from './views/layout/components/nav-items/nav-item/sign-out-nav-item/sign-out-nav-item.component';
import { IsSignOutNavItemPipe } from './views/layout/components/nav-items/nav-item/pipes/is-sign-out-nav-item.pipe';
import { IsDefaultNavSubItemPipe } from './views/layout/components/nav-items/nav-sub-item/pipes/is-default-nav-sub-item.pipe';
import { NavbarComponent } from './views/layout/components/navbar/navbar.component';
import { IsDropDownNavItemPipe } from './views/layout/components/nav-items/nav-item/pipes/is-drop-down-nav-item.pipe';
import { DropDownNavItemComponent } from './views/layout/components/nav-items/nav-item/drop-down-nav-item/drop-down-nav-item.component';
import { DesktopNavComponent } from './views/layout/components/navbar/components/desktop-nav/desktop-nav.component';
import { MobileNavComponent } from './views/layout/components/navbar/components/mobile-nav/mobile-nav.component';
import { CustomToastComponent } from './views/shared/components/custom-toast/custom-toast.component';
import { ProfileNavItemComponent } from './views/layout/components/nav-items/nav-item/profile-nav-item/profile-nav-item.component';
import { IsProfileNavItemPipe } from './views/layout/components/nav-items/nav-item/pipes/is-profile-nav-item.pipe';
import { PermissionsInterceptor } from './services/interceptors/permission.interceptor';
import { MobileDropDownNavItemComponent } from './views/layout/components/nav-items/nav-item/mobile-drop-down-nav-item/mobile-drop-down-nav-item.component';
import { MobileNavSubItemComponent } from './views/layout/components/nav-items/nav-sub-item/mobile-nav-sub-item/mobile-nav-sub-item.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavbarComponent,
    DesktopNavComponent,
    MobileNavComponent,
    NavSubItemComponent,
    NavItemComponent,
    SignOutNavItemComponent,
    DropDownNavItemComponent,
    IsDefaultNavItemPipe,
    IsSignOutNavItemPipe,
    IsDefaultNavSubItemPipe,
    IsDropDownNavItemPipe,
    ProfileNavItemComponent,
    IsProfileNavItemPipe,
    MobileDropDownNavItemComponent,
    MobileNavSubItemComponent
  ],
  imports: [
    // Do not change the order of AppRoutingModule. If you do, it will mess up the base route url redirect!
    AppRoutingModule,
    AuthModule,
    BannerEventsModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    ConfirmationModule,
    HttpClientModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      toastComponent: CustomToastComponent,
      maxOpened: 5,
      toastClass: 'custom-toast ngx-toastr'
    }),
    SharedModule,
    NgOptimizedImage,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionToggle,
    NgbAccordionCollapse,
    NgbAccordionBody,
    NgbCollapse
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PermissionsInterceptor,
      multi: true
    }
  ],
  exports: [NavItemComponent, SignOutNavItemComponent, IsDefaultNavItemPipe, IsSignOutNavItemPipe, NavSubItemComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
