import { Injectable } from '@angular/core';
import { ComponentCanDeactivate } from '../../models/protocols/component-can-deactivate';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor() {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<boolean> | Observable<boolean> {
    return component.canDeactivate();
  }
}
