export {};
declare global {
  export interface Number {
    mod(n: number): number;
  }
}

Number.prototype.mod = function (this: number, n: number) {
  return ((this % n) + n) % n;
};

export class NumberUtils {
  private static floatComparisonToleranceValue = 0.000001; // not using epsilon because it is not recommended

  static isEven(i: number): boolean {
    return i % 2 === 0;
  }

  static isOdd(i: number): boolean {
    return i % 2 !== 0;
  }

  static secondsToMilli(n: number): number {
    return n * 1000;
  }

  static getIntegerSum(total: number, num: number) {
    return total + Math.round(num);
  }

  static floatNumbersEqual(a: number, b: number) {
    return Math.abs(a - b) < this.floatComparisonToleranceValue;
  }

  static floatFirstGreaterThanSecond(a: number, b: number) {
    return a - b - this.floatComparisonToleranceValue > this.floatComparisonToleranceValue;
  }

  /** returns reduced version of input numerator and denominator: [reducedNumerator, reducedDenominator] */
  static reduceFraction(numerator: number, denominator: number) {
    const gcd = function greatestCommonDivisor(n: number, d: number): number {
      return d ? greatestCommonDivisor(d, n % d) : n;
    };
    const newDenominator = gcd(numerator, denominator);
    const reducedNumerator = numerator / newDenominator;
    const reducedDenominator = denominator / newDenominator;
    return [reducedNumerator, reducedDenominator];
  }

  static parseFloatOrNull(s: string) {
    const p = parseFloat(s);
    return isNaN(p) ? null : p;
  }

  static ifZeroReturnNull(n: number): number | null {
    if (n === 0) {
      return null;
    } else {
      return n;
    }
  }

  static getDecimalSigFigs(n: number): number {
    if (Math.floor(n) === n) return 0;
    return n?.toString()?.split('.')?.[1]?.length || 0;
  }

  static formatNumberWithLeadingZeros(number: number, length: number): string {
    const numString = number.toString();
    if (numString.length >= length) {
      return numString;
    }
    const numZeros = length - numString.length;
    const zeros = '0'.repeat(numZeros);
    return zeros + numString;
  }

  static formatToSigFigDecimals(n: number): string {
    const decSigFigs = NumberUtils.getDecimalSigFigs(n);
    return n.toFixed(decSigFigs);
  }

  static unique(list: number[]): number[] {
    return list?.filter((item, index) => list.indexOf(item) === index) || [];
  }
}
