import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of, skip, Subject } from 'rxjs';
import { SlidingWindowOptions } from '../../../../../models/shared/sliding-window-options';
import { AlertsDomainModel } from '../../../../../domainModels/alerts-domain-model';
import { inject, Injectable } from '@angular/core';
import { AlertsTableFilterRequest } from '../../../../../models/shared/odata-filter-requests/alerts-table-filter-request';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { AlertsTableOrderbyRequest } from '../../../../../models/shared/odata-sorting-requests/alerts-table-orderby-request';
import { OdataFilterGenerator } from '../../../../../interfaces/odata-filter-generator';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { ODataQueryOptions } from '../../../../../models/shared/odata-query-options';
import { CustomError } from '../../../../../models/shared/custom-error';
import { ToastService } from '../../../../../services/toast-service';
import { PortalTypeIdEnum } from '../../../../../models/enum/shared/portal-type-id.enum';
import { AlertStatusEnum } from '../../../../../models/alerts/enum/alert-status.enum';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';
import { StringExtensions } from '../../../../../utils/string.extensions';
import { Router } from '@angular/router';

@Injectable()
export class AlertsDataTableViewModel extends BaseViewModel {
  constructor(private toastService: ToastService) {
    super();
  }

  private alertsDomainModel = inject(AlertsDomainModel);
  private router = inject(Router);

  public alerts$ = this.alertsDomainModel.alerts$.pipe(skip(1));
  public alertsTotalCount$ = this.alertsDomainModel.alertsTotalCount$;

  private _showFilterWindow = new BehaviorSubject<boolean>(false);
  public readonly showFilterWindow$ = this._showFilterWindow as Observable<boolean>;

  private _activeFilterCount = new BehaviorSubject<number>(0);
  public readonly activeFilterCount$ = this._activeFilterCount as Observable<number>;

  private _activeSearchCriteria = new BehaviorSubject<number>(0);
  public readonly activeSearchCriteria$ = this._activeSearchCriteria as Observable<number>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private _selectedStartDate = new BehaviorSubject<string>('');
  public readonly selectedStartDate$ = this._selectedStartDate as Observable<string>;

  private _selectedEndDate = new BehaviorSubject<string>('');
  public readonly selectedEndDate$ = this._selectedEndDate as Observable<string>;

  private _selectedDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly selectedDates$ = this._selectedDates as Observable<NgbDate[]>;

  public readonly selectedDateString$ = this.selectedDates$.pipe(
    map(dates => {
      if (!dates.length) return '';
      const formatDayOrMonth = StringExtensions.formatDayOrMonth;
      let dateString = `${formatDayOrMonth(dates[0]?.day)}/${formatDayOrMonth(dates[0]?.month)}/${dates[0]?.year}`;
      if (dates[1]) {
        dateString += ` - ${formatDayOrMonth(dates[1]?.day)}/${formatDayOrMonth(dates[1]?.month)}/${dates[1]?.year}`;
      }
      return dateString;
    })
  );

  private _alertsTableFilterRequest = new BehaviorSubject<AlertsTableFilterRequest>(new AlertsTableFilterRequest());
  public readonly alertsTableFilterRequest$ = this._alertsTableFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _alertsTableOrderByRequest = new BehaviorSubject<AlertsTableOrderbyRequest>(new AlertsTableOrderbyRequest());
  public readonly alertsTableOrderByRequest$ = this._alertsTableOrderByRequest as Observable<AlertsTableOrderbyRequest>;

  private _searchCriteriaChangedSubject = new Subject<AlertsTableFilterRequest>();
  public readonly searchCriteriaChanged$ = this._searchCriteriaChangedSubject as Observable<AlertsTableFilterRequest>;

  private _loadingAlerts = new BehaviorSubject<boolean>(false);
  public loadingAlerts$ = this._loadingAlerts as Observable<boolean>;

  public clearDates$ = new Subject<void>();
  public clearSearches$ = new Subject<void>();

  public filterWindowOptions$ = of(
    new SlidingWindowOptions($localize`Filter Alerts`, $localize`Clear All`, $localize`Apply Filters`)
  );

  public statusDropdowns$ = of([
    new DropDownItem($localize`Active`, AlertStatusEnum.Active, false),
    new DropDownItem($localize`Past`, AlertStatusEnum.Past, false),
    new DropDownItem($localize`Upcoming`, AlertStatusEnum.Upcoming, false)
  ]);

  public typeDropdowns$ = of([
    new DropDownItem($localize`Card`, AlertTypeEnum.Card, false),
    new DropDownItem($localize`Banner`, AlertTypeEnum.Banner, false)
  ]);

  public portalDropdowns$ = of([
    new DropDownItem($localize`Member`, PortalTypeIdEnum.Member, false),
    new DropDownItem($localize`Internal`, PortalTypeIdEnum.Internal, false),
    new DropDownItem($localize`Employer`, PortalTypeIdEnum.Employer, false)
  ]);

  private searchCriteriaChangedSubscription = this.searchCriteriaChanged$
    .distinctUniquelyIdentifiable()
    .pipe(debounceTime(500))
    .subscribeWhileAlive({
      owner: this,
      next: req => {
        this._alertsTableFilterRequest.next(req);
        this._activeSearchCriteria.next(req.getSearchCount());
      }
    });

  private listenToFetchAlerts = combineLatest([
    this.alertsTableFilterRequest$,
    this.alertsTableOrderByRequest$,
    this.currPageNumber$,
    this.pageSize$
  ])
    .pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: ([filterRequest, orderbyRequest, currPage, pageSize]) => {
        this.fetchAlerts(filterRequest, orderbyRequest, currPage, pageSize);
      }
    });

  public toggleFilterWindow(): void {
    this._showFilterWindow.once(show => {
      this._showFilterWindow.next(!show);
    });
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public filterFormSubmitted(req: AlertsTableFilterRequest): void {
    this._activeFilterCount.next(req.getFilterCount());
    const copy = Object.assign(new AlertsTableFilterRequest(), req);
    this.generateDatesFromFilterRequest(copy);
    this._alertsTableFilterRequest.next(copy);
    this.toggleFilterWindow();
  }

  public handleSortReq(sortReq: string): void {
    this._alertsTableOrderByRequest.next(new AlertsTableOrderbyRequest(sortReq));
  }

  public searchCriteriaChanged(req: OdataFilterGenerator): void {
    const copy = Object.assign(new AlertsTableFilterRequest(), req);
    this._searchCriteriaChangedSubject.next(copy as AlertsTableFilterRequest);
  }

  private generateDatesFromFilterRequest(req: AlertsTableFilterRequest): void {
    const dates = [];
    if (!!req.startDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.startDate));
    }
    if (!!req.endDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.endDate));
    }
    this.setDateFilters(dates);
  }

  public setDateFilters(dates: NgbDate[]): void {
    this._selectedDates.next(dates);
  }

  public alertClicked(a: Alert) {
    this.router.navigate([`/settings/alerts/${a.id}`]).then();
  }

  public setPageSize(size: number): void {
    this._pageSize.next(size);
  }

  public clearSearches(): void {
    this.clearSearches$.next();
  }

  public clearAllFilters(): void {
    this._alertsTableFilterRequest.next(new AlertsTableFilterRequest());
    this._activeSearchCriteria.next(0);
    this._activeFilterCount.next(0);
    this._selectedDates.next([]);
    this._selectedStartDate.next('');
    this._selectedEndDate.next('');
    this.clearDates$.next();
  }

  public resetFilterForm(): void {
    this.alertsTableFilterRequest$.once(req => {
      const copy = Object.assign(new AlertsTableFilterRequest(), req);
      copy.clearFilters();
      this._alertsTableFilterRequest.next(copy as AlertsTableFilterRequest);
      this._activeFilterCount.next(0);
      this._selectedDates.next([]);
      this._selectedStartDate.next('');
      this._selectedEndDate.next('');
      this.clearDates$.next();
    });
  }

  public resetFilterField(fieldKeys: string[]): void {
    this.alertsTableFilterRequest$.once(req => {
      const reqCopy = Object.assign(new AlertsTableFilterRequest(), req);
      fieldKeys.forEach(k => {
        if (k.includes('Date' || 'search')) {
          reqCopy[k] = '';
          this._selectedDates.next([]);
          this._selectedEndDate.next('');
          this._selectedStartDate.next('');
        } else if (k.includes('alertType')) {
          reqCopy[k] = '';
        } else {
          reqCopy[k] = [];
        }
      });
      this._alertsTableFilterRequest.next(reqCopy as AlertsTableFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  private fetchAlerts(
    filterRequest: AlertsTableFilterRequest,
    orderbyRequest: AlertsTableOrderbyRequest,
    currPage: number,
    pageSize: number
  ) {
    const lm = $localize`Loading Alerts`;
    this._loadingOpts.addRequest(lm);
    this._loadingAlerts.next(true);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterRequest.getFilterString();
    const orderbyString = orderbyRequest.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    this.alertsDomainModel.getAlerts(oDataParams).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this._loadingAlerts.next(false);
      },
      error: (error: CustomError) => {
        this._loadingOpts.removeRequest(lm);
        this._loadingAlerts.next(false);
        this.toastService.publishError(error);
      }
    });
  }
}
