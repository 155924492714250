import { Deserializable } from '../protocols/deserializable';

export enum RemittanceSourceIdEnum {
  Employer = 1,
  Internal = 2,
  Scheduled
}

export class RemittanceSource implements Deserializable {
  public id!: RemittanceSourceIdEnum;
  public name!: string;

  public onDeserialize() {}
}
