<div class="auth-action-container">
  <app-loading [hidden]="!(viewModel.isLoading$ | async)" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <div class="auth-flow-wrapper">
    <div class="auth-flow-container">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="software-version">
    {{ viewModel.versionString }}
  </div>
</div>
<div class="auth-brand-container">
  <div class="brand-wrapper">
    <div class="img-container">
      <img [src]="splashImage$ | async" alt="fallback-image" />
    </div>
  </div>
</div>
