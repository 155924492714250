<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="confirmation-modal-title">
        {{ confirmationOptions?.title }}
      </div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <!-- Keep this one line because of white space attribute set to pre-wrap -->
    <div ngbAutoFocus class="confirmation-text">{{ confirmationOptions?.bodyText }}</div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button
      *ngIf="confirmationOptions?.showCancel"
      (click)="cancel()"
      [ngClass]="confirmationOptions?.cancelButtonClass">
      {{ confirmationOptions?.cancelText }}
    </button>
    <button
      *ngIf="confirmationOptions?.showContinue"
      (click)="continue()"
      [ngClass]="confirmationOptions?.continueButtonClass">
      {{ confirmationOptions?.continueText }}
    </button>
  </div>
</div>
