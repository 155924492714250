import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { CreateAlertRequest } from '../models/alerts/requests/create-alert-request';
import { Observable, throwError } from 'rxjs';
import { Alert } from '../models/alerts/dto/alert';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlertsAPI implements LoggableAPI {
  constructor() {}

  private apiClient = inject(ApiClient);

  public serviceName = 'Alerts';

  public createAlert(req: CreateAlertRequest): Observable<Alert> {
    const url = Endpoints.createAlert();
    return this.apiClient.postObj<Alert, CreateAlertRequest>(Alert, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateAlert(alert: Alert): Observable<Alert> {
    const url = Endpoints.updateAlert(alert.id.toString(10));
    return this.apiClient.putObj(Alert, url, alert).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteAlert(alertId: string): Observable<HttpResponse<any>> {
    const url = Endpoints.updateAlert(alertId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getAlertById(alertId: string): Observable<Alert> {
    const url = Endpoints.getAlertById(alertId);
    const odataQueryOptions = new ODataQueryOptions();
    odataQueryOptions.setExpand('Type,Colour,PortalType');
    return this.apiClient.getOdataObj<Alert>(url, Alert, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getAlerts(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Alert>> {
    const url = Endpoints.getAlerts();
    odataQueryOptions.setExpand('Colour,Type,PortalType');
    return this.apiClient.getOdata<Alert>(url, Alert, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
