<label
  (click)="!disabled && clicked.emit(!checked)"
  class="spp-checkbox-container"
  [ngClass]="{ checked: checked, disabled: disabled }">
  <div class="spp-checkbox" id="fake-box"></div>
  <span class="spp-checkbox-label" [ngClass]="{ checked: checked }">
    {{ label }}
  </span>
  <span class="spp-checkmark" [ngClass]="{ checked: checked }"></span>
</label>
