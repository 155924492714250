<app-header-container [includeBanner]="includeBanner" [isSystemBanner]="isSystemBanner">
  <div
    header-content
    class="d-flex flex-column gap-3"
    [style.background-color]="'white'"
    *ngIf="!isLoading; else loading">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="!isMobile"></app-breadcrumbs>
    <div class="d-flex justify-content-between">
      <div class="header-text">{{ headerTitle }}</div>
    </div>
  </div>
  <div *ngIf="includeBanner" banner-content>
    <ng-content></ng-content>
  </div>
</app-header-container>

<ng-template #loading>
  <div class="d-flex flex-column gap-3">
    <lib-loading-shimmer [height]="'1rem'"></lib-loading-shimmer>
    <lib-loading-shimmer [height]="'2rem'"></lib-loading-shimmer>
  </div>
</ng-template>
