import { Deserializable } from '../protocols/deserializable';

export enum RemittanceFrequencyEnum {
  Once = 1,
  Weekly = 2,
  BiWeekly = 3,
  TwiceMonthly = 4,
  Monthly = 5,
  EveryMonthsEnd = 6
}

export class RemittanceFrequency implements Deserializable {
  public id!: number;
  public name!: string;
  public description!: string;

  onDeserialize(): void {}
}
