import { Deserializable } from '../protocols/deserializable';
import { Searchable } from '../protocols/searchable';
import { Mentionable } from '../base/mentionable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';

export class RemittanceOwner extends Mentionable implements Deserializable, Searchable, LookAheadItem {
  // Searchable
  public title!: string;
  public value: any;

  onDeserialize() {
    super.onDeserialize();
    this.title = this.fullName;
    this.value = this.id;
  }

  lookAheadDisabled(): boolean {
    return false;
  }
  lookAheadDisabledMessage(): string {
    return '';
  }
}
