import { Component, Input } from '@angular/core';
import { BaseAlertComponent } from '../base-alert.component';

@Component({
  selector: 'app-simple-banner-alert',
  templateUrl: './simple-banner-alert.component.html',
  styleUrls: ['./simple-banner-alert.component.scss']
})
export class SimpleBannerAlertComponent extends BaseAlertComponent {
  @Input() iconSrc: string = '';

  setupBindings(): void {}

  setupViews(): void {}
}
