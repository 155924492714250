<!-- TODO: Update this form when design is finalized -->
<app-base-action-modal
  [title]="'Personal Information' | localization"
  [primaryLabel]="'Continue' | localization"
  [hideSecondary]="true"
  [ignoreEscape]="true"
  [hideClose]="true"
  (primaryClicked)="form.submitForm(); coopForm?.submitForm()">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <lib-reactive-form-group
    #form
    (formSubmitted$)="formSubmitted($event)"
    [bindTo]="req$ | async"
    [applyDisabledStylesToAllElements]="true"
    [includeIconWithError]="true">
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'firstName'"
        [label]="'Given Name(s)' | localization"
        [bindingProperty]="'firstName'"
        [required]="true">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'lastName'"
        [label]="'Last Name' | localization"
        [bindingProperty]="'lastName'"
        [required]="true">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'address1'"
        [required]="true"
        [label]="'Street or P.O. Box Number' | localization"
        [bindingProperty]="'address.addressLine1'">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'city'"
        [label]="'City or Town' | localization"
        [required]="true"
        [bindingProperty]="'address.city'">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-drop-down
        [inputName]="'country'"
        [label]="'Country' | localization"
        [inlineLabel]="false"
        [required]="true"
        [dropdowns]="(viewModel.countryDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.countryId'"
        [programmaticallyChangeValue]="viewModel.selectedCountryId$ | async"
        (valueChanged)="viewModel.countryChanged($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        [inputName]="'province'"
        [label]="'Province / State' | localization"
        [inlineLabel]="false"
        [placeholder]="'Select Province' | localization"
        [required]="true"
        [dropdowns]="(viewModel.stateDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.stateId'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'postal'"
        [required]="true"
        [label]="'Postal Code' | localization"
        [bindingProperty]="'address.postalCode'">
      </lib-reactive-form-text>
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'mobilePhoneNumber'"
        [countryCodeBindingProperty]="'mobilePhoneNumberCountryCode'"
        [hintText]="'(000) 000-0000'"
        [label]="'Phone' | localization"
        [countryCodes]="(countryCodes$ | async) ?? []"
        [required]="true"
        [inputName]="'mobilePhoneNumber'"></lib-reactive-form-phone-country-code>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</app-base-action-modal>
