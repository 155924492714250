import { Deserializable } from '../protocols/deserializable';
import { PortalTypeIdEnum } from '../enum/shared/portal-type-id.enum';
import { OriginPortal } from './remittance-history';

export class BaseRemittanceHistory implements Deserializable {
  public id!: string;
  public originPortalId!: PortalTypeIdEnum;
  public originPortal!: OriginPortal;
  public event!: string;
  public eventDate!: Date;

  onDeserialize(): void {
    if (!!this.eventDate) {
      this.eventDate = new Date(this.eventDate);
    }
    if (this.originPortal) {
      window?.injector?.Deserialize?.instanceOf(OriginPortal, this.originPortal);
    }
  }
}
