<lib-reactive-form-merge-groups
  #mergedFormGroups
  [mergeKey]="'editUser'"
  (formsSubmitted$)="viewModel.updateUser($event)">
</lib-reactive-form-merge-groups>

<div class="card-container-title view-user-account-header-padding">
  <app-back-button [paddingBottomRem]="0.5" (backClicked)="goBack()"></app-back-button>
  <span class="header">{{ viewModel.headerText$ | async }}</span>
</div>
<div class="card-container view-user-account-card-container-margin">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <div class="card-form-content-container">
    <div class="card-form-container">
      <!-- Information -->
      <app-base-card [height]="'100%'" class="info-card">
        <lib-reactive-form-group
          [permissionMap]="(viewModel.permissionMap$ | async) ?? undefined"
          [bindTo]="viewModel.user$ | async"
          [mergeKey]="'editUser'"
          [applyDisabledStylesToAllElements]="true"
          [includeIconWithError]="true">
          <span [style.padding-bottom.rem]="2" class="card-header">{{ 'Information' | localization }}</span>
          <lib-reactive-form-column-layout nColumns="2">
            <lib-reactive-form-text
              [inputName]="'firstName'"
              [permissionFieldId]="viewModel.fieldIdPrefix + 'FirstName'"
              [required]="true"
              [label]="'First Name' | localization"
              [bindingProperty]="'firstName'">
            </lib-reactive-form-text>
            <lib-reactive-form-text
              [inputName]="'lastName'"
              [permissionFieldId]="viewModel.fieldIdPrefix + 'LastName'"
              [required]="true"
              [label]="'Last Name' | localization"
              [bindingProperty]="'lastName'">
            </lib-reactive-form-text>
            <lib-reactive-form-text
              [inputName]="'email'"
              [permissionFieldId]="viewModel.fieldIdPrefix + 'Email'"
              [required]="true"
              [disabled]="true"
              [label]="'Email' | localization"
              [bindingProperty]="'email'">
            </lib-reactive-form-text>
            <lib-reactive-form-drop-down
              [inputName]="'status'"
              [permissionFieldId]="viewModel.fieldIdPrefix + 'Status'"
              [label]="'Status' | localization"
              [inlineLabel]="false"
              [required]="true"
              [dropdowns]="(viewModel.statusDropdowns$ | async) ?? undefined"
              [bindingProperty]="'active'">
            </lib-reactive-form-drop-down>
          </lib-reactive-form-column-layout>
          <hr class="pb-2" />
        </lib-reactive-form-group>
        <app-add-remove-role-form
          *ngIf="viewModel.canViewUserRoles$ | async"
          [permissionMap]="(viewModel.permissionMap$ | async) ?? undefined"
          [mergeKey]="'editUser'"
          [permissionFieldId]="viewModel.fieldIdPrefix + 'Role'"
          [user]="(viewModel.user$ | async) ?? undefined"
          [addRoleMap]="(viewModel.userRoleMap$ | async) ?? undefined">
        </app-add-remove-role-form>
        <div *ngIf="viewModel.userHasNotLoggedIn$ | async; else timestamp" class="flex-container flex-align-center">
          <div class="account-timestamp">{{ "User hasn't logged in yet." | localization }}</div>
          <div class="primary-paddingless-text-button" (click)="viewModel.resendInvite(true)">
            {{ 'Resend Invite?' | localization }}
          </div>
        </div>
        <ng-template #timestamp>
          <div class="account-timestamp">{{ viewModel.lastLoginTime$ | async }}</div>
          <!--            TODO: Add "Set to Inactive By" when added to backend -->
        </ng-template>
      </app-base-card>
    </div>
    <div *ngIf="viewModel.showActionContainer$ | async" class="card-form-action-container">
      <app-base-card [height]="'100%'" [padding]="(viewModel.screenService.isMobile$ | async) ? '2rem 1.5rem' : '2rem'">
        <div class="action-title">{{ 'Actions' | localization }}</div>
        <div class="action-items-container">
          <div class="action-item" *ngIf="viewModel.canChangeUserEmail$ | async">
            <img class="action-icon" [src]="'assets/icons/primary/Mail.svg'" alt="" />
            <span class="card-header" (click)="viewModel.openChangeEmailModal()">{{
              'Change Email' | localization
            }}</span>
          </div>
          <div class="action-item" *ngIf="viewModel.canResendUserInvite$ | async">
            <img class="action-icon" [src]="'assets/icons/primary/Lock On.svg'" alt="" />
            <span class="card-header" (click)="viewModel.resendInvite(false)">{{
              'Reset Password' | localization
            }}</span>
          </div>
          <div
            *ngIf="(viewModel.userHasNotLoggedIn$ | async) && (viewModel.canResendUserInvite$ | async)"
            class="action-item">
            <img class="action-icon" [src]="'assets/icons/primary/RefreshLeft.svg'" alt="" />
            <span class="card-header" (click)="viewModel.resendInvite(true)">{{ 'Resend Invite' | localization }}</span>
          </div>
        </div>
      </app-base-card>
    </div>
  </div>
  <ng-content></ng-content>
  <div *ngIf="viewModel.canEditUser$ | async" class="card-container-button-row">
    <lib-button-primary (buttonClicked)="mergedFormGroups.submitForms()"
      >{{ 'Save Changes' | localization }}
    </lib-button-primary>
  </div>
</div>
