import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { OdataFilterGenerator } from '../../../../interfaces/odata-filter-generator';
import { BehaviorSubject, Observable } from 'rxjs';
import { InternalUserTableFilterRequest } from '../../../../models/shared/odata-filter-requests/internal-user-table-filter-request';
import { Selectable } from '../../../../models/protocols/selectable';

@Component({
  selector: 'app-table-column-drop-down-bar',
  templateUrl: './table-column-drop-down-bar.component.html',
  styleUrls: ['./table-column-drop-down-bar.component.scss']
})
export class TableColumnDropDownBarComponent extends BaseComponent implements OnChanges {
  constructor() {
    super();
  }

  @Input() searchForm!: OdataFilterGenerator;
  @Input() clearable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() useClearableButton: boolean = true;
  @Input() bindingProperty = '';
  @Input() placeholder = '';
  @Input() dropdowns: Selectable[] = [];
  @Output() searchValue = new EventEmitter<OdataFilterGenerator>();

  private _searchForm = new BehaviorSubject<OdataFilterGenerator>(new InternalUserTableFilterRequest());
  public readonly searchForm$ = this._searchForm as Observable<OdataFilterGenerator>;

  private _bindingProperty = new BehaviorSubject<string>('');
  public readonly bindingProperty$ = this._bindingProperty as Observable<string>;

  private _placeholder = new BehaviorSubject<string>($localize`Search`);
  public readonly placeholder$ = this._placeholder as Observable<string>;

  private _dropdowns = new BehaviorSubject<Selectable[]>([]);
  public readonly dropdowns$ = this._dropdowns as Observable<Selectable[]>;

  setupBindings(): void {}

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchForm) {
      this._searchForm.next(this.searchForm);
    }

    if (changes.bindingProperty) this._bindingProperty.next(this.bindingProperty);
    if (changes.placeholder) this._placeholder.next(this.placeholder);
    if (changes.dropdowns) this._dropdowns.next(this.dropdowns);
  }

  public formSubmitted(form: OdataFilterGenerator): void {
    this.searchValue.emit(form);
  }
}
