import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

declare global {
  interface String {
    spaceOutCamelString(): string;

    stripWhiteSpaceAndLowerCase(): string;

    ellipsis(maxLength: number): string;

    pluralizer(): Pluralizer;
  }
}

String.prototype.spaceOutCamelString = function () {
  return this.replace(/([A-Z])/g, ' $1').trim();
};

String.prototype.stripWhiteSpaceAndLowerCase = function () {
  return this.trim().replace(/\s/g, '').toLowerCase();
};

String.prototype.ellipsis = function (maxLength: number): string {
  return this.length > maxLength ? this.substring(0, maxLength).toString() + '…' : this.toString();
};

type PluralizerRule = { isPlural?: boolean; listConnection: any[]; word: string; useApostrophe: boolean };
type Pluralizer = { addRule: (rule: PluralizerRule) => Pluralizer; pluralize: () => string };
String.prototype.pluralizer = function (): Pluralizer {
  const rules: PluralizerRule[] = [];
  let pluralized: string = this.toString(); // Convert the String object to a primitive string type.
  const addRule = (rule: PluralizerRule) => {
    rules.push(rule);
    return { addRule, pluralize };
  };
  const pluralize = (): string => {
    for (const rule of rules) {
      if (rule?.isPlural || rule?.listConnection?.length !== 1) {
        // prettier-ignore
        pluralized = pluralized.replace(rule.word, rule.word + (rule.useApostrophe ? '\'' : '') + 's');
      }
    }
    return pluralized;
  };
  return { addRule, pluralize };
};

export class StringExtensions {
  static lowercaseFirstCharacter(s: string): string {
    return s.length > 1 ? s.charAt(0).toLowerCase() + s.slice(1) : s;
  }

  static uppercaseFirstCharacter(s: string): string {
    return s.length > 1 ? s.charAt(0).toUpperCase() + s.slice(1) : s;
  }

  static sentenceCase(s: string): string {
    return StringExtensions.uppercaseFirstCharacter(s?.toLowerCase());
  }

  static normalizeCharacters(s: string): string {
    // First replace all non-ASCII characters, then replace all duplicate spaces with a single space
    return s
      .normalize('NFD')
      .replace(/[^a-zA-Z0-9\-_().\s]/g, '')
      .replace(/\s\s+/g, ' ');
  }

  static camelize(str: string) {
    return str
      .replace(/^\w|[A-Z]|\b\w/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  static splitPascalCaseIntoSentence(s: string) {
    return s?.split(/(?=[A-Z])/)?.join(' ');
  }

  static getStringMode(items: string[]): string {
    const modeCounts: { [key: string]: number } = {}; // Specify the type of the modeCounts object.
    items?.forEach(item => (modeCounts[item] = (modeCounts[item] || 0) + 1));
    const sortedModeCounts = Object.keys(modeCounts)?.sort((a, b) => modeCounts[b] - modeCounts[a]);
    return sortedModeCounts?.firstOrNull();
  }

  static ellipse(s: string, maxLength: number) {
    return s?.length > maxLength ? s.substring(0, maxLength) + '…' : s;
  }

  // Converts from "DD-MM-YYYY" to "YYYY-MM-DD"
  static convertDateFormatForBackend(dateString: string): string {
    if (!!dateString && dateString.length === 10) {
      const dateStringWithDashes = dateString.replace(/\//g, '-');
      const parts = dateStringWithDashes.split('-');
      const year = parts[2];
      const month = parts[1];
      const day = parts[0];
      return `${year}-${month}-${day}`;
    } else {
      return '';
    }
  }

  // Converts from "YYYY-MM-DD" to an NgbDate object
  static convertDateStringToNgbDate(dateString: string): NgbDate {
    if (!!dateString && dateString.length === 10) {
      const dateStringWithDashes = dateString.replace(/\//g, '-');
      const parts = dateStringWithDashes.split('-');
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return new NgbDate(+year, +month, +day);
    } else {
      return new NgbDate(0, 0, 0);
    }
  }

  static formatDayOrMonth(d: number): string {
    return d < 10 ? `0${d}` : `${d}`;
  }

  // Converts date string to YYYY-MM-DDT00:00:00.000Z
  static convertDateToIsoString(dateString: string): string {
    return !!dateString ? new Date(dateString).toISOString() : '';
  }

  // Converts from 000-000-000 to 000000000
  static removeDashesFromSin(sin: string): string {
    const regex = /\d+/g;
    const matches = sin.match(regex);

    if (matches && matches.length > 0) {
      return matches.join('');
    } else {
      return '';
    }
  }

  static stripSpaces(s: string): string {
    return s?.replace(/\s/g, '');
  }
}
