<div [style.position]="'relative'" ngbAccordion>
  <div ngbAccordionItem="{{ panelName }}" [collapsed]="!(expanded$ | async)">
    <app-loading [hidden]="!(isLoading$ | async)" [options]="(loadingOpts$ | async) ?? undefined"></app-loading>
    <app-base-card [ngClass]="{ 'disable-card': disableAccordion$ | async }">
      <div class="d-flex flex-row justify-content-between" [ngClass]="{ 'mb-4': !submissionPending }">
        <span class="card-title" i18n>{{ (req$ | async)?.employer }}</span>
        <app-edit-button
          *ngIf="showEditButton"
          (clicked)="toggleEditMode()"
          [isExpanded]="expanded$ | async"
          [disabled]="disableAccordion$ | async"></app-edit-button>
      </div>
      <lib-reactive-form-group
        #form
        class="detail-inputs"
        [bindTo]="req$ | async"
        [isLoading]="isLoading$ | async"
        [applyDisabledStylesToAllElements]="true"
        [includeIconWithError]="true"
        (formSubmitted$)="formSubmitted($event)">
        <div class="form-container">
          <div class="item-width">
            <lib-reactive-form-text
              [inputName]="'employerName'"
              label="Employer Name"
              i18n-label
              [bindingProperty]="'employer'"
              [disabled]="true">
            </lib-reactive-form-text>
          </div>
          <div class="item-width">
            <lib-reactive-form-text
              [inputName]="'employerId'"
              label="Employer ID"
              i18n-label
              [bindingProperty]="'employerId'"
              [disabled]="true">
            </lib-reactive-form-text>
          </div>
        </div>
        <lib-reactive-form-column-layout [nColumns]="3" [rowGap]="'1.5rem'">
          <lib-reactive-form-masked-input
            class="date"
            [required]="true"
            [disabled]="!(expanded$ | async)"
            [mask]="dateMask"
            label="Employment Start"
            hintText="DD / MM / YYYY"
            inputName="employmentStart"
            bindingProperty="formattedStartDate"
            placeholder=""
            i18n-label></lib-reactive-form-masked-input>
          <lib-reactive-form-masked-input
            class="date"
            [disabled]="!(expanded$ | async)"
            [mask]="dateMask"
            label="Employment End"
            hintText="Optional - DD / MM / YYYY"
            inputName="employmentEnd"
            bindingProperty="formattedEndDate"
            placeholder=""
            i18n-label></lib-reactive-form-masked-input>
          <lib-reactive-form-drop-down
            [inputName]="'employmentStatus'"
            [disabled]="!(expanded$ | async)"
            label="Employment Status"
            i18n-label
            [dropdowns]="(employmentStatusDropdowns$ | async) ?? undefined"
            [required]="true"
            [bindingProperty]="'employmentStatusId'"
            (valueChanged)="setEmploymentStatus($event)">
          </lib-reactive-form-drop-down>
          <lib-reactive-form-drop-down
            [hidden]="!(showLeaveDetails$ | async)"
            [inputName]="'leaveType'"
            [disabled]="!(expanded$ | async)"
            label="Leave Type"
            i18n-label
            [dropdowns]="(leaveTypeDropdowns$ | async) ?? undefined"
            [required]="showLeaveDetails$ | async"
            [bindingProperty]="'leaveDetails.leaveTypeId'">
          </lib-reactive-form-drop-down>
          <lib-reactive-form-masked-input
            class="date"
            [hidden]="!(showLeaveDetails$ | async)"
            [required]="showLeaveDetails$ | async"
            [disabled]="!(expanded$ | async)"
            [mask]="dateMask"
            label="Leave Start"
            hintText="DD / MM / YYYY"
            inputName="leaveStart"
            bindingProperty="leaveDetails.formattedStartDate"
            placeholder=""
            i18n-label></lib-reactive-form-masked-input>
          <lib-reactive-form-masked-input
            class="date"
            [hidden]="!(showLeaveDetails$ | async)"
            [required]="showLeaveDetails$ | async"
            [disabled]="!(expanded$ | async)"
            [mask]="dateMask"
            label="Expected Return"
            hintText="DD / MM / YYYY"
            inputName="leaveEnd"
            bindingProperty="leaveDetails.formattedExpectedReturnDate"
            placeholder=""
            i18n-label></lib-reactive-form-masked-input>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
      <div ngbAccordionCollapse>
        <div class="button-container" ngbAccordionBody>
          <div class="d-flex justify-flex-end">
            <lib-button-primary i18n (buttonClicked)="form.submitForm()">Save Changes</lib-button-primary>
          </div>
        </div>
      </div>
    </app-base-card>
  </div>
</div>
