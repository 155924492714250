import { StatusPill } from './status-pill';

import {
  RemittancePaymentStatusBorderColorMap,
  RemittancePaymentStatusPrimaryColorMap,
  RemittancePaymentStatusSecondaryColorMap
} from '../enum/shared/remittance-payment-status-colors.enum';

export class RemittancePaymentStatusPill extends StatusPill {
  constructor(id: number, name: string) {
    super(id, name);
    this.primaryColor = RemittancePaymentStatusPrimaryColorMap[this.id];
    this.secondaryColor = RemittancePaymentStatusSecondaryColorMap[this.id];
    this.borderColor = RemittancePaymentStatusBorderColorMap[this.id];
    this.textColour = this.primaryColor;
  }
}
