import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { ApiClient } from './api-client';
import { ReleaseNoteDocument } from '../models/release-notes/release-note-document';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ReleaseNoteDocumentVersion } from '../models/release-notes/release-note-document-version';
import { CreateReleaseNoteDocumentRequest } from '../models/release-notes/requests/create-release-note-document-request';
import { UpdateReleaseNoteDocumentRequest } from '../models/release-notes/requests/update-release-note-document-request';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  public serviceName = 'ReleaseNotesAPI';

  public getReleaseNoteDocuments(oDataParams: ODataQueryOptions): Observable<ODataResponse<ReleaseNoteDocument>> {
    const url = Endpoints.getReleaseNoteDocuments();
    return this.apiClient.getOdata(url, ReleaseNoteDocument, oDataParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getReleaseNoteDocumentVersions(): Observable<ODataResponse<ReleaseNoteDocumentVersion>> {
    const url = Endpoints.getReleaseNoteDocumentVersions();
    return this.apiClient.getOdata(url, ReleaseNoteDocumentVersion).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getLatestReleaseNoteDocument(): Observable<ReleaseNoteDocument> {
    const url = Endpoints.getLatestReleaseNoteDocument();
    const oDataParams = new ODataQueryOptions();
    oDataParams.setExpand('PortalType');
    oDataParams.setExpand('ReleaseNotes($expand=ReleaseNoteChangeType)');
    return this.apiClient.getOdataObj(url, ReleaseNoteDocument, oDataParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getReleaseNoteDocumentById(id: string): Observable<ReleaseNoteDocument> {
    const url = Endpoints.getReleaseNoteDocumentById(id);
    const oDataParams = new ODataQueryOptions();
    oDataParams.setExpand('PortalType');
    oDataParams.setExpand('ReleaseNotes($expand=ReleaseNoteChangeType)');
    return this.apiClient.getOdataObj(url, ReleaseNoteDocument, oDataParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getUniqueVersions(odataParams: ODataQueryOptions): Observable<ODataResponse<ReleaseNoteDocumentVersion>> {
    const url = Endpoints.getUniqueReleaseVersions();
    return this.apiClient.getOdata<ReleaseNoteDocumentVersion>(url, ReleaseNoteDocumentVersion, odataParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteReleaseNoteDocument(id: string): Observable<any> {
    const url = Endpoints.releaseNoteDocumentById(id);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createReleaseNoteDocument(req: CreateReleaseNoteDocumentRequest): Observable<ReleaseNoteDocument> {
    const url = Endpoints.createReleaseNoteDocument();
    return this.apiClient.postObj(ReleaseNoteDocument, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateReleaseNoteDocument(req: UpdateReleaseNoteDocumentRequest): Observable<ReleaseNoteDocument> {
    const url = Endpoints.releaseNoteDocumentById(req.id);
    return this.apiClient.putObj(ReleaseNoteDocument, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
