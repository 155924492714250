<app-sliding-window [toggleWindow]="showCommentWindow$ | async" [contentPaddingRem]="0">
  <div header>
    <app-sliding-window-header
      [slidingWindowOptions]="commentWindowOptions$ | async"
      (closeButtonClicked)="toggleCommentWindow()">
    </app-sliding-window-header>
    <div class="header-content" *ngIf="headerText">
      {{ headerText }}
    </div>
  </div>
  <div content>
    <app-comments-form
      #form
      [headerHeight]="!!headerText ? '4.5rem' : '0rem'"
      [comments]="comments"
      [isLoading]="isLoading"
      [dropdownItems]="dropdownItems"
      (filterText)="filterText.emit($event)"
      (commentValueChanged)="onCommentValueChange($event)"
      (newComment)="newComment.emit($event)">
    </app-comments-form>
  </div>
  <app-sliding-window-footer
    footer
    [hideActionButton]="hideActionButton$ | async"
    [slidingWindowOptions]="commentWindowOptions$ | async"
    (primaryButtonClicked)="form.submitForm()"
    (secondaryButtonClicked)="toggleCommentWindow()">
  </app-sliding-window-footer>
</app-sliding-window>
