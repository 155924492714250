<app-loading *ngIf="isLoading$ | async" [options]="(loadingOpts$ | async) ?? undefined"></app-loading>

<div class="app-body mb-0" [ngClass]="{ 'app-body-blur': blurPage$ | async }">
  <!-- Nav Bar -->
  <app-navbar
    [hidden]="navbarMinimized"
    [class.spp-navbar-hide]="navbarMinimized"
    [class.spp-navbar-show]="!navbarMinimized">
  </app-navbar>
  <!-- Main content -->
  <main class="main" [style.overflow]="(disableScroll$ | async) ? 'hidden' : 'auto'">
    <div class="main-content">
      <router-outlet></router-outlet>
      <app-banner-events></app-banner-events>
    </div>
  </main>
</div>
