import { LoggableAPI } from '../models/protocols/loggable-api';
import { inject, Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { Observable, throwError } from 'rxjs';
import { ODataResponse } from '../models/protocols/odata-response';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { EmployerUser } from '../models/account/dto/employer-user';
import { CreateEmployerUserRequest } from '../models/account/requests/create-employer-user-request';
import { Deserializable } from '../models/protocols/deserializable';
import { Role } from '../models/roles/role';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { HttpResponse } from '@angular/common/http';
import { MemberUser } from '../models/account/dto/member-user';
import { CreateMemberRequest } from '../models/account/requests/create-member-request';
import { EmployerSearchResult } from '../models/shared/employer-search-result';
import { Employer } from '../models/shared/employer';

@Injectable({
  providedIn: 'root'
})
export class EmployersAPI implements LoggableAPI {
  constructor() {}

  private apiClient = inject(ApiClient);

  public serviceName = 'Employers';

  public getUsersForEmployers(
    oDataQueryOptions: ODataQueryOptions,
    employerId: string
  ): Observable<ODataResponse<EmployerUser>> {
    const url = Endpoints.getUsersForEmployer(employerId);
    oDataQueryOptions.setExpand('Roles');
    return this.apiClient.getOdata<EmployerUser>(url, EmployerUser, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMembersForEmployer(
    oDataQueryOptions: ODataQueryOptions,
    employerId: string
  ): Observable<ODataResponse<MemberUser>> {
    const url = Endpoints.getMembersForEmployer(employerId);
    return this.apiClient.getOdata<MemberUser>(url, MemberUser, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createEmployerUser(req: CreateEmployerUserRequest, employerId: string): Observable<EmployerUser> {
    const url = Endpoints.createEmployerUser(employerId);
    return this.apiClient.postObj<EmployerUser, Deserializable>(EmployerUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createMemberUser(req: CreateMemberRequest, employerId: string): Observable<MemberUser> {
    const url = Endpoints.createEmployee(employerId);
    return this.apiClient.postObj<MemberUser, Deserializable>(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerRoles(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Role>> {
    const url = Endpoints.getEmployerRoles();
    const permissionHeaders = { permissions: [3, 9] };
    return this.apiClient.getOdata(url, Role, odataQueryOptions, permissionHeaders).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerUserById(employerId: string, userId: string): Observable<EmployerUser> {
    const url = Endpoints.getEmployerUserById(employerId, userId);
    const odataQueryOptions = new ODataQueryOptions();
    odataQueryOptions.setExpand('Roles');
    return this.apiClient.getOdataObj<EmployerUser>(url, EmployerUser, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerById(employerId: string): Observable<Employer> {
    const url = Endpoints.getEmployerById(employerId);
    const odataQueryOptions = new ODataQueryOptions();
    return this.apiClient.getOdataObj<Employer>(url, Employer, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateEmployerUser(u: EmployerUser): Observable<EmployerUser> {
    const url = Endpoints.updateEmployerUser(u);
    return this.apiClient.putObj(EmployerUser, url, u).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public forceChangeEmail(u: EmployerUser, req: ChangeEmailRequest): Observable<EmployerUser> {
    const url = Endpoints.employerForceChangeEmail(u);
    return this.apiClient.postObj<EmployerUser, Deserializable>(EmployerUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // This endpoint works to both force reset another Employer User's password, and resend the welcome email.
  public forceResetPassword(u: EmployerUser): Observable<HttpResponse<any>> {
    const url = Endpoints.employerForceChangePassword(u);
    return this.apiClient.simpleBodylessPost(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public searchEmployersByIdOrName(
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<EmployerSearchResult>> {
    const url = Endpoints.searchEmployersByIdOrName();
    return this.apiClient.getOdata<EmployerSearchResult>(url, EmployerSearchResult, odataQueryOptions, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
