import { BaseComponent } from '../../../../models/base/base-component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseButtonSizeEnum } from './base-button-size.enum';
import { BaseButtonColourEnum } from './base-button-colour.enum';

@Component({
  template: ''
})
export abstract class BaseButtonComponent extends BaseComponent {
  @Input() disabled: boolean = false;
  @Input() size: BaseButtonSizeEnum = BaseButtonSizeEnum.lg;
  @Input() colour: BaseButtonColourEnum = BaseButtonColourEnum.Blue;
  @Input() leftIconSrc: string = '';
  @Input() rightIconSrc: string = '';
  @Output() buttonClicked = new EventEmitter<void>();

  public loaded = false;

  setupBindings(): void {
    setTimeout(() => (this.loaded = true));
  }

  setupViews(): void {}
}
