<div
  class="base-card"
  [style.display]="display"
  [style.flex-direction]="flexDirection"
  [style.height]="height"
  [style.width]="width"
  [style.max-height]="maxHeight"
  [style.max-width]="maxWidth"
  [style.padding]="padding"
  [style.gap]="gap"
  [style.border-radius]="borderRadius"
  [style.box-shadow]="boxShadow"
  [style.background-color]="backgroundColor"
  [style.border]="useBorder ? '1px solid' : 'none'"
  [style.border-color]="borderColor">
  <ng-content></ng-content>
</div>
