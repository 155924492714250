import { Component, inject, Input } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../services/toast-service';
import { SearchableOptions } from '../../../../models/shared/searchable-options';
import { Searchable } from '../../../../models/protocols/searchable';
import { SearchableModalViewModel } from './searchable-modal-view-model';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Observable, throwError } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { CustomError } from '../../../../models/shared/custom-error';
import { BaseButtonSizeEnum } from '../../components/base-button/base-button-size.enum';

@Component({
  selector: 'app-searchable-modal',
  templateUrl: './searchable-modal.component.html',
  styleUrls: ['./searchable-modal.component.scss'],
  providers: [SearchableModalViewModel]
})
export class SearchableModalComponent extends BaseModalComponent {
  @Input() ignoreEscape: boolean = true;

  constructor(protected activeModal: NgbActiveModal, private toastService: ToastService) {
    super(activeModal);
  }

  public loadingOpts: LoadingOptions = LoadingOptions.defaultInModal();
  public searchableOptions!: SearchableOptions;
  public functionToExecute!: (val: any) => Observable<any>;
  private viewModel = inject(SearchableModalViewModel);

  public fuzzyHits$ = this.viewModel.fuzzyHits$ as Observable<Searchable[]>;

  setupBindings(): void {}

  setupViews(): void {}

  public connectToSearchableItems(items: Searchable[]): void {
    this.viewModel.setSearchableItems(items);
  }

  public connectToSearchedProperties(properties: string[]): void {
    this.viewModel.setSearchedProperties(properties);
  }

  public connectToSearchText(search: string): void {
    this.viewModel.setSearchText(search);
  }

  public connectToShowAllItemsWhenSearchIsEmpty(show: boolean): void {
    this.viewModel.setShowAllItemsWhenSearchIsEmpty(show);
  }

  public setSearchableOptions(opts: SearchableOptions): void {
    this.searchableOptions = opts;
  }

  public itemClicked(val: any): void {
    this.continue(val);
  }

  private continue(val: any): void {
    const lm = this.searchableOptions.loadingText;
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.functionToExecute(val)
        .pipe(
          take(1),
          finalize(() => this.loadingOpts.removeRequest(lm))
        )
        .subscribe({
          next: (success: any) => {
            if (success) {
              this.toastService.publishSuccessMessage(
                this.searchableOptions.successTitle,
                this.searchableOptions.successMess
              );
              this.activeModal.close(success);
            } else {
              this.toastService.publishErrorMessage(
                this.searchableOptions.failureMess,
                this.searchableOptions.failureTitle
              );
            }
          },
          error: (error: CustomError) => {
            this.toastService.publishError(error);
            return throwError(() => error);
          }
        });
    }
  }

  clearSearch() {
    this.connectToSearchText('');
  }

  protected readonly BaseButtonSizeEnum = BaseButtonSizeEnum;
}
