<div class="spp-navbar">
  <ul class="nav-list">
    <img (click)="viewModel.navigateToRoot()" [src]="'assets/logo/purple/spp-logo.svg'" alt="logo" class="nav-logo" />
    <li *ngFor="let navItem of viewModel.navItems$ | async">
      <app-nav-item *ngIf="navItem | isDefaultNavItem" [navItem]="navItem"></app-nav-item>
      <app-profile-nav-item *ngIf="navItem | isProfileNavItem" [navItem]="navItem"></app-profile-nav-item>
      <app-sign-out-nav-item *ngIf="navItem | isSignOutNavItem" [navItem]="navItem"></app-sign-out-nav-item>
      <app-drop-down-nav-item *ngIf="navItem | isDropDownNavItem" [navItem]="navItem"></app-drop-down-nav-item>
    </li>
  </ul>
</div>
