import { PortalTypeIdEnum } from '../enum/shared/portal-type-id.enum';
import { Deserializable } from '../protocols/deserializable';
import { BaseRemittanceHistory } from './base-remittance-history';

export class RemittanceHistory extends BaseRemittanceHistory {
  public remittanceId!: string;
}

export class OriginPortal implements Deserializable {
  public id!: PortalTypeIdEnum;
  public name!: string;

  onDeserialize(): void {}
}
