<div class="window-footer">
  <ng-container *ngIf="!(showCompletedButtons$ | async); else completed_container">
    <lib-button-neutral
      *ngIf="(slidingWindowOptions$ | async)?.secondaryButtonText"
      class="secondary-button"
      (buttonClicked)="secondaryButtonClicked.emit()">
      {{ (slidingWindowOptions$ | async)?.secondaryButtonText }}
    </lib-button-neutral>
    <lib-button-primary
      *ngIf="(slidingWindowOptions$ | async)?.primaryButtonText && !(hideActionButton$ | async)"
      (buttonClicked)="primaryButtonClicked.emit()">
      {{ (slidingWindowOptions$ | async)?.primaryButtonText }}
    </lib-button-primary>
  </ng-container>
</div>

<ng-template #completed_container>
  <div class="completed-container">
    <lib-button-primary class="fill-width" (buttonClicked)="secondaryButtonClicked.emit()">
      {{ (slidingWindowOptions$ | async)?.completedStateText }}
    </lib-button-primary>
  </div>
</ng-template>
