<div>
  <lib-reactive-form-group
    #searchForm
    [bindTo]="searchForm$ | async"
    [applyDisabledStylesToAllElements]="true"
    [includeIconWithError]="true"
    (formSubmitted$)="formSubmitted($event)">
    <lib-reactive-form-drop-down
      [inputName]="(bindingProperty$ | async) ?? ''"
      [placeholder]="(placeholder$ | async) ?? ''"
      [clearable]="clearable"
      [disabled]="disabled"
      [useClearableButton]="useClearableButton"
      [bindingProperty]="(bindingProperty$ | async) ?? ''"
      [dropdowns]="(dropdowns$ | async) ?? undefined"
      (valueChanged)="searchForm.submitForm()">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-group>
</div>
