import { StatusPill } from './status-pill';
import {
  ScheduledRemittanceStatusBorderColourMap,
  ScheduledRemittanceStatusPrimaryColorMap,
  ScheduledRemittanceStatusSecondaryColorMap
} from '../enum/shared/scheduled-remittance-status-colors.enum';

export class ScheduledRemittanceStatusPill extends StatusPill {
  constructor(id: number, name: string) {
    super(id, name);
    this.primaryColor = ScheduledRemittanceStatusPrimaryColorMap[this.id];
    this.secondaryColor = ScheduledRemittanceStatusSecondaryColorMap[this.id];
    this.borderColor = ScheduledRemittanceStatusBorderColourMap[this.id];
    this.textColour = this.primaryColor;
  }
}
