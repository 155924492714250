import { Deserializable } from '../protocols/deserializable';
import * as moment from 'moment';
import { DateUtils } from '../../utils/date-utils';

export abstract class BaseRemittanceNewMember implements Deserializable {
  public id!: string;
  public firstName!: string;
  public lastName!: string;
  public socialInsuranceNumber!: string;
  public birthDate!: Date;
  public newMemberDetails!: RemittanceNewMemberDetails;

  public onDeserialize(): void {
    if (!!this.newMemberDetails) {
      this.newMemberDetails = window?.injector?.Deserialize?.instanceOf(
        RemittanceNewMemberDetails,
        this.newMemberDetails
      );
    } else {
      this.newMemberDetails = new RemittanceNewMemberDetails();
    }
  }

  public prepareDatesForBackend(): void {
    if (!!this.newMemberDetails.formattedEmploymentStartDate) {
      this.newMemberDetails.employmentStartDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedEmploymentStartDate)
      );
    }
    if (!!this.newMemberDetails.formattedLeaveStartDate) {
      this.newMemberDetails.leaveStartDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedLeaveStartDate)
      );
    }
    if (!!this.newMemberDetails.formattedLeaveExpectedReturnDate) {
      this.newMemberDetails.leaveExpectedReturnDate = new Date(
        DateUtils.swapDayAndMonth(this.newMemberDetails.formattedLeaveExpectedReturnDate)
      );
    }
  }
}

export class RemittanceNewMemberDetails implements Deserializable {
  public id!: string;
  public contributionId!: string;
  public email!: string;
  public employmentStartDate!: Date;
  public onLeave: boolean = false;
  public leaveTypeId: number | null = null;
  public leaveStartDate: Date | null = null;
  public leaveExpectedReturnDate: Date | null = null;
  public leaveContinuedContributions: boolean | null = null;

  // Not from API
  public formattedEmploymentStartDate!: string;
  public formattedContributionStartDate!: string;
  public formattedLeaveStartDate!: string;
  public formattedLeaveExpectedReturnDate!: string;

  onDeserialize(): void {
    this.formattedEmploymentStartDate = this.employmentStartDate
      ? moment(this.employmentStartDate).format('DD/MM/YYYY').toString()
      : '';
    this.formattedLeaveStartDate = this.leaveStartDate
      ? moment(this.leaveStartDate).format('DD/MM/YYYY').toString()
      : '';
    this.formattedLeaveExpectedReturnDate = this.leaveExpectedReturnDate
      ? moment(this.leaveExpectedReturnDate).format('DD/MM/YYYY').toString()
      : '';
  }
}
