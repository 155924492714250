import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { Deserializable } from '../protocols/deserializable';

export class EmployerSearchResult implements Deserializable, LookAheadItem {
  id!: number;
  name!: string;

  onDeserialize() {}

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  lookAheadDisplayValue(): string {
    return this.name + ' ' + '(' + this.id + ')';
  }
}
