export class PillItem {
  public clickable: boolean;
  public selected: boolean;
  public disabled: boolean;
  public text: string;
  public imgSrc: string;
  public value: any;

  constructor(
    text: string = '',
    clickable: boolean = true,
    selected: boolean = false,
    disabled: boolean = false,
    imgSrc: string = '',
    value?: any
  ) {
    this.clickable = clickable;
    this.selected = selected;
    this.disabled = disabled;
    this.text = text;
    this.imgSrc = imgSrc;
    this.value = value;
  }
}
