import { inject, Injectable } from '@angular/core';
import { BaseService } from '@csspension/base-angular';
import { SubmissionsDomainModel } from '../../../../../../domainModels/submissions-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { ODataQueryOptions } from '../../../../../../models/shared/odata-query-options';
import { MemberSubmission } from '../../../../../../models/submissions/member-submission';

@Injectable({
  providedIn: 'root'
})
export class MemberSubmissionHistoryService extends BaseService {
  constructor() {
    super();
    this.getSubmissionHistory();
  }

  private submissionDomainModel = inject(SubmissionsDomainModel);
  private toastService = inject(ToastService);

  private _submissionHistoryLoadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default(false));
  public submissionHistoryLoadingOpts$ = defer(() => this._submissionHistoryLoadingOpts);

  public isLoading$ = this.submissionHistoryLoadingOpts$.pipe(
    map(it => it?.isLoading),
    startWith(false)
  );

  private _memberSubmissions = new BehaviorSubject<MemberSubmission[]>([]);
  public memberSubmissions$ = this._memberSubmissions as Observable<MemberSubmission[]>;

  private getSubmissionHistory(): void {
    const lm = $localize`Loading...`;
    const opts = new ODataQueryOptions();
    opts.setOrderBy('lastStatusUpdateDate desc');
    this.submissionDomainModel
      .getMemberSubmissions(opts)
      .pipe(
        tap(_ => this._submissionHistoryLoadingOpts.addRequest(lm)),
        finalize(() => this._submissionHistoryLoadingOpts.removeRequest(lm))
      )
      .subscribe({
        error: () => {
          this.toastService.publishErrorMessage($localize`Error`, $localize`Unable to get submissions for user.`);
        },
        next: res => {
          this._memberSubmissions.next(res.value);
        }
      });
  }

  public refetchSubmissionHistory(): void {
    this.getSubmissionHistory();
  }
}
