import { Deserializable } from '../protocols/deserializable';

export enum RemittanceTypeEnum {
  Immediate,
  Scheduled
}

export class RemittanceType implements Deserializable {
  public id!: number;
  public name!: string;

  public onDeserialize() {}
}
