<div class="auth-flow-subtext">
  <div class="header">{{ viewModel.authFlowTitle$ | async | localization }}</div>
  <div class="subtext">{{ viewModel.authFlowSubtext$ | async | localization }}</div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted(req)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    [label]="'Verification Code' | localization"
    [inlineLabel]="false"
    [placeholder]="''"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>

<lib-button-primary class="w-100" (buttonClicked)="form.submitForm()">
  {{ 'Verify Account' | localization }}
</lib-button-primary>
