import { Component } from '@angular/core';
import { NavItemViewModel } from '../nav-item-view-model';
import { NavItemComponent } from '../nav-item.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-drop-down-nav-item',
  templateUrl: './mobile-drop-down-nav-item.component.html',
  styleUrls: ['./mobile-drop-down-nav-item.component.scss', '../nav-item.component.scss'],
  providers: [NavItemViewModel]
})
export class MobileDropDownNavItemComponent extends NavItemComponent {
  constructor(public viewModel: NavItemViewModel, protected router: Router) {
    super(viewModel, router);
  }
}
