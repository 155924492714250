import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignInRequest } from '../../../../../models/account/requests/sign-in-request';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReactiveFormEmailComponent, ReactiveFormPasswordComponent } from '@csspension/reactive-form';
import { map } from 'rxjs/operators';
import { PortalService } from '../../../../../services/portal/portal.service';
import { SignInViewModel } from '../../../viewModels/sign-in-view-model';
import { PortalType } from '../../../../../models/enum/shared/portal-type';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss', '../auth.component.scss']
})
export class SignInComponent extends BaseComponent {
  viewModel = inject(SignInViewModel);
  portalService = inject(PortalService);
  route = inject(ActivatedRoute);

  @Output() loadingOpts = new EventEmitter<LoadingOptions>();

  constructor(private router: Router) {
    super();
  }

  @ViewChild(ReactiveFormEmailComponent) emailComponent!: ReactiveFormEmailComponent;
  @ViewChild(ReactiveFormPasswordComponent) passwordComponent!: ReactiveFormPasswordComponent;
  private deleteEmailAstrixMutationObserver!: MutationObserver;
  private deletePasswordAstrixMutationObserver!: MutationObserver;

  // View
  private _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this._loadingOpts.asObservable().pipe(map(opts => opts.isLoading));
  // Variables
  public readonly req$ = this.viewModel.preFillEmail$.pipe(map(email => new SignInRequest(email, '')));
  private deleteMutationConfig = { attributes: false, childList: true, subtree: true };
  public loginTitle$ = this.viewModel.loginTitle$;

  private deleteAstrixBlock = (div: HTMLDivElement): void => {
    const deleteMe = div?.getElementsByClassName('reactive-form-required-star');
    const divArray = Array.from(deleteMe) as HTMLDivElement[]; // convert to array
    for (const astrix of divArray) {
      astrix.remove();
    }
  };

  setupBindings() {
    this.bindUrlParams();
    this.deleteEmailAstrix();
    this.deletePasswordAstrix();
    this.viewModel.loadingOpts$.subscribeWhileAlive({
      owner: this,
      next: opts => this.loadingOpts.emit(opts)
    });
  }

  setupViews() {}

  bindUrlParams() {
    this.viewModel.getUrlParams(this.route);
  }

  // View Actions

  formSubmitted(req: SignInRequest) {
    this.portalService.portalType$.once(type => {
      switch (type) {
        case PortalType.Internal:
          this.viewModel.internalUserSignIn(req);
          break;
        case PortalType.Member:
          this.viewModel.memberSignIn(req);
          break;
        case PortalType.Employer:
          this.viewModel.employerSignIn(req);
          break;
      }
    });
  }

  secondaryButtonPressed() {
    const tempEmail = this.emailComponent?.getMyValue();
    this.router.navigate(['auth/forgot-password'], { queryParams: { email: tempEmail } }).then();
  }

  private deleteEmailAstrix(): void {
    const emailDiv = this.emailComponent.getNativeElement() as HTMLDivElement;
    if (!!emailDiv) {
      const callback = () => this.deleteAstrixBlock(emailDiv);
      this.deleteEmailAstrixMutationObserver = new MutationObserver(callback);
      this.deleteEmailAstrixMutationObserver.observe(emailDiv, this.deleteMutationConfig);
    }
  }

  private deletePasswordAstrix(): void {
    const passwordDiv = this.passwordComponent.getNativeElement() as HTMLDivElement;
    if (!!passwordDiv) {
      const callback = () => this.deleteAstrixBlock(passwordDiv);
      this.deletePasswordAstrixMutationObserver = new MutationObserver(callback);
      this.deletePasswordAstrixMutationObserver.observe(passwordDiv, this.deleteMutationConfig);
    }
  }

  destroy() {
    super.destroy();
    this.deleteEmailAstrixMutationObserver?.disconnect();
    this.deletePasswordAstrixMutationObserver?.disconnect();
  }
}
