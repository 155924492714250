import { Component, inject, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractSubmissionFormComponent } from '../submission-forms/abstract-submission-form/abstract-submission-form.component';
import { UpdateContactInfoSubmissionFormViewModel } from './update-contact-info-submission-form-view-model';
import { UpdateContactInfoRequest } from '../../../../models/account/requests/submissionRequests/update-contact-info-request';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'app-update-contact-info-submission-form',
  templateUrl: './update-contact-info-submission-form.component.html',
  styleUrls: ['./update-contact-info-submission-form.component.scss'],
  providers: [UpdateContactInfoSubmissionFormViewModel]
})
// prettier-ignore
/* eslint-disable brace-style */
export class UpdateContactInfoSubmissionFormComponent
  extends AbstractSubmissionFormComponent<UpdateContactInfoRequest>
  implements OnChanges
{
  /* eslint-enable brace-style */
  protected viewModel = inject(UpdateContactInfoSubmissionFormViewModel);

  public countryCodes$ = this.viewModel.countryCodes$;

  public req$ = this.viewModel.req$;

  private _submitAttempted = new BehaviorSubject<boolean>(false);
  public submitAttempted$ = this._submitAttempted as Observable<boolean>;

  public submitContactInfo(req: UpdateContactInfoRequest): void {
    this._submitAttempted.next(true);
    if (!req.mobilePhoneNumber && !req.homePhoneNumber && !req.workPhoneNumber) return;
    // Remove country codes from request if phone numbers are empty
    if (!req.workPhoneNumber) delete req.workPhoneNumberCountryCode;
    if (!req.homePhoneNumber) delete req.homePhoneNumberCountryCode;
    if (!req.mobilePhoneNumber) delete req.mobilePhoneNumberCountryCode;

    this.processFilesAndSubmit(req);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      this.viewModel.setUser(this.user);
    }
  }
}
