import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Viewable } from '@csspension/base-angular';
import { NavbarViewModel } from './navbar-view-model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent extends BaseComponent implements OnInit, Viewable {
  constructor(public viewModel: NavbarViewModel) {
    super();
  }

  setupViews() {}

  setupBindings() {}
}
