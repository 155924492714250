export enum NavItemId {
  Accounts,
  Home,
  Employers,
  Members,
  Profile,
  Remittance,
  ContactUs,
  Settings,
  Submissions,
  Dashboard,
  Reports,
  Records,
  Documents,
  Messaging,
  Work
}
