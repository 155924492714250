<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="confirmation-modal-title">
        {{ confirmationOptions?.title }}
      </div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div *ngIf="!confirmationOptions.bodyTextAsHtml" ngbAutoFocus class="body-text">
      {{ confirmationOptions?.bodyText }}
    </div>
    <div
      class="body-text"
      *ngIf="confirmationOptions.bodyTextAsHtml"
      ngbAutoFocus
      [innerHTML]="confirmationOptions?.bodyText | trustAsHtml"></div>
  </div>

  <div
    class="sticky-footer-container modal-footer-flex-end"
    [style.gap.rem]="confirmationOptions?.cancelButtonStyle === SecondaryButtonStyleEnum.Tertiary ? 1.25 : 1">
    <ng-container *ngIf="confirmationOptions?.showCancel">
      <app-secondary-button
        *ngIf="confirmationOptions?.cancelButtonStyle === SecondaryButtonStyleEnum.Secondary"
        [size]="BaseButtonSizeEnum.sm"
        [colour]="BaseButtonColourEnum.Blue"
        (buttonClicked)="cancel()">
        {{ confirmationOptions?.cancelText }}
      </app-secondary-button>
      <app-tertiary-button
        *ngIf="confirmationOptions?.cancelButtonStyle === SecondaryButtonStyleEnum.Tertiary"
        [size]="BaseButtonSizeEnum.sm"
        [colour]="BaseButtonColourEnum.Blue"
        (buttonClicked)="cancel()">
        {{ confirmationOptions?.cancelText }}
      </app-tertiary-button>
    </ng-container>

    <app-primary-button
      [size]="BaseButtonSizeEnum.sm"
      [colour]="BaseButtonColourEnum.Blue"
      *ngIf="confirmationOptions?.showContinue"
      (buttonClicked)="continue()">
      {{ confirmationOptions?.continueText }}
    </app-primary-button>
  </div>
</div>
