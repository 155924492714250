<div
  #container
  matRipple
  appThrottleClick
  class="look-ahead-item"
  [matRippleDisabled]="disabled$ | async"
  [ngClass]="{ selected: selected && !(disabled$ | async), disabled: (disabled$ | async) }"
  [ngbTooltip]="(disabled$ | async) ? (disabledMessage$ | async) : null"
  (throttleClick)="handleClick()">
  <app-user-profile-badge [initials]="initials$ | async" [textColor]="profileColour$ | async"></app-user-profile-badge>
  {{ lookAheadString$ | async }}
</div>
