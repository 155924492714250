import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { InternalUser } from '../models/account/dto/internal-user';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { InternalUsersAPI } from '../api/internal-users-api.service';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { ODataResponse } from '../models/protocols/odata-response';
import { HttpResponse } from '@angular/common/http';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { CreateInternalUserRequest } from '../models/account/requests/create-internal-user-request';

// Provided by Logged In Scope
@Injectable()
export class InternalUsersDomainModel extends BaseDomainModel {
  constructor(private internalUsersApi: InternalUsersAPI) {
    super();
  }

  private _allInternalUsers = new BehaviorSubject<InternalUser[] | null>(null);
  public allInternalUsers$ = this._allInternalUsers as Observable<InternalUser[]>;

  private _allInternalUsersTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public allInternalUsersTotalCount$ = this._allInternalUsersTotalCount as Observable<number | undefined>;

  private _mentionableInternalUsers = new BehaviorSubject<InternalUser[]>([]);
  public mentionableInternalUsers$ = this._mentionableInternalUsers.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getAllInternalUsers(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<InternalUser>> {
    return this.internalUsersApi.getAllInternalUsers(oDataQueryOptions).pipe(
      tap(odataRes => this._allInternalUsersTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._allInternalUsers.next(odataRes.value)),
      catchError(err => {
        this._allInternalUsers.next([]);
        this._allInternalUsersTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getMentionableInternalUsers(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<InternalUser>> {
    return this.internalUsersApi.getAllInternalUsers(oDataQueryOptions).pipe(
      tap(odataRes => this._mentionableInternalUsers.next(odataRes.value)),
      catchError(err => {
        return throwError(() => err);
      })
    );
  }

  public getInternalUserById(internalUserId: string): Observable<ODataResponse<InternalUser>> {
    return this.internalUsersApi.getInternalUserById(internalUserId);
  }

  public updateInternalUser(internalUser: InternalUser): Observable<InternalUser> {
    if (internalUser.active === null) internalUser.active = false;
    if (internalUser.lastLoginDate?.getTime() === 0) internalUser.lastLoginDate = null;
    if (internalUser.passwordChangedDate?.getTime() === 0) internalUser.passwordChangedDate = null;
    if (internalUser.eulaAcceptedDate?.getTime() === 0) internalUser.eulaAcceptedDate = null;
    return this.internalUsersApi.updateInternalUser(internalUser);
  }

  public createInternalUser(internalUserReq: CreateInternalUserRequest): Observable<InternalUser> {
    return this.internalUsersApi.createInternalUser(internalUserReq);
  }

  public forceResetInternalUserPassword(internalUser: InternalUser): Observable<HttpResponse<any>> {
    return this.internalUsersApi.forceResetInternalUserPassword(internalUser);
  }

  public forceResetInternalUserEmail(
    changeEmailReq: ChangeEmailRequest,
    internalUser: InternalUser
  ): Observable<InternalUser> {
    return this.internalUsersApi.forceResetInternalUserEmail(changeEmailReq, internalUser);
  }
}
