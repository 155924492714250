export class StatusPill {
  public id: number = 0;
  public name: string = '';
  public primaryColor: string = '';
  public secondaryColor: string = '';
  public textColour: string = '';

  constructor(id: number, name: string, primaryColor?: string, secondaryColor?: string, textColour?: string) {
    this.id = id;
    this.name = name;
    if (primaryColor) this.primaryColor = primaryColor;
    if (secondaryColor) this.secondaryColor = secondaryColor;
    if (textColour) this.textColour = textColour;
  }
}
