// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  mainServiceUrl: 'https://dev.api.gvpensionplan.com',
  mainSocketUrl: 'wss://4o3fe4abpd.execute-api.ca-central-1.amazonaws.com/dev',
  disableEncryptionKey: '37E708FF-281F-4B48-9086-032112C496FB',
  encryptionKey: 'B45AB3DE62944AAEA4C85133C4DD23C2',
  encryptionIV: '*PDgnX94O4@4E6*D',
  encryptionBypass: true
};
