<lib-reactive-form-group
  [permissionMap]="(viewModel.permissionMap$ | async) ?? undefined"
  [atLeastNItemsFilledIn]="1"
  [bindTo]="viewModel.roleMap$ | async"
  [mergeKey]="mergeKey"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true">
  <ng-container
    *ngFor="
      let dropdown of (viewModel.roleDropDownFormItems$ | async) ?? undefined;
      let i = index;
      let last = last;
      let first = first
    ">
    <div [style.display]="inlineLabels ? 'flex' : 'block'" class="role-row">
      <div class="role-dropdown">
        <lib-reactive-form-drop-down
          #dropdownItem
          [inputName]="'role' + i"
          [permissionFieldId]="permissionFieldId"
          [label]="'Role' | localization"
          [inlineLabel]="false"
          [required]="true"
          [disabled]="
            ((first || !last) && i + 1 !== (viewModel.roleDropDownFormItems$ | async)?.length) ||
            !(viewModel.canEditUserRole$ | async)
          "
          [reinitializeWhenListChanges]="false"
          [placeholder]="'Choose a Role' | localization"
          [dropdowns]="(viewModel.roleDropdownOptions$ | async) ?? undefined"
          [bindingProperty]="'roleMap'"
          (valueChanged)="viewModel.roleSelected($event, i)"
          [bindingPropertyMapKey]="i">
        </lib-reactive-form-drop-down>
      </div>
      <div *ngIf="!first" class="remove-role-button">
        <button
          [disabled]="!(viewModel.canEditUserRole$ | async) || (first && last) || !last"
          [ngClass]="{
            'disable-button': !(viewModel.canEditUserRole$ | async) || (first && last) || !last
          }"
          (click)="viewModel.removeRole(i)"
          class="destructive-paddingless-text-button">
          {{ 'Remove Role' | localization }}
        </button>
      </div>
      <hr *ngIf="!inlineLabels" />
    </div>
    <div *ngIf="last && !(viewModel.hideAddRoleButton$ | async)" class="additional-button">
      <lib-button-inline-text
        [style.margin.rem]="0"
        [disabled]="!dropdownItem.getMyValue() || !(viewModel.canEditUserRole$ | async)"
        (buttonClicked)="viewModel.addAdditionalRole(i)">
        {{ 'Add Additional Role' | localization }}
      </lib-button-inline-text>
    </div>
  </ng-container>
  <hr *ngIf="inlineLabels" />
</lib-reactive-form-group>
