import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe implements PipeTransform {
  // Accepts a month number and returns the full name of the month
  transform(value: number): string {
    return moment()
      .month(value - 1)
      .format('MMMM');
  }
}
