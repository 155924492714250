import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseButtonSizeEnum } from '../../components/base-button/base-button-size.enum';
import { BaseButtonColourEnum } from '../../components/base-button/base-button-colour.enum';

export enum SecondaryButtonStyleEnum {
  Secondary,
  Tertiary
}

@Component({
  selector: 'app-base-action-modal',
  templateUrl: './base-action-modal.component.html',
  styleUrls: ['./base-action-modal.component.scss']
})
export class BaseActionModalComponent extends BaseModalComponent {
  // Ensure title is localized before being passed in
  @Input() title: string = '';
  // Ensure primaryLabel is localized before being passed in
  @Input() primaryLabel: string = '';
  // Ensure secondaryLabel is localized before being passed in
  @Input() secondaryLabel: string = '';
  @Input() secondaryButtonStyle: SecondaryButtonStyleEnum = SecondaryButtonStyleEnum.Tertiary;

  @Input() hidePrimary: boolean = false;
  @Input() hideSecondary: boolean = false;
  @Input() hideClose: boolean = false;
  // Use in conjunction with ignoreEscapeModalOptions to ensure the modal
  // cannot be closed on escape
  @Input() ignoreEscape: boolean = false;

  @Output() closeClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() primaryClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondaryClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal) {
    super(activeModal);
  }

  setupBindings(): void {}

  setupViews(): void {}

  protected readonly SecondaryButtonStyleEnum = SecondaryButtonStyleEnum;
  protected readonly BaseButtonSizeEnum = BaseButtonSizeEnum;
  protected readonly BaseButtonColourEnum = BaseButtonColourEnum;
}
