import { Component } from '@angular/core';
import { NavSubItemComponent } from '../nav-sub-item.component';
import { NavSubItemViewModel } from '../nav-sub-item-view-model';

@Component({
  selector: 'app-mobile-nav-sub-item',
  templateUrl: './mobile-nav-sub-item.component.html',
  styleUrls: ['./mobile-nav-sub-item.component.scss'],
  providers: [NavSubItemViewModel]
})
export class MobileNavSubItemComponent extends NavSubItemComponent {}
