import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ViewUserViewModel } from './view-user-view-model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent extends BaseComponent {
  constructor(protected location: Location) {
    super();
  }

  @Input() viewModel!: ViewUserViewModel;

  setupBindings(): void {}

  setupViews(): void {}

  public goBack(): void {
    this.location.back();
  }
}
