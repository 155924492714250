<div class="modal-body sticky">
  <div class="sticky-header-container" [style.overflow-y]="'unset'">
    <div class="modal-header d-flex flex-row">
      <div class="modal-title" i18n>QR Code Support</div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="d-flex flex-row mb-4">
      <img class="icon" [src]="'assets/icons/dark/Sun.svg'" alt="" />
      <div class="flex-column">
        <div class="header" i18n>Make sure your device screen’s brightness is high</div>
        <div class="subtext" i18n>
          The authenticator app may struggle to capture the QR Code if the device screen is too dark
        </div>
      </div>
    </div>

    <div class="d-flex flex-row">
      <img class="icon" [src]="'assets/icons/dark/Crop.svg'" alt="" />
      <div class="flex-column">
        <div class="header" i18n>Make sure the whole QR Code is within the frame</div>
        <div class="subtext" i18n>
          The authenticator app will not be able to scan the QR Code if any part is missing.
        </div>
      </div>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" [style.padding-top]="'1rem'">
    <lib-button-primary (buttonClicked)="cancel()" i18n>Done</lib-button-primary>
  </div>
</div>
