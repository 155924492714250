<div class="auth-title-text">
  {{ 'Verification is needed to complete your log in' }}
</div>

<div class="auth-flow-subtext">
  <div class="subtext">
    {{ viewModel.subText$ | async | localization }}
  </div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="viewModel.req$ | async"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="viewModel.formSubmitted($event)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    [label]="'Verification Code' | localization"
    [inlineLabel]="false"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>

<lib-button-primary class="w-100" (buttonClicked)="form.submitForm()">
  {{ 'Verify Account' | localization }}
</lib-button-primary>
