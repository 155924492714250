import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { AlertsAPI } from '../api/alerts-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Alert } from '../models/alerts/dto/alert';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { catchError, tap } from 'rxjs/operators';
import { TypeService } from '../services/type/type-service';
import { CreateAlertRequest } from '../models/alerts/requests/create-alert-request';
import { HttpResponse } from '@angular/common/http';

// Provided by Logged In Scope
@Injectable()
export class AlertsDomainModel extends BaseDomainModel {
  constructor(private alertsAPI: AlertsAPI) {
    super();
  }

  private typeService = inject(TypeService);

  private _alerts = new BehaviorSubject<Alert[] | null>(null);
  public alerts$ = this._alerts as Observable<Alert[]>;

  private _alertsTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public alertsTotalCount$ = this._alertsTotalCount as Observable<number | undefined>;

  public alertTypes$ = this.typeService.alertTypes$;

  public getAlerts(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Alert>> {
    return this.alertsAPI.getAlerts(oDataQueryOptions).pipe(
      tap(odataRes => this._alertsTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._alerts.next(odataRes.value)),
      catchError(err => {
        this._alerts.next([]);
        this._alertsTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public createAlert(req: CreateAlertRequest): Observable<Alert> {
    return this.alertsAPI.createAlert(req);
  }

  public getAlertById(id: string): Observable<Alert> {
    return this.alertsAPI.getAlertById(id);
  }

  public updateAlert(al: Alert): Observable<Alert> {
    return this.alertsAPI.updateAlert(al);
  }

  public deleteAlert(id: string): Observable<HttpResponse<any>> {
    return this.alertsAPI.deleteAlert(id);
  }
}
