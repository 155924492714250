<div
  class="alert-container"
  [style.background-color]="backgroundColour$ | async"
  [style.border-color]="accentColour$ | async">
  <div class="alert-body">
    <div class="alert-title" [style.color]="titleColour$ | async">{{ title$ | async }}</div>
    <div class="alert-description" [style.color]="textColour$ | async">
      {{ description$ | async }}
    </div>
    <div class="dismiss-button">
      <img *ngIf="isDismissable$ | async" class="dismiss-button" [src]="dismissButtonSrc$ | async" alt="Dismiss" />
    </div>
  </div>
</div>
