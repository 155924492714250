import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent extends BaseComponent implements OnChanges {
  constructor() {
    super();
  }

  @Input() dateRange = false;
  @Input() public initialSelectedDates: NgbDate[] = [];
  @Input() public startDate!: { year: number; month: number };

  //Outputs a single date unless dateRange is true
  @Output() dateSelected = new EventEmitter<NgbDate[]>();

  public clearDateSelection = new Subject<void>();

  public confirmDisabled: boolean = false;
  public currentDate: NgbDate | null = null;
  public currentDateRange: NgbDate[] = [];
  public hoveredDate: NgbDate | null = null;
  public selectedDate: NgbDateStruct | null = null;
  public fromDate: NgbDate | null = null;
  private toDate: NgbDate | null = null;

  setupBindings(): void {
    const clearSub = this.clearDateSelection.subscribe(() => {
      this.clear();
    });
    this.subscriptions.push(clearSub);
  }

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialSelectedDates) {
      if (this.initialSelectedDates.length > 1) {
        this.fromDate = this.initialSelectedDates[0];
        this.toDate = this.initialSelectedDates[1];
      } else if (this.initialSelectedDates.length === 1) {
        this.selectedDate = this.initialSelectedDates[0];
      } else {
        this.clear();
      }
    }
    if (changes.startDate) {
      const currentDate = new Date();
      this.startDate = {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1
      };
    }
    if (changes.dateRange) {
      if (this.dateRange && !this.toDate) {
        this.confirmDisabled = true;
      }
    }
  }

  public reset(): void {
    this.clear();
    this.dateSelected.emit([]);
  }

  public navigate(datepicker: NgbDatepicker, number: number): void {
    const { state, calendar } = datepicker;
    datepicker.navigateTo(calendar.getNext(state.focusedDate, 'm', number));
  }

  public setCurrentDate(date: NgbDate): void {
    if (date.equals(this.currentDate)) {
      this.clear();
      return;
    }
    this.currentDate = date;
    if (this.dateRange) this.handleDateRangeSelection(date);
  }

  public confirm(): void {
    if (this.dateRange && !!this.fromDate && !!this.toDate) {
      this.dateSelected.emit(this.currentDateRange);
    } else {
      this.dateSelected.emit(this.currentDate ? [this.currentDate] : []);
    }
  }

  public handleDateSelection(date: NgbDate): void {
    if (this.dateRange) {
      this.handleDateRangeSelection(date);
    } else {
      this.selectedDate = date;
      this.dateSelected.emit([date]);
    }
  }

  public handleDateRangeSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.currentDateRange = [];
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.currentDateRange = [this.fromDate, this.toDate];
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.currentDateRange = [];
    }
  }

  private clear(): void {
    this.toDate = null;
    this.selectedDate = null;
    this.currentDate = null;
    this.fromDate = null;
  }

  public isHovered(date: NgbDate): boolean | null {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean | null {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isFocused(date: NgbDate): boolean {
    return date.equals(this.currentDate);
  }

  public isRange(date: NgbDate): boolean | null {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public showBackground(date: NgbDate): boolean | null | undefined {
    if (!this.dateRange) return false;
    return (
      (this.isRange(date) && this.hoveredDate?.after(this.fromDate)) ||
      this.isInside(date) ||
      this.isToDate(date) ||
      (this.isFromDate(date) && (this.hoveredDate?.after(this.fromDate) || !!this.toDate))
    );
  }

  public isFromDate(date: NgbDate): boolean {
    return date.equals(this.fromDate);
  }

  public isToDate(date: NgbDate): boolean | null {
    return date.equals(this.toDate);
  }
}
