<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky modal-container" cdkScrollable data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="modal-title">{{ searchableOptions.title }}</div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div class="sticky-body-container searchable-container">
    <div class="searchable-subtitle" [hidden]="searchableOptions.subTitle === ''">
      {{ searchableOptions.subTitle }}
    </div>
    <lib-reactive-form-group [applyDisabledStylesToAllElements]="true" [includeIconWithError]="true">
      <lib-reactive-form-text
        #searchInput
        [inputName]="'search'"
        [hideLabel]="true"
        [placeholder]="searchableOptions.placeholder"
        (valueChanged)="connectToSearchText($event)">
      </lib-reactive-form-text>
    </lib-reactive-form-group>
    <ng-container *ngIf="(fuzzyHits$ | async)?.length > 0; else emptyState">
      <div *ngFor="let i of fuzzyHits$ | async" class="searchable-row" (click)="itemClicked(i.value)">
        <div>{{ i.title }}</div>
      </div>
    </ng-container>
    <ng-template #emptyState>
      <div class="searchable-empty-text">
        {{ searchableOptions.noResultsText }}
      </div>
      <div>
        <button
          class="primary-paddingless-text-button"
          (click)="clearSearch(); searchInput.getSelfAsFormItem()?.reset()">
          {{ searchableOptions.noResultsButtonText }}
        </button>
      </div>
    </ng-template>
  </div>

  <div class="sticky-footer-container">
    <div class="modal-footer-flex-end">
      <app-secondary-button [size]="BaseButtonSizeEnum.md" (buttonClicked)="cancel()">
        {{ searchableOptions.closeButtonText }}
      </app-secondary-button>
    </div>
  </div>
</div>
