export class MfaType {
  public id!: number;
  public name!: string;
}

export enum MfaTypeIdEnum {
  TextMessage = 1,
  App = 2,
  None = 3
}
