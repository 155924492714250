import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { PermissionTypeMap } from '../../../../models/permissions/base/permission-type-map';
import { AddRoleMap } from '../../../../models/shared/add-role-map';
import { AddRemoveRoleFormViewModel } from './add-remove-role-form-view-model';
import { BaseUser } from '../../../../models/base/base-user';

@Component({
  selector: 'app-add-remove-role-form',
  templateUrl: './add-remove-role-form.component.html',
  styleUrls: ['./add-remove-role-form.component.scss'],
  providers: [AddRemoveRoleFormViewModel]
})

/**
 * This component is designed to be utilized in conjunction with a form group merge key,
 * resulting in a form group that produces multiple outputs. The form group output is structured
 * as a map of the type:
 *
 * roleMap: {
 *    roleMap: Map<number, number | undefined>;
 * }
 *
 * In this structure, a number represents a role id. By iterating through the map, you can easily
 * identify the role ids that are present. For example:
 *
 * ```
 * const roleMap = formSubmissions[1].roleMap;
 * internalUser.roles = [];
 * roleMap.forEach(roleId => {
 *    if (roleId !== undefined) {
 *       internalUser.roles.push(new UserRole(roleId));
 *    }
 * });
 * ```
 *
 * This allows for the dynamic handling of role ids within the form submissions.
 */
export class AddRemoveRoleFormComponent extends BaseComponent implements OnChanges {
  @Input() mergeKey: string = '';
  @Input() permissionMap: Map<number, PermissionTypeMap> = new Map<number, PermissionTypeMap>();
  @Input() addRoleMap: AddRoleMap = new AddRoleMap();
  @Input() atLeastNItemsFilledIn: number = 1;
  // Ensure your user object has a roles property, and implements UniquelyIdentifiable
  @Input() user: BaseUser = {} as BaseUser;
  @Input() inlineLabels: boolean = true;
  @Input() permissionFieldId!: string;

  constructor() {
    super();
  }

  public viewModel = inject(AddRemoveRoleFormViewModel);

  setupBindings(): void {}

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.permissionMap) this.viewModel.setPermissionMap(this.permissionMap);
    if (changes.addRoleMap) this.viewModel.setRoleMap(this.addRoleMap);
    if (changes.user) this.viewModel.setUser(this.user);
  }
}
