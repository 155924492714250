import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Event, NavigationEnd, Router } from '@angular/router';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { NavItem } from './models/nav-item';

@Injectable()
export class NavItemViewModel extends BaseViewModel {
  protected loadingOpts = new BehaviorSubject<LoadingOptions | null>(null);
  protected _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());
  protected navItem$ = new BehaviorSubject<NavItem | null>(null);
  public isNavItemActive$ = combineLatest([this.navItem$, this.currentNavUrl$]).pipe(
    map(([nav, url]) => {
      const isActive = nav?.itemMapsToRoute
        ? url.indexOf(`${nav?.routerPath}`) === 0
        : nav?.subItems?.some(sub => url.indexOf(`${nav.routerPath}/${sub.relativeRouterPath}`) === 0) ?? false;
      if (isActive && nav?.itemMapsToRoute) {
        this.navigateToFirstChild();
      }
      return isActive;
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public multiLineNavItem$ = this.navItem$.pipe(
    map(navItem => {
      if (navItem?.name?.length) {
        return navItem.name.length > 12;
      }
      return false;
    })
  );

  constructor(protected router: Router) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.bindUrlParams();
  }

  public hasIcons$ = this.navItem$.pipe(map(ni => ni?.iconFilledSrc !== '' && ni?.iconFilledSrc !== ''));

  private bindUrlParams() {
    this._currentNavUrl.next(this.router.url);
    this.router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.onDestroy)
      )
      .subscribe(evt => this.urlChanged(evt));
  }

  connectToNavItem(item: NavItem) {
    this.navItem$.next(item);
  }

  connectToLoadingOpts(loadingOpts: LoadingOptions) {
    this.loadingOpts.next(loadingOpts);
  }

  navigateToFirstChild() {
    combineLatest([this.navItem$, this.currentNavUrl$])
      .pipe(take(1))
      .subscribe(([currentNavItem, currentUrl]) => {
        const forwardSlashCount = currentUrl.split('/').length;
        // Tests if we are on a parent route, ie: /menus
        // This is an invalid route so we should reroute us to the first child nav route, ie: /menus/digital
        // first case catches /menus, second case catches /menus/
        const isParentRoute = forwardSlashCount - 1 === 1 || (forwardSlashCount - 1 === 2 && currentUrl.endsWith('/'));
        if (!!currentNavItem?.hasSubItems() && isParentRoute) {
          const firstChildNavItem = currentNavItem.subItems[0];
          this.router
            .navigate([`${currentNavItem?.routerPath}/${firstChildNavItem?.relativeRouterPath}`])
            .then(() => {});
        }
      });
  }

  private urlChanged(nav: NavigationEnd) {
    this._currentNavUrl.next(nav.url);
  }
}
