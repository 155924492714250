import { Type } from '@angular/core';
import { PolymorphicDeserializationKey } from '../enum/shared/polymorphic-deserialization-key.enum';
import { DeserializeLabelHelper } from './deserialize-label-helper';
import { Deserializable } from './deserializable';
import * as moment from 'moment/moment';

/**
 * Don't import this class directly, use window?.injector?.Deserialize to access the static methods of this class.
 * Accessing directly will cause circular dependency warnings.
 */
export class InjectedDeserializer {
  private static getPolymorphicInstance<T extends Deserializable>(ObjectType: Type<T>, data: any): T {
    const polymorphismKey = Object.create(ObjectType.prototype)?.getPolymorphicDeserializationKey?.();
    return polymorphismKey >= 0
      ? window?.injector?.Deserialize?.getPolymorphicObject(polymorphismKey, data)
      : Object.assign(Object.create(ObjectType.prototype), data);
  }

  /**
   * If ObjectType implements getPolymorphicDeserializationKey, then this method will return a polymorphic object.
   * Example:
   * const Deserialize = window?.injector?.Deserialize;
   * Deserialize?.instanceOf(Label, saleSystemLabelData), will return an object of type SaleSystemLabel and not Label.
   */
  static instanceOf<T extends Deserializable>(ObjectType: Type<T>, data: any, optionalParam?: any): T {
    if (!!data) {
      const instance: T = InjectedDeserializer.getPolymorphicInstance(ObjectType, data);
      if (!!optionalParam) {
        instance.onDeserialize(optionalParam);
      } else {
        instance?.onDeserialize();
      }
      if (!!instance) {
        return instance as T;
      }
    }
    throw new Error(`Unable to deserialize ${ObjectType.name}.`);
  }

  static shallowCopyOf<T extends { [key: string]: any }>(data: T): T | null {
    if (!data) {
      return null;
    } else {
      const shallowCopy = Object.create(Object.getPrototypeOf(data));
      Object.keys(data).forEach(key => (shallowCopy[key] = data[key]));
      return shallowCopy;
    }
  }

  static arrayOf<T extends Deserializable>(ObjectType: Type<T>, data: any[]): T[] {
    const arr: T[] = [];
    if (data) {
      data.forEach(d => {
        const inst: T | undefined = window?.injector?.Deserialize?.instanceOf(ObjectType, d);
        if (inst !== undefined) {
          arr.push(inst);
        }
      });
    }
    return arr;
  }

  static mapOf<X extends string | number, Y extends Deserializable>(
    ObjectType: Type<Y>,
    data: Map<X, Y | null>
  ): Map<X, Y> {
    if (!data) {
      return new Map();
    } else {
      const newMap = new Map<X, Y>();
      data.forEach((val, key) => {
        if (val !== null) {
          newMap.set(key, this.instanceOf(ObjectType, val));
        }
      });
      return newMap;
    }
  }

  private static getPolymorphicObject<T extends Deserializable>(
    key: PolymorphicDeserializationKey,
    data: any
  ): T | null {
    const getPolymorphicObjectFromKey = {
      [PolymorphicDeserializationKey.Label]: DeserializeLabelHelper.getPolymorphicLabelObject
    } as {
      [k in PolymorphicDeserializationKey]: (data: any) => T;
    };
    return getPolymorphicObjectFromKey[key]?.(data) ?? null;
  }

  static genericMap<X extends string | number, Y>(data: Map<X, Y>): Map<X, Y> {
    const dataMap: Map<any, any> = data instanceof Map ? data : new Map(Object.entries(data));
    return dataMap.deepCopy();
  }

  static genericArrayMap<X extends string | number, Y extends string | number>(data: Map<X, Y[]>): Map<X, Y[]> | null {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y[]>();
      data.forEach((val, key) => newMap.set(key as X, val as Y[]));
      return newMap;
    }
  }

  static genericNestedArrayMap<X extends string | number, Y extends string | number, Z extends string | number>(
    data: Map<X, Map<Y, Z[]>>
  ): Map<X, Map<Y, Z[]>> | null {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Map<Y, Z[]>>();
      data.forEach((val, key) => {
        const nestedMap = InjectedDeserializer.genericMap(val);
        newMap.set(key as X, nestedMap as Map<Y, Z[]>);
      });
      return newMap;
    }
  }

  static typedArrayMapOf<X extends string | number, Y extends Deserializable>(
    ObjectType: Type<Y>,
    data: Map<X, Y[]>
  ): Map<X, Y[]> | null {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y[]>();
      data.forEach((val, key) => newMap.set(key as X, this.arrayOf(ObjectType, val)));
      return newMap;
    }
  }

  static deserializeToDate(date: Date | string): Date {
    if (!!date) {
      return moment.utc(date.toString()).toDate();
    }
    return new Date();
  }
}
