<app-base-action-modal
  title="Change Email"
  primaryLabel="Update Email Address"
  secondaryLabel="Cancel"
  i18n-title
  i18n-primaryLabel
  i18n-secondaryLabel
  (closeClicked)="closeModal()"
  (primaryClicked)="form.submitForm()"
  (secondaryClicked)="closeModal()">
  <app-loading *ngIf="isLoading$ | async" [options]="(loadingOpts$ | async) ?? undefined"> </app-loading>
  <lib-reactive-form-group
    #form
    [bindTo]="changeEmailReq$ | async"
    [applyDisabledStylesToAllElements]="true"
    [includeIconWithError]="true"
    (formSubmitted$)="formSubmitted($event)">
    <lib-reactive-form-email
      [required]="true"
      [disabled]="true"
      label="Current Email Address"
      i18n-label
      [bindingProperty]="'currentEmail'"
      [inputName]="'currentEmail'"></lib-reactive-form-email>
    <lib-reactive-form-email
      [required]="true"
      label="New Email Address"
      i18n-label
      [bindingProperty]="'newEmail'"
      [inputName]="'email'"></lib-reactive-form-email>
    <lib-reactive-form-email
      [required]="true"
      label="Confirm New Email Address"
      i18n-label
      [confirmEmail]="true"
      [inputName]="'confirmEmail'"></lib-reactive-form-email>
  </lib-reactive-form-group>
</app-base-action-modal>
