import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Router } from '@angular/router';
import { NavSubItemViewModel } from './nav-sub-item-view-model';
import { NavItem } from '../nav-item/models/nav-item';
import { NavSubItem } from './models/nav-sub-item';
import { SessionService } from '../../../../../services/session-service';

@Component({
  selector: 'app-nav-sub-item',
  templateUrl: './nav-sub-item.component.html',
  styleUrls: ['./nav-sub-item.component.scss'],
  providers: [NavSubItemViewModel]
})
export class NavSubItemComponent extends BaseComponent implements OnChanges {
  @Input() navItem!: NavItem;
  @Input() subItem!: NavSubItem;
  @Output() subItemClicked = new EventEmitter<void>(true);

  public viewModel = inject(NavSubItemViewModel);
  private session = inject(SessionService);
  public subItemHasIcon$ = this.viewModel.subItemHasIcon$;

  constructor(protected router: Router) {
    super();
  }

  setupViews() {
    this.viewModel.connectToNavItem(this.navItem);
    this.viewModel.connectToSubNavItem(this.subItem);
  }

  setupBindings() {}

  navItemSelected() {
    const extras = {
      fragment: this.subItem?.subItemFragment ? this.subItem?.subItemFragment : this.subItem?.savedFragment
    };
    if (this.subItem.isSignOutItem) {
      this.router.navigate([`/auth/sign-out`]).then();
    } else if (!!this.subItem?.subItemFragment) {
      this.router
        .navigate([`${this.navItem?.baseFragmentRoute}/${this.subItem?.relativeRouterPath}`], extras)
        .then(() => {});
    } else {
      this.router.navigate([`${this.navItem?.routerPath}/${this.subItem?.relativeRouterPath}`], extras).then(() => {});
    }
    if (this.subItem.dumpCacheOnNavigate) this.session.destroySession.next(true);
    this.subItemClicked.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navItem) this.viewModel.connectToNavItem(this.navItem);
    if (changes.subItem) this.viewModel.connectToSubNavItem(this.subItem);
  }
}
