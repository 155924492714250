<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="confirmation-modal-title">
        {{ confirmationOptions?.title }}
      </div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div *ngIf="!confirmationOptions.bodyTextAsHtml" ngbAutoFocus class="body-text">
      {{ confirmationOptions?.bodyText }}
    </div>
    <div
      class="body-text"
      *ngIf="confirmationOptions.bodyTextAsHtml"
      ngbAutoFocus
      [innerHTML]="confirmationOptions?.bodyText | trustAsHtml"></div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral *ngIf="confirmationOptions?.showCancel" (buttonClicked)="cancel()">
      {{ confirmationOptions?.cancelText }}
    </lib-button-neutral>
    <lib-button-primary *ngIf="confirmationOptions?.showContinue" (buttonClicked)="continue()">
      {{ confirmationOptions?.continueText }}
    </lib-button-primary>
  </div>
</div>
