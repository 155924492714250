import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccordionStatusService {
  private _accordionStatuses = new BehaviorSubject(new Map<string, boolean>());
  public accordionStatuses$ = this._accordionStatuses as Observable<Map<string, boolean>>;

  public toggleAccordion(accordionId: string): void {
    this.accordionStatuses$.once(currentStatuses => {
      const newStatuses = new Map<string, boolean>();

      if (currentStatuses.has(accordionId)) {
        currentStatuses.forEach((value, key) => {
          newStatuses.set(key, key === accordionId ? !value : false);
        });
      } else {
        currentStatuses.forEach((value, key) => {
          newStatuses.set(key, false);
        });
        newStatuses.set(accordionId, true);
      }

      this._accordionStatuses.next(newStatuses);
    });
  }

  public closeAllAccordions(): void {
    this.accordionStatuses$.once(currentStatuses => {
      const newStatuses = new Map<string, boolean>();

      currentStatuses.forEach((value, key) => {
        newStatuses.set(key, false);
      });

      this._accordionStatuses.next(newStatuses);
    });
  }
}
