<app-header-container [includeBanner]="includeBanner">
  <div header-content class="d-flex flex-column gap-4" [style.background-color]="'white'">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="d-flex justify-content-between">
      <div class="header-text">{{ title }}</div>
    </div>
  </div>
  <div *ngIf="includeBanner" banner-content>
    <ng-content></ng-content>
  </div>
</app-header-container>
