<app-base-action-modal
  title="Release Note"
  primaryLabel="Close"
  [hideSecondary]="true"
  i18n-primaryLabel
  i18n-title
  (closeClicked)="close(null)"
  (primaryClicked)="close(null)">
  <div class="container">
    <span class="header-title" i18n>{{ versionString$ | async }}</span>
    <div *ngIf="(features$ | async)?.length">
      <span class="header-title" i18n>Features</span>
      <ul>
        <li class="note-item" *ngFor="let feature of features$ | async">{{ feature }}</li>
      </ul>
    </div>
    <div *ngIf="(bugFixes$ | async)?.length">
      <span class="header-title" i18n>Bug Fixes</span>
      <ul>
        <li class="note-item" *ngFor="let fix of bugFixes$ | async">{{ fix }}</li>
      </ul>
    </div>
  </div>
</app-base-action-modal>
