<div class="system-banner" [ngClass]="[banner.bannerType, banner.layout]">
  <div class="d-flex gap-2 align-items-start">
    <img *ngIf="!banner.hideIcon" class="system-banner-icon" [src]="banner.bannerType | systemBannerIcon" alt="" />
    <div>{{ banner.text }}</div>
  </div>
  <div *ngIf="banner.buttonText || banner.isDismissible" class="d-flex gap-2 align-items-center">
    <button *ngIf="banner.buttonText" class="no-style-button system-banner-button" (click)="textButtonClicked.emit()">
      {{ banner.buttonText }}
    </button>
    <button *ngIf="banner.isDismissible" class="no-style-button" (click)="dismissBannerClicked.emit()">
      <img class="system-banner-dismiss" [src]="banner.bannerType | systemBannerDismissIcon" alt="" />
    </button>
  </div>
</div>
