import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { PillItem } from '../../../../models/shared/pill-item';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pill-radio-container',
  templateUrl: './pill-radio-container.component.html',
  styleUrls: ['./pill-radio-container.component.scss']
})
export class PillRadioContainerComponent extends BaseComponent implements OnChanges {
  @Input() pillItems: PillItem[] = [];
  @Input() pillPadding = '0.75rem 1.5rem';
  @Output() selectedPillValue = new EventEmitter<any>();

  private _pillItems = new BehaviorSubject<PillItem[]>([]);
  public pillItems$ = this._pillItems as Observable<PillItem[]>;

  private selectedPill$ = new BehaviorSubject<PillItem | null>(null);

  setupViews() {}

  setupBindings() {
    this.pillItems$.once(pills => {
      const selectedPill = pills.find(pill => pill.selected);
      if (selectedPill) {
        this.selectedPill$.next(selectedPill);
        this.selectedPillValue.emit(selectedPill.value);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pillItems) {
      this._pillItems.next(this.pillItems);
    }
  }

  public onPillClick(pill: PillItem): void {
    if (!pill.clickable || pill.disabled) {
      return;
    }

    combineLatest([this.selectedPill$, this.pillItems$])
      .pipe(take(1))
      .subscribe(([selectedPill, pills]) => {
        if (pill === selectedPill) {
          return;
        }
        const updatedPills = pills.map(p => ({ ...p, selected: p.value === pill.value }));

        this.selectedPill$.next(pill);
        this.selectedPillValue.emit(pill.value);
        this._pillItems.next(updatedPills);
      });
  }
}
