import { Deserializable } from '../protocols/deserializable';
import { Account } from './account';
import { TransactionStatus } from './transaction-status';
import { TransactionStatusPill } from '../shared/transaction-status-pill';

export class TransactionEntry implements Deserializable {
  public id!: string;
  public transactionId!: number;
  public accountId!: number;
  public codeId!: number;
  public legacyCodeId!: number;
  public debitAmount!: number | null;
  public creditAmount!: number | null;
  public account!: Account;
  public createdDate: Date | undefined;
  public postedDate: Date | undefined;
  public status: TransactionStatus | undefined;

  // not from API
  public statusPill: TransactionStatusPill | undefined;

  onDeserialize() {
    if (this.createdDate) {
      this.createdDate = new Date(this.createdDate);
    }
    if (this.postedDate) {
      this.postedDate = new Date(this.postedDate);
    }
    if (this.status) {
      this.status = window?.injector?.Deserialize.instanceOf(TransactionStatus, this.status);
      this.statusPill = new TransactionStatusPill(this.status.id, this.status.name);
    }
  }
}
