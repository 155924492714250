import { Deserializable } from '../../protocols/deserializable';
import { Country } from '../../types/dto/country';
import { State } from '../../types/dto/state';

export class Address implements Deserializable {
  id!: string;
  addressLine1!: string;
  addressLine2!: string;
  city!: string;
  stateId!: number;
  state!: State;
  stateCode!: string;
  countryId!: number;
  country!: Country;
  countryCode!: string;
  postalCode!: string;

  // Not from API
  countryName!: string;
  stateName!: string;

  onDeserialize(): void {
    if (!!this.state) {
      this.stateName = this.state.name;
    }
    if (!!this.country) {
      this.countryName = this.country.name;
    }
  }

  isEmptyAddress(): boolean {
    return !this.addressLine1 && !this.city && !this.stateId && !this.postalCode;
  }
}
