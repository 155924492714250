import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, Subject } from 'rxjs';
import { MemberUser } from '../../../../../models/account/dto/member-user';

export abstract class BaseMemberRecordCardViewModel extends BaseViewModel {
  private _user = new BehaviorSubject<MemberUser | null>(null);
  public user$ = this._user as BehaviorSubject<MemberUser | null>;

  public userUpdated$ = new Subject<MemberUser>();

  public setUser(user: MemberUser): void {
    this._user.next(user);
  }

  public abstract formSubmitted(req: any): void;
}
