import { Component, ElementRef, inject, Input, Renderer2, ViewChild } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss']
})
export class HeaderContainerComponent extends BaseComponent {
  @Input() includeBanner: boolean = false;

  @ViewChild('header') header: ElementRef | undefined;
  @ViewChild('banner') banner: ElementRef | undefined;
  @ViewChild('buffer') buffer: ElementRef | undefined;

  private renderer = inject(Renderer2);
  private headerObserver = new ResizeObserver(() => {
    const headerHeight = this.header?.nativeElement?.offsetHeight;
    const bannerHeight = this.banner?.nativeElement?.offsetHeight ?? 0;
    const totalHeight = headerHeight + bannerHeight;
    this.renderer.setStyle(this.buffer?.nativeElement, 'height', totalHeight + 'px');
  });

  private bannerObserver = new ResizeObserver(() => {
    const bannerHeight = this.banner?.nativeElement?.offsetHeight ?? 0;
    const headerHeight = this.header?.nativeElement?.offsetHeight ?? 0;
    const totalHeight = headerHeight + bannerHeight;
    this.renderer.setStyle(this.banner?.nativeElement, 'top', totalHeight + 'px');
  });

  public setupBindings(): void {
    this.headerObserver.observe(this.header?.nativeElement);
    this.bannerObserver.observe(this.banner?.nativeElement);
  }

  public setupViews(): void {}

  public destroy(): void {
    super.destroy();
    this.headerObserver.disconnect();
    this.bannerObserver.disconnect();
  }
}
