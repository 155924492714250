import { Pipe, PipeTransform } from '@angular/core';
import { SystemBannerType } from '../../../../models/shared/system-banner';

@Pipe({
  name: 'systemBannerDismissIcon'
})
export class SystemBannerDismissIconPipe implements PipeTransform {
  private iconMap: Record<SystemBannerType, string> = {
    error: 'assets/icons/alerts/RedClose.svg',
    info: 'assets/icons/alerts/BlueClose.svg',
    success: 'assets/icons/alerts/GreenClose.svg',
    warning: 'assets/icons/alerts/YellowClose.svg'
  };
  transform(value: SystemBannerType): string {
    return this.iconMap[value];
  }
}
