import { inject, Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { RemittancesAPI } from '../api/remittances-api';
import { Observable } from 'rxjs';
import { Remittance } from '../models/remittances/remittance';
import { map } from 'rxjs/operators';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { Contribution } from '../models/remittances/contribution';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { ContributionSummary } from '../models/remittances/contribution-summary';
import { RemittanceOwner } from '../models/remittances/remittance-owner';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { ContributionEntry } from '../models/remittances/contribution-entry';

@Injectable()
export class RemittanceDomainModel extends BaseDomainModel {
  private remittancesAPI = inject(RemittancesAPI);

  public getOpenRemittancesForEmployer(employerId: number): Observable<Remittance[]> {
    return this.remittancesAPI.getOpenRemittancesForEmployer(employerId).pipe(map(response => response.value));
  }

  public getClosedRemittanceCountForEmployer(employerId: number): Observable<number> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setCount(true);
    queryOptions.setTop(0);
    return this.remittancesAPI
      .getClosedRemittancesForEmployer(employerId, queryOptions)
      .pipe(map(response => response['@odata.count'] ?? 0));
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    oDataOpts: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    return this.remittancesAPI.getClosedRemittancesForEmployer(employerId, oDataOpts);
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    return this.remittancesAPI.createRemittance(req);
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    return this.remittancesAPI.getRemittanceById(remittanceId, queryOptions);
  }

  public getRemittanceOwners(
    oDataQueryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<RemittanceOwner[]> {
    return this.remittancesAPI.getRemittanceOwners(oDataQueryOptions).pipe(map(response => response.value));
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions = new ODataQueryOptions()
  ): Observable<ODataResponse<Contribution>> {
    queryOptions.setOrderBy('memberLastName asc');
    return this.remittancesAPI.getContributionsForRemittance(remittanceId, queryOptions);
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    return this.remittancesAPI
      .getRemittanceHistory(remittanceId)
      .pipe(map(history => history.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())));
  }

  public getContributionHistory(remittanceId: string, contributionId: string): Observable<RemittanceHistory[]> {
    return this.remittancesAPI.getContributionHistory(remittanceId, contributionId);
  }

  public getContributionSummaryForRemittance(remittanceId: number): Observable<ContributionSummary> {
    return this.remittancesAPI.getContributionSummaryForRemittance(remittanceId.toString(10));
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    return this.remittancesAPI.addContribution(remittanceId, req);
  }

  public deleteContribution(remittanceId: number, contributionId: string): Observable<any> {
    return this.remittancesAPI.deleteContribution(remittanceId, contributionId);
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    return this.remittancesAPI.createContributionEntry(remittanceId, contributionId, entries);
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    return this.remittancesAPI.updateContributionEntry(remittanceId, contributionId, entry);
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    return this.remittancesAPI.deleteContributionEntry(remittanceId, contributionId, entryId);
  }
}
