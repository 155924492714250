import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OdataFilterGenerator } from '../../../../interfaces/odata-filter-generator';
import { StringExtensions } from '../../../../utils/string.extensions';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateValidDirective } from '../../directives/date-valid-directive.directive';
import { ReactiveFormMaskedInputComponent } from '@csspension/reactive-form';
import { debounceTime } from 'rxjs/operators';
import { InternalUserTableFilterRequest } from '../../../../models/shared/odata-filter-requests/internal-user-table-filter-request';
import { createPopper, Instance } from '@popperjs/core';
import { DateUtils } from '../../../../utils/date-utils';
import { ScreenService } from '../../../../services/screen-service.service';

@Component({
  selector: 'app-table-column-search-bar',
  templateUrl: './table-column-search-bar.component.html',
  styleUrls: ['./table-column-search-bar.component.scss']
})
export class TableColumnSearchBarComponent extends BaseComponent implements OnChanges {
  constructor() {
    super();
  }
  @ViewChild('dateInput') dateInput!: ReactiveFormMaskedInputComponent;
  @ViewChild('datePicker') datePicker!: ElementRef;

  @Input() useDatePicker = false;
  @Input() searchForm!: OdataFilterGenerator;
  @Input() editable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() bindingProperty = '';
  @Input() placeholder = '';
  @Input() clearDates$!: Subject<void>;
  @Input() clearSearches$!: Subject<void>;
  @Output() searchValue = new EventEmitter<OdataFilterGenerator>();

  private instance: Instance | undefined;

  private _useDatePicker = new BehaviorSubject<boolean>(false);
  public readonly useDatePicker$ = this._useDatePicker as Observable<boolean>;

  private _searchForm = new BehaviorSubject<OdataFilterGenerator>(new InternalUserTableFilterRequest());
  public readonly searchForm$ = this._searchForm as Observable<OdataFilterGenerator>;

  private _bindingProperty = new BehaviorSubject<string>('');
  public readonly bindingProperty$ = this._bindingProperty as Observable<string>;

  private _placeholder = new BehaviorSubject<string>($localize`Search`);
  public readonly placeholder$ = this._placeholder as Observable<string>;

  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public shouldShowDatePicker = false;
  public calClicked: boolean = false;
  public validDateDirective = new DateValidDirective();

  private _selectedDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly selectedDates$ = this._selectedDates as Observable<NgbDate[]>;

  private _selectedDate = new BehaviorSubject<string>('');
  public readonly selectedDate$ = this._selectedDate.pipe(debounceTime(100));

  private screenService = inject(ScreenService);

  setupBindings(): void {
    const clearDatesSub = this.clearDates$?.subscribe(_ => {
      this._selectedDate.next('');
      this._selectedDates.next([]);
    });
    this.pushSub(clearDatesSub);

    const clearSearchesSub = this.clearSearches$?.subscribe(_ => {
      this._selectedDate.next('');
    });
    this.pushSub(clearSearchesSub);
  }

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchForm) {
      this._searchForm.next(this.searchForm);
    }
    if (changes.bindingProperty) this._bindingProperty.next(this.bindingProperty);
    if (changes.placeholder) this._placeholder.next(this.placeholder);
    if (changes.useDatePicker) this._useDatePicker.next(this.useDatePicker);
  }

  destroy() {
    super.destroy();
    this.instance?.destroy();
  }

  public calendarClicked(): void {
    this.calClicked = true;
    this.toggleDatePicker();
  }

  public toggleDatePicker() {
    if (this.shouldShowDatePicker) {
      this.hideDatePicker();
    } else {
      this.showDatePicker();
    }
  }

  public showDatePicker() {
    this.shouldShowDatePicker = true;
    this.datePicker.nativeElement.style.display = 'block';
    this.instance = createPopper(this.dateInput.getNativeElement(), this.datePicker.nativeElement);
    this.screenService.showOverlay();
  }

  public hideDatePicker() {
    this.shouldShowDatePicker = false;
    this.datePicker.nativeElement.style.display = 'none';
    this.instance?.destroy();
    this.screenService.hideOverlay();
  }

  public onClickOutside(): void {
    if (this.shouldShowDatePicker && !this.calClicked) this.hideDatePicker();
    this.calClicked = !this.calClicked;
  }

  public handleDateSelection(dates: NgbDate[]): void {
    if (dates.length === 1) {
      this._selectedDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
    } else if (!dates.length) {
      this._selectedDate.next('');
    }
    this._selectedDates.next(dates);
    this.hideDatePicker();
  }

  public formSubmitted(form: OdataFilterGenerator): void {
    this.searchValue.emit(form);
  }

  public checkDateValue(v: string): void {
    if (!v) {
      this._selectedDate.next('');
      this._selectedDates.next([]);
    }
  }

  protected readonly StringExtensions = StringExtensions;
}
