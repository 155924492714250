<div class="auth-flow-subtext">
  <div class="header" i18n>{{ 'Forgot Your Password?' }}</div>
  <div class="subtext" i18n>
    {{
      'Enter your email address below to receive instructions for resetting your password and regaining access to your account.'
    }}
  </div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    #resetCodeEmail
    [inputName]="'email'"
    [label]="'Email' | localization"
    [placeholder]="''"
    [bindingProperty]="'email'"
    [showRequiredAstrix]="false"
    [required]="true">
  </lib-reactive-form-email>
</lib-reactive-form-group>

<div class="reset-button-container">
  <lib-button-primary
    class="action-button"
    [disabled]="(viewModel.emailSent$ | async) ?? false"
    (buttonClicked)="form.submitForm()">
    {{ (viewModel.emailSent$ | async) ? emailSentText : resetPasswordText }}
  </lib-button-primary>

  <button class="paddingless-text-button" i18n (click)="backToLogin()">
    {{ 'Back to Login' }}
  </button>

  <div class="subtext pt-16px" *ngIf="viewModel.emailSent$ | async">
    {{ 'The email has been sent to the address above with a link to set a new password.' | localization }}
  </div>
</div>
