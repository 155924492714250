import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import * as moment from 'moment';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import { OdataUtils } from '../../../utils/odata-utils';

export class InternalUserTableFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public active: boolean[] = [];
  public roles: string[] = [];
  public startDate: string = '';
  public endDate: string = '';

  public searchFirstName: string = '';
  public searchLastName: string = '';
  public searchEmail: string = '';
  public searchRoleName: string = '';
  public searchStatus: string | null = '';
  public searchLastLogin: string = '';

  private filterCount: number = 0;
  private searchCount: number = 0;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];
    if (this.active?.length > 0) {
      filterArray.push(`active eq ${this.active}`);
      this.filterCount++;
    }
    if (this.roles?.length > 0) {
      let base = `roles/any(role: `;
      this.roles.forEach(r => {
        base += `role/roleId eq ${r} or `;
        this.filterCount++;
      });
      // removing the last or
      filterArray.push(base.slice(0, -4) + ')');
    }
    if (this.startDate !== '') {
      const startDate = moment(this.startDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.endDate !== '') {
        endDate = moment(this.endDate).endOf('day');
      }
      filterArray.push(
        `lastLoginDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and lastLoginDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.filterCount++;
    }
    if (this.searchLastLogin !== '') {
      const filterString = OdataUtils.constructDateSearchFilterQuery(this.searchLastLogin, 'lastLoginDate');
      filterArray.push(filterString);

      this.searchCount++;
    }
    if (this.searchFirstName !== '') {
      filterArray.push(`contains(firstName, '${this.searchFirstName}')`);
      this.searchCount++;
    }
    if (this.searchLastName !== '') {
      filterArray.push(`contains(lastName, '${this.searchLastName}')`);
      this.searchCount++;
    }
    if (this.searchEmail !== '') {
      filterArray.push(`contains(email, '${this.searchEmail}')`);
      this.searchCount++;
    }
    if (this.searchRoleName !== '') {
      filterArray.push(`Roles/any(parent: contains(parent/role/name, '${this.searchRoleName}'))`);
      this.searchCount++;
    }
    if (this.searchStatus !== '' && this.searchStatus !== null) {
      filterArray.push(`(active eq ${this.searchStatus})`);
      this.searchCount++;
    }
    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  public getFilterCount(): number {
    this.filterCount = 0;
    this.getFilterString();
    return this.filterCount;
  }

  public getSearchCount(): number {
    this.searchCount = 0;
    this.getFilterString();
    return this.searchCount;
  }

  public clearFilters(): void {
    this.active = [];
    this.roles = [];
    this.startDate = '';
    this.endDate = '';
  }

  getUniqueIdentifier(): string {
    return `
    ${this.active.join(',')} +
    ${this.roles.join(',')} +
    ${this.startDate} +
    ${this.endDate} +
    ${this.searchFirstName} +
    ${this.searchLastName} +
    ${this.searchEmail} +
    ${this.searchRoleName} +
    ${this.searchStatus} +
    ${this.searchLastLogin} +
    ${this.filterCount} +
    ${this.searchCount}`;
  }

  onDeserialize(): void {}
}
