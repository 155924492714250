<button
  type="button"
  [ngClass]="{ loaded: loaded }"
  [class]="colour + ' ' + size"
  [disabled]="disabled"
  (click)="!disabled && buttonClicked.emit()">
  <img [class]="size" *ngIf="leftIconSrc" [src]="leftIconSrc" />
  <ng-content></ng-content>
  <img [class]="size" *ngIf="rightIconSrc" [src]="rightIconSrc" />
</button>
