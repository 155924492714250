import { Deserializable } from '../protocols/deserializable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { Searchable } from '../protocols/searchable';

export class ReleaseNoteDocumentVersion implements Deserializable, LookAheadItem, Searchable {
  public version!: string;
  public title!: string;
  public value!: any;

  onDeserialize(): void {
    this.value = this.version;
    this.title = this.version;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }
}
