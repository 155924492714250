<div
  class="sub-nav-item"
  (click)="navItemSelected(); $event.stopPropagation()"
  [class.sub-active]="viewModel.isNavItemActive$ | async">
  <div *ngIf="subItemHasIcon$ | async" class="nav-item-icon">
    <img [src]="subItem.iconSrc" alt="icon" class="nav-bar-icon" />
  </div>
  <div class="sub-nav-item-title">
    {{ subItem?.name }}
  </div>
</div>
