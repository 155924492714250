import { PermissionMap } from '../base/permission-map';
import { PermissionTypeMap } from '../base/permission-type-map';
import { PermissionType } from '../base/permission-type';

export class EmployerPermissionMap extends PermissionMap {
  public map: Map<number, PermissionTypeMap> = new Map<number, PermissionTypeMap>();

  constructor() {
    super();
    this.map.set(2001, new PermissionTypeMap(2001, PermissionType.Action, 'viewEmployeesTable'));
    this.map.set(2002, new PermissionTypeMap(2002, PermissionType.Action, 'createEmployees'));
    this.map.set(2003, new PermissionTypeMap(2003, PermissionType.Action, 'viewEmployeeDetails'));
    this.map.set(2004, new PermissionTypeMap(2004, PermissionType.Action, 'viewEmployeeFullSin'));
    this.map.set(2005, new PermissionTypeMap(2005, PermissionType.Action, 'editEmployeeEmploymentHistory'));
    this.map.set(2006, new PermissionTypeMap(2006, PermissionType.Action, 'viewRemittances'));
    this.map.set(2007, new PermissionTypeMap(2007, PermissionType.Action, 'createAndEditRemittances'));
    this.map.set(2008, new PermissionTypeMap(2008, PermissionType.Action, 'viewRemittanceDetails'));
    this.map.set(2009, new PermissionTypeMap(2009, PermissionType.Action, 'viewEmployerUsersTable'));
    this.map.set(2010, new PermissionTypeMap(2010, PermissionType.Action, 'createEmployerUsers'));
    this.map.set(2011, new PermissionTypeMap(2011, PermissionType.Action, 'viewEmployerUserDetails'));
    this.map.set(2012, new PermissionTypeMap(2012, PermissionType.Action, 'editEmployerUserDetails'));
  }
}
