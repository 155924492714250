<app-loading [options]="loadingOpts$ | async" *ngIf="(loadingOpts$ | async)?.isLoading"></app-loading>
<app-breadcrumb-header
  [includeBanner]="(alertIsActive$ | async) && !(isCreatingAlert$ | async)"
  [headerTitle]="pageTitle$ | async"
  [breadcrumbs]="breadcrumbs$ | async">
  <div
    class="active-alert"
    *ngIf="(alertIsActive$ | async) && !(isEditingAlert$ | async) && !(isCreatingAlert$ | async)">
    <img [src]="'assets/icons/alerts/AlertActive.svg'" />
    <span class="active-alert-text" i18n>The alert is active</span>
  </div>
  <div
    class="edit-active-alert"
    *ngIf="(alertIsActive$ | async) && (isEditingAlert$ | async) && !(isCreatingAlert$ | async)">
    <img [src]="'assets/icons/alerts/EditActiveAlert.svg'" />
    <span class="edit-active-alert-text" i18n
      >You are editing an active alert. Any changes you publish will be visible to users who are targeted by this
      alert.</span
    >
  </div>
</app-breadcrumb-header>
<div class="banner-spacer" *ngIf="(alertIsActive$ | async) && !(isCreatingAlert$ | async)"></div>
<div>
  <div
    class="banner-preview-container"
    *ngIf="showBannerAlertPreview$ | async"
    [style.margin-bottom.rem]="0"
    [style.margin-top.rem]="-1">
    <app-banner-alert
      *ngIf="!(previewLoading$ | async)"
      [style.margin-bottom.rem]="2"
      [alert]="alertPreview$ | async"></app-banner-alert>
    <lib-loading-shimmer
      *ngIf="previewLoading$ | async"
      [width]="'100%'"
      [height]="'2.815rem'"
      [style.margin-bottom.rem]="2"></lib-loading-shimmer>
  </div>
  <div class="card-form-content-container" [style.margin-top.rem]="0">
    <div class="card-form-container">
      <div class="d-flex justify-content-end gap-3">
        <div *ngIf="canDeleteAlert$ | async" class="edit-button-container">
          <lib-button-outlined (buttonClicked)="openDeleteModal()">
            <img [src]="'assets/icons/primary/Garbage.svg'" />
            <span i18n>Delete</span>
          </lib-button-outlined>
        </div>
        <div
          *ngIf="
            !(isPastAlert$ | async) &&
            (canEditAlertDetails$ | async) &&
            !(isEditingAlert$ | async) &&
            !(isCreatingAlert$ | async)
          "
          class="edit-button-container">
          <lib-button-outlined (buttonClicked)="toggleIsEditingAlert()">
            <img [src]="'assets/icons/primary/EditIcon.svg'" />
            <span i18n>Edit Alert</span>
          </lib-button-outlined>
        </div>
      </div>

      <lib-reactive-form-group
        #form
        [bindTo]="alert$ | async"
        [permissionMap]="(permissionMap$ | async) ?? undefined"
        [customErrorMessageMap]="dateErrorMap"
        [applyDisabledStylesToAllElements]="true"
        [includeIconWithError]="true"
        (formChanged$)="updatePreview($event)"
        (formSubmitted$)="submitForm($event)">
        <div [ngClass]="{ 'alert-inputs': !(isCreatingAlert$ | async) }">
          <!-- Alert Appearance-->
          <app-base-card [height]="'100%'" class="alert-details-card" [ngClass]="{ 'disable-card': false }">
            <div class="card-header">
              <span i18n>Appearance</span>
            </div>
            <div class="card-subtitle mt-1 mb-4" i18n *ngIf="(isCreatingAlert$ | async) || (isEditingAlert$ | async)">
              Choose <span class="card-highlight">Card</span> for more detailed messages and a button with a custom
              destination. If your message is more succinct, choose <span class="card-highlight">Banner</span>.
            </div>
            <lib-reactive-form-capture-radio-buttons [bindingProperty]="'typeId'" [inputName]="'typeId'">
              <div class="radio-title" i18n>Message Style</div>
              <div class="radio-container">
                <lib-reactive-form-radio-button
                  [inputName]="'card'"
                  [value]="AlertTypeEnum.Card"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [permissionFieldId]="'canEditAlerts'"
                  (valueChanged)="setAlertType($event)">
                  <span i18n>Card</span>
                </lib-reactive-form-radio-button>
                <lib-reactive-form-radio-button
                  [inputName]="'banner'"
                  [value]="AlertTypeEnum.Banner"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [permissionFieldId]="'canEditAlerts'"
                  (valueChanged)="setAlertType($event)">
                  <span i18n>Banner</span>
                </lib-reactive-form-radio-button>
              </div>
            </lib-reactive-form-capture-radio-buttons>
            <hr class="radio-divider" />
            <lib-reactive-form-capture-radio-buttons
              [bindingProperty]="'colourId'"
              [permissionFieldId]="'canEditAlerts'"
              [inputName]="'colourId'"
              [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
              [required]="true">
              <div class="radio-title" i18n>Colour Scheme</div>
              <div class="radio-container">
                <lib-reactive-form-radio-button
                  *ngFor="let b of cardRadioButtons$ | async"
                  [hidden]="!(isCardTypeAlert$ | async)"
                  [permissionFieldId]="'canEditAlerts'"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [inputName]="b.name"
                  [value]="b.value">
                  {{ b.name }}
                </lib-reactive-form-radio-button>
                <lib-reactive-form-radio-button
                  *ngFor="let b of bannerRadioButtons$ | async"
                  [hidden]="isCardTypeAlert$ | async"
                  [permissionFieldId]="'canEditAlerts'"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [inputName]="b.name"
                  [value]="b.value">
                  {{ b.name }}
                </lib-reactive-form-radio-button>
              </div>
            </lib-reactive-form-capture-radio-buttons>
            <hr class="radio-divider mb-0" />
            <div [style.display]="(isCardTypeAlert$ | async) ? 'none' : 'flex'" [style.height.rem]="3">
              <div class="radio-title dismissal" i18n>Allow Dismissal</div>
              <div class="radio-container mt-4" [hidden]="isCardTypeAlert$ | async">
                <lib-reactive-form-switch
                  #dismissableSwitch
                  [inputName]="'dismissable'"
                  [permissionFieldId]="'canEditAlerts'"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [selectedValue]="true"
                  [notSelectedValue]="false"
                  [bindingProperty]="'dismissable'">
                </lib-reactive-form-switch>
                <hr class="divider" />
              </div>
            </div>
            <div [style.display]="(isCardTypeAlert$ | async) ? 'flex' : 'none'" [style.height.rem]="3">
              <div class="radio-title dismissal" i18n>Include Button</div>
              <div class="radio-container mt-4" [hidden]="!(isCardTypeAlert$ | async)">
                <lib-reactive-form-switch
                  [inputName]="'includeButton'"
                  [permissionFieldId]="'canEditAlerts'"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [selectedValue]="true"
                  [notSelectedValue]="false"
                  (valueChanged)="includeButtonChanged($event)"
                  [bindingProperty]="'includeButton'">
                </lib-reactive-form-switch>
                <hr class="divider" />
              </div>
            </div>
          </app-base-card>
          <!-- Content -->
          <app-base-card class="alert-details-card" [ngClass]="{ 'alert-inputs': !(isCreatingAlert$ | async) }">
            <span class="card-header mb-4" i18n>Message Content</span>
            <lib-reactive-form-column-layout [nColumns]="1" [columnGap]="'1.5rem'">
              <lib-reactive-form-text
                [inputName]="'title'"
                [bindingProperty]="'title'"
                [permissionFieldId]="'canEditAlerts'"
                [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                [required]="true"
                label="Title"
                i18n-label
                (valueChanged)="previewValueChanged()"
                [maxLength]="maxAlertTitleLength"
                [showCharacterCount]="true">
              </lib-reactive-form-text>
              <lib-reactive-form-text-area
                [inputName]="'description'"
                [bindingProperty]="'description'"
                [permissionFieldId]="'canEditAlerts'"
                [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                [required]="true"
                label="Description"
                i18n-label
                (valueChanged)="previewValueChanged()"
                [maxLength]="maxAlertDescriptionLength"
                [showCharacterCount]="true">
              </lib-reactive-form-text-area>
              <div [hidden]="!(isCardTypeAlert$ | async) || !(includeButton$ | async)">
                <lib-reactive-form-text
                  [inputName]="'buttonText'"
                  [bindingProperty]="'buttonText'"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [required]="(isCardTypeAlert$ | async) && (includeButton$ | async)"
                  label="Button Text"
                  i18n-label
                  (valueChanged)="previewValueChanged()"
                  [permissionFieldId]="'canEditAlerts'"
                  [maxLength]="maxAlertButtonLength"
                  [showCharacterCount]="true">
                </lib-reactive-form-text>
                <lib-reactive-form-text
                  [inputName]="'buttonLink'"
                  [bindingProperty]="'buttonLink'"
                  (valueChanged)="previewValueChanged()"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [required]="(isCardTypeAlert$ | async) && (includeButton$ | async)"
                  [permissionFieldId]="'canEditAlerts'"
                  label="Button Link"
                  i18n-label
                  hintText="Button links will automatically open in a new browser tab"
                  i18n-hintText
                  [placeholder]="'https://'">
                </lib-reactive-form-text>
              </div>
            </lib-reactive-form-column-layout>
          </app-base-card>
          <app-base-card class="alert-details-card">
            <span class="card-header mb-4" i18n>Alert Duration</span>
            <lib-reactive-form-column-layout [nColumns]="1" [columnGap]="'1.5rem'">
              <lib-reactive-form-row [gap]="'2rem'">
                <lib-reactive-form-masked-input
                  [inputName]="'startDateString'"
                  [mask]="dateMask"
                  [permissionFieldId]="'canEditAlerts'"
                  label="Start Date"
                  i18n-label
                  hintText="DD / MM / YYYY"
                  i18n-hintText
                  [required]="true"
                  [bindingProperty]="'startDateString'"
                  [showCalButton]="true"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [editable]="false"
                  [customValueParser]="StringExtensions.convertDateFormatForBackend"
                  [customValidators]="[validDateDirective]"
                  [programmaticallyChangeValue]="selectedStartDate$ | async"
                  [showingCalendar]="showStartDatePicker"
                  (calButtonClicked)="toggleStartDatePicker()">
                </lib-reactive-form-masked-input>
                <div
                  [hidden]="!showStartDatePicker"
                  [ngClass]="{ 'start-create': (isCreatingAlert$ | async) }"
                  class="start-date-picker-wrapper">
                  <!--                  TODO: Investigate why Click Ahead does not work here (SPP-696)-->
                  <app-date-picker
                    (dateSelected)="setStartDate($event)"
                    [initialSelectedDates]="datePickerStartDate$ | async"></app-date-picker>
                </div>
                <lib-reactive-form-time-dropdown
                  [required]="true"
                  [inputName]="'startTime'"
                  [bindingProperty]="'startTime'"
                  [permissionFieldId]="'canEditAlerts'"
                  [dropdownOnLeft]="true"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  label="Start Time"
                  i18n-label
                  hintText="Time in CST"
                  i18n-hintText>
                </lib-reactive-form-time-dropdown>
              </lib-reactive-form-row>
              <lib-reactive-form-row [gap]="'2rem'">
                <lib-reactive-form-masked-input
                  [inputName]="'endDateString'"
                  [mask]="dateMask"
                  label="End Date"
                  i18n-label
                  [required]="true"
                  [permissionFieldId]="'canEditAlerts'"
                  [bindingProperty]="'endDateString'"
                  [showCalButton]="true"
                  [editable]="false"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [customValueParser]="StringExtensions.convertDateFormatForBackend"
                  [customValidators]="[validDateDirective, startEndDateDirective]"
                  hintText="DD / MM / YYYY"
                  i18n-hintText
                  [programmaticallyChangeValue]="selectedEndDate$ | async"
                  [showingCalendar]="showEndDatePicker"
                  (calButtonClicked)="toggleEndDatePicker()">
                </lib-reactive-form-masked-input>
                <div
                  [hidden]="!showEndDatePicker"
                  [ngClass]="{ 'end-create': (isCreatingAlert$ | async) }"
                  class="end-date-picker-wrapper">
                  <!--                  TODO: Investigate why Click Ahead does not work here (SPP-696)-->
                  <app-date-picker
                    (dateSelected)="setEndDate($event)"
                    [initialSelectedDates]="datePickerEndDate$ | async"></app-date-picker>
                </div>
                <lib-reactive-form-time-dropdown
                  [required]="true"
                  [inputName]="'endTime'"
                  [bindingProperty]="'endTime'"
                  [permissionFieldId]="'canEditAlerts'"
                  [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
                  [customValidators]="[startEndDateDirective]"
                  [dropdownOnLeft]="true"
                  label="End Time"
                  i18n-label
                  hintText="Time in CST"
                  i18n-hintText>
                </lib-reactive-form-time-dropdown>
              </lib-reactive-form-row>
            </lib-reactive-form-column-layout>
          </app-base-card>
          <app-base-card>
            <span class="card-header mb-4" i18n>Targeting Parameters</span>
            <lib-reactive-form-capture-radio-buttons
              [bindingProperty]="'portalTypeId'"
              [inputName]="'portalType'"
              [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)"
              [required]="true">
              <div class="radio-title" i18n>Audience</div>
              <div class="radio-container">
                <lib-reactive-form-column-layout [nColumns]="3">
                  <lib-reactive-form-radio-button
                    [inputName]="'member'"
                    [value]="PortalTypeIdEnum.Member"
                    [permissionFieldId]="'canEditAlerts'"
                    [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)">
                    <span i18n>Member Portal</span>
                  </lib-reactive-form-radio-button>
                  <lib-reactive-form-radio-button
                    [inputName]="'employer'"
                    [value]="PortalTypeIdEnum.Employer"
                    [permissionFieldId]="'canEditAlerts'"
                    [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)">
                    <span i18n>Employer Portal</span>
                  </lib-reactive-form-radio-button>
                  <lib-reactive-form-radio-button
                    [inputName]="'internal'"
                    [value]="PortalTypeIdEnum.Internal"
                    [permissionFieldId]="'canEditAlerts'"
                    [disabled]="!(isEditingAlert$ | async) && !(isCreatingAlert$ | async)">
                    <span i18n>Internal Portal</span>
                  </lib-reactive-form-radio-button>
                </lib-reactive-form-column-layout>
              </div>
            </lib-reactive-form-capture-radio-buttons>
          </app-base-card>
        </div>
      </lib-reactive-form-group>
    </div>
    <div class="card-form-action-container">
      <app-base-card
        *ngIf="!(isCreatingAlert$ | async)"
        [width]="'100%'"
        class="d-flex"
        [style.margin-top]="(isPastAlert$ | async) ? '2.15rem' : '5.375rem'">
        <div class="d-flex flex-column" [style.gap.rem]="1">
          <div class="overview-title-container">
            <div class="card-title" [style.margin-bottom.rem]="0" i18n>Alert Overview</div>
          </div>
          <div class="d-flex flex-column gap-4">
            <app-submission-details-list-item label="Status" i18n-label [statusPill]="statusPill$ | async">
            </app-submission-details-list-item>
            <app-submission-details-list-item
              label="Alert Start Date"
              i18n-label
              [text]="(alertStartDate$ | async) ?? '-'">
            </app-submission-details-list-item>
            <app-submission-details-list-item label="Alert End Date" i18n-label [text]="(alertEndDate$ | async) ?? '-'">
            </app-submission-details-list-item>
            <app-submission-details-list-item
              label="Last Edited"
              i18n-label
              [text]="(alertLastUpdatedDate$ | async) ?? '-'">
            </app-submission-details-list-item>
          </div>
        </div>
      </app-base-card>
      <app-base-card
        [height]="'100%'"
        [padding]="'1.5rem'"
        class="d-flex"
        [style.margin-top]="(isCreatingAlert$ | async) ? '2.15rem' : '0'">
        <div class="d-flex flex-column gap-3">
          <div class="preview-title">
            <span class="preview" i18n>Preview</span>
            <lib-reactive-form-group>
              <lib-reactive-form-switch
                [inputName]="'showPreview'"
                [programmaticallyChangeValue]="showAlertPreview$ | async"
                [selectedValue]="true"
                [notSelectedValue]="false"
                (clicked)="toggleShowAlertPreview()">
              </lib-reactive-form-switch>
            </lib-reactive-form-group>
          </div>
          <div class="preview-subtext" i18n>View how desktop SPP Portal users will see your alert.</div>
        </div>
      </app-base-card>
      <app-card-alert
        *ngIf="(showCardAlertPreview$ | async) && !(previewLoading$ | async)"
        [alert]="alertPreview$ | async"></app-card-alert>
      <lib-loading-shimmer
        *ngIf="(showCardAlertPreview$ | async) && (previewLoading$ | async)"
        [width]="'100%'"
        [height]="'14.875rem'"></lib-loading-shimmer>
    </div>
  </div>
</div>
<div class="action-button-container">
  <div
    class="action-buttons"
    *ngIf="((canEditAlertDetails$ | async) && (isEditingAlert$ | async)) || (isCreatingAlert$ | async)">
    <lib-button-neutral i18n (buttonClicked)="cancelClicked()">Cancel</lib-button-neutral>
    <lib-button-primary (buttonClicked)="form.submitForm()">{{ actionButtonText$ | async }}</lib-button-primary>
  </div>
</div>
