export enum ScheduledRemittanceStatusPrimaryColorEnum {
  Pending = '#B24819',
  Scheduled = '#104255',
  Editing = '#5D5B24',
  Past = '#3B3B3C'
}

export enum ScheduledRemittanceStatusSecondaryColorEnum {
  Pending = '#FFEAD1',
  Scheduled = '#DCEBFA',
  Editing = '#FAFADC',
  Past = '#FAFAFA'
}

export enum ScheduledRemittanceStatusBorderColorEnum {
  Pending = '#FB932A',
  Scheduled = '#68C3EB',
  Editing = '#C1BA4A',
  Past = '#B9B9B9'
}

/* eslint-disable @typescript-eslint/naming-convention */
export const ScheduledRemittanceStatusPrimaryColorMap: Record<number, string> = {
  1: ScheduledRemittanceStatusPrimaryColorEnum.Pending,
  2: ScheduledRemittanceStatusPrimaryColorEnum.Scheduled,
  3: ScheduledRemittanceStatusPrimaryColorEnum.Editing,
  4: ScheduledRemittanceStatusPrimaryColorEnum.Past
};

export const ScheduledRemittanceStatusSecondaryColorMap: Record<number, string> = {
  1: ScheduledRemittanceStatusSecondaryColorEnum.Pending,
  2: ScheduledRemittanceStatusSecondaryColorEnum.Scheduled,
  3: ScheduledRemittanceStatusSecondaryColorEnum.Editing,
  4: ScheduledRemittanceStatusSecondaryColorEnum.Past
};

export const ScheduledRemittanceStatusBorderColourMap: Record<number, string> = {
  1: ScheduledRemittanceStatusBorderColorEnum.Pending,
  2: ScheduledRemittanceStatusBorderColorEnum.Scheduled,
  3: ScheduledRemittanceStatusBorderColorEnum.Editing,
  4: ScheduledRemittanceStatusBorderColorEnum.Past
};
