<div class="auth-flow-title">
  {{ viewModel.authFlowTitle$ | async | localization }}
</div>

<div class="auth-flow-subtext">
  <div>
    {{ 'You’re almost there! We sent an email with a verification code to' }}
    <span>{{ viewModel.newMemberEmail$ | async }}</span>
    {{ '. Please allow 5-10 minutes for this message to arrive.' | localization }}
  </div>
  <div class="mt-3">
    {{
      'Click on the link in the email to complete your account setup. If you don’t see an email from us,' | localization
    }}
    <span class="auth-highlighted-text"> {{ 'you may need to check your spam folder.' | localization }}</span>
  </div>
  <div class="mt-3">
    {{ 'Still cant find the email?' | localization }}
  </div>
</div>

<div class="action-button-container">
  <lib-button-primary [style.width.%]="100">{{ 'Resend Email' | localization }}</lib-button-primary>
</div>
<div class="member-support-button-container">
  {{ 'Having trouble?' | localization }}
  <button class="paddingless-text-button support-text mt-2" (click)="viewModel.openContactPage()">
    {{ 'Click here for support' | localization }}
  </button>
</div>
