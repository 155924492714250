<app-header-container>
  <div header-content>
    <app-back-button *ngIf="showBackButton" (backClicked)="backButtonClicked.emit()"></app-back-button>
    <div class="header-row mt-1">
      <div *ngIf="title !== ''" class="header-text">
        {{ title }}
      </div>
      <div class="button-container">
        <lib-button-neutral *ngIf="cancelButtonLabel !== ''" (buttonClicked)="emitCancelButtonClicked()">{{
          cancelButtonLabel
        }}</lib-button-neutral>
        <lib-button-primary *ngIf="actionButtonLabel !== ''" (buttonClicked)="emitActionButtonClicked()">{{
          actionButtonLabel
        }}</lib-button-primary>
      </div>
    </div>
    <div *ngIf="tabs.length > 0" class="header-row tab-bar">
      <app-tab-bar [tabs]="tabs" (selectedTab)="selectedTab.emit($event)"> </app-tab-bar>
    </div>
  </div>
</app-header-container>
