import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { CustomError } from '../models/shared/custom-error';
import { State } from '../models/types/dto/state';
import { Gender } from '../models/types/dto/gender';
import { Country } from '../models/types/dto/country';
import { SubmissionStatus } from '../models/shared/submission-status';
import { AlertType } from '../models/alerts/dto/alert-types';
import { TransactionEntryCodeType } from '../models/transactions/transaction-entry-code-type';
import { TransactionStatus } from '../models/transactions/transaction-status';
import { Pronoun } from '../models/account/dto/member-user';
import { MfaType } from '../models/types/dto/mfa-type';
import { EmploymentStatusType } from '../models/types/dto/employment-status-type';
import { LeaveDetailsType } from '../models/types/dto/leave-details-type';
import { RemittanceStatusType } from '../models/remittances/remittance-status-type';
import { RemittanceFrequency } from '../models/remittances/remittance-frequency';
import { ScheduledRemittanceStatus } from '../models/remittances/scheduled-remittance-status';

@Injectable({
  providedIn: 'root'
})
export class TypeAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  // Variables

  public serviceName = 'Types';

  // States

  public getStatesByCountry(countryId: number): Observable<State[]> {
    const url = Endpoints.getStatesByCountryId(countryId);
    return this.apiClient.getArr<State>(State, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Genders

  public getGenders(): Observable<Gender[]> {
    const url = Endpoints.getGenders();
    return this.apiClient.getArr<Gender>(Gender, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Pronouns
  public getPronouns(): Observable<Pronoun[]> {
    const url = Endpoints.getPronouns();
    return this.apiClient.getArr<Gender>(Gender, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Countries

  public getCountries(): Observable<Country[]> {
    const url = Endpoints.getCountries();
    return this.apiClient.getArr<Country>(Country, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Submissions

  public getSubmissionStatusTypes(): Observable<SubmissionStatus[]> {
    const url = Endpoints.getSubmissionStatusTypes();
    return this.apiClient.getArr<SubmissionStatus>(SubmissionStatus, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Transactions

  public getTransactionStatusTypes(): Observable<TransactionStatus[]> {
    const url = Endpoints.getTransactionStatusTypes();
    return this.apiClient.getArr<TransactionStatus>(TransactionStatus, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Remittances

  public getRemittanceStatusTypes(): Observable<RemittanceStatusType[]> {
    const url = Endpoints.getRemittanceStatusTypes();
    return this.apiClient.getArr<RemittanceStatusType>(RemittanceStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittancePaymentStatusTypes(): Observable<RemittanceStatusType[]> {
    const url = Endpoints.getRemittancePaymentStatusTypes();
    return this.apiClient.getArr<RemittanceStatusType>(RemittanceStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Contribution Entry Options

  public getContributionEntryCodeTypes(): Observable<TransactionEntryCodeType[]> {
    const url = Endpoints.getContributionEntryOptionTypes();
    return this.apiClient.getArr<TransactionEntryCodeType>(TransactionEntryCodeType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Entries

  public getTransactionEntryCodeTypes(): Observable<TransactionEntryCodeType[]> {
    const url = Endpoints.getTransactionEntryCodeTypes();
    return this.apiClient.getArr<TransactionEntryCodeType>(TransactionEntryCodeType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Alerts

  public getAlertTypes(): Observable<AlertType[]> {
    const url = Endpoints.getAlertTypes();
    return this.apiClient.getArr<AlertType>(AlertType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Multi-Factor Authentication Types

  public getMfaTypes(): Observable<MfaType[]> {
    const url = Endpoints.getMfaTypes();
    return this.apiClient.getArr<AlertType>(AlertType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  // Member Employment History

  public getMemberEmploymentLeaveTypes(): Observable<LeaveDetailsType[]> {
    const url = Endpoints.getMemberEmploymentLeaveTypes();
    return this.apiClient.getArr<LeaveDetailsType>(LeaveDetailsType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberEmploymentStatusTypes(): Observable<EmploymentStatusType[]> {
    const url = Endpoints.getMemberEmploymentStatusTypes();
    return this.apiClient.getArr<EmploymentStatusType>(EmploymentStatusType, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduledRemittanceFrequencyTypes(): Observable<RemittanceFrequency[]> {
    const url = Endpoints.getScheduledRemittanceFrequencyTypes();
    return this.apiClient.getArr<RemittanceFrequency>(RemittanceFrequency, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getScheduledRemittanceStatusTypes(): Observable<ScheduledRemittanceStatus[]> {
    const url = Endpoints.getScheduledRemittanceStatusTypes();
    return this.apiClient.getArr<ScheduledRemittanceStatus>(ScheduledRemittanceStatus, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
