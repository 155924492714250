import { Deserializable } from '../protocols/deserializable';
import { TransactionStatus } from './transaction-status';
import { TransactionSource } from './transaction-source';
import { Currency } from './currency';
import { TransactionEntry } from './transaction-entry';
import { TransactionStatusPill } from '../shared/transaction-status-pill';
import { TransactionComment } from './transaction-comment';

export class Transaction implements Deserializable {
  public id!: number;
  public typeId!: number;
  public typeName!: string;
  public statusId!: number;
  public sourceId!: number;
  public currencyId!: number;
  public createdDate!: Date;
  public createdById!: number;
  public createdByName!: string;
  public createdByUserType!: string;
  public postedDate!: Date;
  public status!: TransactionStatus;
  public source!: TransactionSource;
  public currency!: Currency;
  public entries: TransactionEntry[] = [];
  public comment: TransactionComment | null = null;

  public statusPill!: TransactionStatusPill;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    if (!!this.status) this.status = Deserialize?.instanceOf(TransactionStatus, this.status);
    if (!!this.source) this.source = Deserialize?.instanceOf(TransactionSource, this.source);
    if (!!this.currency) this.currency = Deserialize?.instanceOf(Currency, this.currency);
    if (!!this.entries) this.entries = Deserialize?.arrayOf(TransactionEntry, this.entries);
    if (!!this.postedDate) this.postedDate = new Date(this.postedDate);
    if (!!this.createdDate) this.createdDate = new Date(this.createdDate);
    if (!!this.statusId && !!this.status) this.statusPill = new TransactionStatusPill(this.statusId, this.status.name);
    if (!!this.comment) {
      this.comment = Deserialize?.instanceOf(TransactionComment, this.comment);
    }
  }
}
