// noinspection JSUnusedLocalSymbols

import { inject, Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { SignInRequest } from '../../../models/account/requests/sign-in-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../services/toast-service';
import { PortalType } from '../../../models/enum/shared/portal-type';
import { UserChallengeName } from '../../../models/account/enum/user-challenge.name';
import { PortalService } from '../../../services/portal/portal.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SignInViewModel extends BaseViewModel {
  private readonly loadingMessage = $localize`Signing in`;

  constructor(private userDomainModel: UserDomainModel, public router: Router, public toastService: ToastService) {
    super();
  }

  private portalService = inject(PortalService);

  private _preFillEmail = new BehaviorSubject<string>('');
  public readonly preFillEmail$ = this._preFillEmail as Observable<string>;

  private _preFillCode = new BehaviorSubject<string>('');
  public readonly preFillCode$ = this._preFillCode as Observable<string>;

  public loginTitle$ = this.portalService.portalType$.pipe(
    map(pt => {
      switch (pt) {
        case PortalType.Internal:
          return $localize`Login`;
        case PortalType.Member:
          return $localize`Member Login`;
        case PortalType.Employer:
          return $localize`Employer Portal Login`;
        default:
          return $localize`Login`;
      }
    })
  );

  public getUrlParams(route: ActivatedRoute) {
    route.queryParams.subscribeWhileAlive({
      owner: this,
      next: params => {
        const email = decodeURIComponent(decodeURIComponent(params?.Email || params?.email || ''));
        const code = decodeURIComponent(decodeURIComponent(params?.Code || params?.code || ''));
        if (!!email) this._preFillEmail.next(email);
        if (!!code) this._preFillCode.next(code);
      }
    });
  }

  public internalUserSignIn(req: SignInRequest): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.userDomainModel.signIn(req, PortalType.Internal).subscribe({
      next: user => {
        const { challengeName } = user;

        switch (challengeName) {
          case UserChallengeName.NEW_PASSWORD_REQUIRED:
            this.router.navigate(['/auth/internal-user-choose-password'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.MFA_SETUP:
            this.router.navigate(['/auth/internal-user-auth-setup'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SOFTWARE_TOKEN_MFA:
            this.router.navigate(['/auth/internal-user-verify-code'], { replaceUrl: true }).then(() => {});
            break;
          default:
            this.router.navigate(['/auth/sign-in'], { replaceUrl: true }).then();
            break;
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.loadingMessage);
      }
    });
  }

  public memberSignIn(req: SignInRequest): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.userDomainModel.signIn(req, PortalType.Member).subscribe({
      next: user => {
        const { challengeName } = user;
        switch (challengeName) {
          case UserChallengeName.SOFTWARE_TOKEN_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SMS_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          default:
            this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {});
            break;
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      },
      error: error => {
        if (error.code === 404) {
          this.toastService.publishErrorMessage(
            $localize`Login Failed`,
            $localize`An account for this email does not exist.`
          );
        } else if (error.code === 400) {
          this.toastService.publishErrorMessage(
            $localize`Login Failed`,
            $localize`The information you entered was incorrect.`
          );
        } else {
          this.toastService.publishError(error);
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      }
    });
  }

  public employerSignIn(req: SignInRequest): void {
    this._loadingOpts.addRequest(this.loadingMessage);
    this.userDomainModel.signIn(req, PortalType.Employer).subscribe({
      next: user => {
        const { challengeName } = user;
        switch (challengeName) {
          case UserChallengeName.NEW_PASSWORD_REQUIRED:
            this.router.navigate(['/auth/employer-choose-password'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SOFTWARE_TOKEN_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          case UserChallengeName.SMS_MFA:
            this.router.navigate(['/auth/verify-mfa'], { replaceUrl: true }).then(() => {});
            break;
          default:
            this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {});
            break;
        }
        this._loadingOpts.removeRequest(this.loadingMessage);
      },
      error: error => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(this.loadingMessage);
      }
    });
  }
}
