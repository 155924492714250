import { Component, ElementRef, inject, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss']
})
export class HeaderContainerComponent extends BaseComponent implements OnChanges {
  @Input() includeBanner: boolean = false;
  @Input() isSystemBanner: boolean = false;

  @ViewChild('header') header: ElementRef | undefined;
  @ViewChild('banner') banner: ElementRef | undefined;
  @ViewChild('buffer') buffer: ElementRef | undefined;

  private renderer = inject(Renderer2);
  private headerObserver = new ResizeObserver(() => {
    const headerHeight = this.header?.nativeElement?.offsetHeight;
    const bannerHeight = this.banner?.nativeElement?.offsetHeight ?? 0;
    const totalHeight = headerHeight + bannerHeight;
    this.renderer.setStyle(this.buffer?.nativeElement, 'height', totalHeight + 'px');
  });

  private bannerObserver = new ResizeObserver(() => {
    const headerHeight = this.header?.nativeElement?.offsetHeight ?? 0;
    const bannerHeight = this.banner?.nativeElement?.offsetHeight ?? 0;
    const totalHeight = headerHeight + bannerHeight;
    if (this.isSystemBanner) {
      this.renderer.setStyle(this.banner?.nativeElement, 'margin-top', headerHeight + 'px');
    } else {
      this.renderer.setStyle(this.banner?.nativeElement, 'top', totalHeight + 'px');
    }
  });

  public setupBindings(): void {
    this.initObservers();
  }

  public setupViews(): void {}

  public destroy(): void {
    super.destroy();
    this.headerObserver.disconnect();
    this.bannerObserver.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.includeBanner) {
      this.initObservers();
    }
  }

  private initObservers(): void {
    this.headerObserver.disconnect();
    this.bannerObserver.disconnect();

    if (this.header?.nativeElement) {
      this.headerObserver.observe(this.header.nativeElement);
    }

    if (this.banner?.nativeElement) {
      this.bannerObserver.observe(this.banner.nativeElement);
    }
  }
}
