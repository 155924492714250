export enum AlertStatusEnum {
  Active = 1,
  Past = 2,
  Upcoming = 3
}

export enum AlertStatusStringEnum {
  Active = 'Active',
  Past = 'Past',
  Upcoming = 'Upcoming'
}
