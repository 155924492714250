<div>
  <div class="verification-title-text" i18n>{{ 'Verification is needed to complete your log in' }}</div>
  <div class="auth-flow-subtext" i18n>
    {{
      'Scan the QR code below using the authenticator app of your choice such as Google Authenticator, Authy, or Windows Phone Authenticator.'
    }}
  </div>
  <ng-container *ngIf="viewModel.isMobile$ | async">
    <div class="subtext pt-3">
      {{ 'On Mobile? Click' | localization }}
      <a class="paddingless-text-button copy-text" (click)="clipboardService.copyToClipboard(mfaKey)">
        {{ 'here' | localization }}
      </a>
      {{ 'to copy the code to your clipboard.' | localization }}
    </div>
    <hr />
  </ng-container>
</div>
<div>
  <canvas id="qr-code"></canvas>
  <button class="paddingless-text-button qr-support" (click)="openSupportModal()">
    {{ 'QR Code Support' | localization }}
  </button>
</div>

<div class="auth-flow-subtext pb-4" i18n>
  {{ 'After scanning the QR code, you will be given a six-digit verification code. Please enter it below.' }}
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted(req)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    [label]="'Verification Code' | localization"
    [inlineLabel]="false"
    [placeholder]="''"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>
<lib-button-primary class="w-100" i18n (buttonClicked)="form.submitForm()">
  {{ 'Verify Account' }}
</lib-button-primary>
<div class="internal-support-button-container" i18n>
  <span>{{ 'Having trouble?' }}</span>
  <button class="paddingless-text-button support-text mt-2" (click)="viewModel.openContactPage()">
    {{ 'Click here for support' }}
  </button>
</div>
