<div
  [ngClass]="{
    active: viewModel.isNavItemActive$ | async,
    animating: animating$ | async
  }"
  id="dropdown{{ idSeed }}"
  class="nav-item active custom-nav-item"
  (click)="toggleDropdown()">
  <div *ngIf="viewModel.hasIcons$ | async" class="nav-item-icon">
    <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon" />
    <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled" />
  </div>
  <div class="nav-item-label" [ngClass]="{ 'multi-line-label': viewModel.multiLineNavItem$ | async }">
    {{ navItem.name }}
  </div>
  <div class="nav-item-icon">
    <img [src]="'assets/nav/drop-down-icon.svg'" alt="icon" class="nav-bar-icon" />
    <img [src]="'assets/nav/drop-down-icon-filled.svg'" alt="icon-filled" class="nav-bar-icon-filled" />
  </div>
  <ul [class.open]="showDropdown$ | async" class="custom-nav-dropdown-menu">
    <ng-container *ngFor="let item of navItem.subItems">
      <ng-container>
        <app-nav-sub-item [navItem]="navItem" [subItem]="item" (subItemClicked)="closeDropdown()"> </app-nav-sub-item>
      </ng-container>
    </ng-container>
  </ul>
</div>
