<div *ngIf="reactiveFormGroup$ | async" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <app-odata-typeahead-search
    [odataFunction]="odataFunction"
    placeHolder="Search by name or member ID"
    i18n-placeHolder
    [disabled]="!!selectedMember || disabled"
    [displayValueInline]="true"
    [searchedProperties]="['name', 'id']"
    [customItemTemplate]="lookAheadItemTemplate"
    [popperClass]="'look-ahead-popper-full-width'"
    [hasErrors]="hasErrors$ | async"
    (lookAheadCleared)="memberSelected.emit(undefined)"
    (lookAheadSelected)="memberSelected.emit($event)">
  </app-odata-typeahead-search>
  <lib-reactive-form-general-error
    [parentFormGroupComponent]="reactiveFormGroupComponent"
    [reactiveFormItemComponent]="this"
    [required]="required"
    [disabled]="disableInput$ | async"
    [performValidationWhenDisabled]="performValidationWhenDisabled"
    (hasError)="connectToHasErrors($event)">
  </lib-reactive-form-general-error>
  <input hidden [formControlName]="inputName" [id]="id" [name]="inputName" />
</div>

<ng-template #lookAheadItemTemplate let-member>{{ member.name }} ({{ member.id }}) </ng-template>
