import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { ReleaseNoteDocument } from '../../../../models/release-notes/release-note-document';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReleaseNoteChangeTypeIdEnum } from '../../../../models/enum/shared/release-note-change-type-id.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormattedDateStringInCSTPipe } from '../../pipes/formatted-date-string-in-cst.pipe';

@Component({
  selector: 'app-release-note-modal',
  templateUrl: './release-note-modal.component.html',
  styleUrls: ['./release-note-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseNoteModalComponent extends BaseModalComponent {
  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  public ignoreEscape: boolean = false;

  private _features = new BehaviorSubject<string[]>([]);
  public features$ = this._features as Observable<string[]>;

  private _bugFixes = new BehaviorSubject<string[]>([]);
  public bugFixes$ = this._bugFixes as Observable<string[]>;

  private _versionString = new BehaviorSubject<string>('');
  public versionString$ = this._versionString as Observable<string>;

  private dateTransform = new FormattedDateStringInCSTPipe();

  setReleaseNoteDocument(releaseNoteDocument: ReleaseNoteDocument): void {
    if (!!releaseNoteDocument) {
      this._versionString.next(
        $localize`Version ${releaseNoteDocument.version} released on ${this.dateTransform.transform(
          releaseNoteDocument.releaseDate.toString()
        )}`
      );

      const features = releaseNoteDocument.releaseNotes
        .filter(note => note.releaseNoteChangeTypeId === ReleaseNoteChangeTypeIdEnum.Feature)
        .map(n => n.description);

      this._features.next(features);

      const bugFixes = releaseNoteDocument.releaseNotes
        .filter(note => note.releaseNoteChangeTypeId === ReleaseNoteChangeTypeIdEnum.BugFix)
        .map(n => n.description);

      this._bugFixes.next(bugFixes);
    }
  }

  setupBindings(): void {}

  setupViews(): void {}
}
