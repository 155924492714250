<div>
  <!-- Member Submissions -->
  <div class="title" i18n>Permission Types</div>
  <div class="submission-title-row">
    <div class="type-name"></div>
    <div class="title-check-container">
      <div class="title-container" [class]="'select-all-title-approve'">
        <div class="name-container">
          <span class="submission-type-name" i18n>Select All</span>
        </div>
      </div>

      <div
        class="title-container"
        *ngFor="let type of submissionPermissionTypes"
        [class]="type.id === SubmissionPermissionTypeEnum.ApproveOwn ? 'select-all-title-approve' : 'select-all-title'">
        <div class="name-container" [ngbTooltip]="type.tooltipText" placement="top" triggers="hover">
          <img [src]="type.icon" />
          <span class="submission-type-name">{{ type.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="submission-row">
    <div class="type-name"></div>
    <div class="check-container">
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.All, $event)"
          [partiallySelected]="(somePermissionsSelected$ | async) && !(allPermissionsSelected$ | async)"
          [selectAll]="true"
          [checked]="somePermissionsSelected$ | async"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.View, $event)"
          [partiallySelected]="(memberViewSelectionType$ | async) === SubmissionPermissionSelectionTypeEnum.Some"
          [selectAll]="true"
          [checked]="(memberViewSelectionType$ | async) !== SubmissionPermissionSelectionTypeEnum.None"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.Process, $event)"
          [partiallySelected]="(memberProcessSelectionType$ | async) === SubmissionPermissionSelectionTypeEnum.Some"
          [selectAll]="true"
          [checked]="(memberProcessSelectionType$ | async) !== SubmissionPermissionSelectionTypeEnum.None"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.Approve, $event)"
          [partiallySelected]="(memberApproveSelectionType$ | async) === SubmissionPermissionSelectionTypeEnum.Some"
          [selectAll]="true"
          [checked]="(memberApproveSelectionType$ | async) !== SubmissionPermissionSelectionTypeEnum.None"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.ApproveOwn, $event)"
          [partiallySelected]="(memberApproveOwnSelectionType$ | async) === SubmissionPermissionSelectionTypeEnum.Some"
          [selectAll]="true"
          [checked]="(memberApproveOwnSelectionType$ | async) !== SubmissionPermissionSelectionTypeEnum.None"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.Override, $event)"
          [partiallySelected]="(memberOverrideSelectionType$ | async) === SubmissionPermissionSelectionTypeEnum.Some"
          [selectAll]="true"
          [checked]="(memberOverrideSelectionType$ | async) !== SubmissionPermissionSelectionTypeEnum.None"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
      <div class="checkbox">
        <app-checkbox
          (clickEvent)="selectAll(SubmissionPermissionTypeEnum.Create, $event)"
          [partiallySelected]="(memberCreateSelectionType$ | async) === SubmissionPermissionSelectionTypeEnum.Some"
          [selectAll]="true"
          [checked]="(memberCreateSelectionType$ | async) !== SubmissionPermissionSelectionTypeEnum.None"
          [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
        </app-checkbox>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let type of memberSubmissionTypes">
    <div class="submission-row">
      <div class="type-name">{{ type.name }}</div>
      <div class="check-container">
        <div class="checkbox" *ngFor="let perm of getPermissionsByType(type.id) | async">
          <app-checkbox
            [checked]="perm.selected"
            (clickEvent)="permissionClicked(perm.id, perm.permissionTypeId, perm.memberSubmissionTypeId, $event)"
            [disabled]="!(canEditSubmissionPermissions$ | async) || disableCheckboxes">
          </app-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
</div>
