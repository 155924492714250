export enum RemittanceStatusPrimaryColorEnum {
  Pending = '#B24819',
  Submitted = '#5D5B24',
  InReview = '#B24819',
  Approved = '#395200',
  Failed = '#9D0209'
}

export enum RemittanceStatusSecondaryColorEnum {
  Pending = '#FFEAD1',
  Submitted = '#FAFADC',
  InReview = '#FFEAD1',
  Approved = '#E8F7D9',
  Failed = '#FFEAEB'
}

export enum RemittanceStatusBorderColourEnum {
  Pending = '#FB932A',
  Submitted = '#C1BA4A',
  InReview = '#FB932A',
  Approved = '#78AB00',
  Failed = '#FF8E93'
}

/* eslint-disable @typescript-eslint/naming-convention */
export const RemittanceStatusPrimaryColorMap: Record<number, string> = {
  1: RemittanceStatusPrimaryColorEnum.Pending,
  2: RemittanceStatusPrimaryColorEnum.Submitted,
  3: RemittanceStatusPrimaryColorEnum.InReview,
  4: RemittanceStatusPrimaryColorEnum.Approved,
  5: RemittanceStatusPrimaryColorEnum.Failed
};

export const RemittanceStatusSecondaryColorMap: Record<number, string> = {
  1: RemittanceStatusSecondaryColorEnum.Pending,
  2: RemittanceStatusSecondaryColorEnum.Submitted,
  3: RemittanceStatusSecondaryColorEnum.InReview,
  4: RemittanceStatusSecondaryColorEnum.Approved,
  5: RemittanceStatusSecondaryColorEnum.Failed
};

export const RemittanceStatusBorderColourMap: Record<number, string> = {
  1: RemittanceStatusBorderColourEnum.Pending,
  2: RemittanceStatusBorderColourEnum.Submitted,
  3: RemittanceStatusBorderColourEnum.InReview,
  4: RemittanceStatusBorderColourEnum.Approved,
  5: RemittanceStatusBorderColourEnum.Failed
};

/* eslint-enable @typescript-eslint/naming-convention */
