<div class="d-flex gap-4">
  <div
    *ngFor="let step of steps; let i = index"
    class="step-button"
    [class.step-disabled]="i > selectedIndex"
    [class.step-active]="selected === step"
    [class.step-completed]="i < selectedIndex || (forcedCompletedSteps | setHas : i)"
    [class.step-error]="forcedErrorSteps | setHas : i">
    <img
      class="step-check-icon"
      src="assets/icons/transparent-backgrounds/GreenCheckCircle.svg"
      alt="completed"
      i18n-alt />
    <img
      class="step-error-icon"
      src="assets/icons/transparent-backgrounds/RedXCircle.svg"
      alt="error in step"
      i18n-alt />
    <div class="step-number">{{ i + 1 }}</div>
    <div class="step-title">{{ step.label }}</div>
  </div>
</div>

<div [style.margin-top.rem]="2">
  <ng-container *ngTemplateOutlet="selected?.content"></ng-container>
</div>
