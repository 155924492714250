import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomError } from '../models/shared/custom-error';
import { BannerEventsService } from '../modules/banner-events/service/banner-events.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastr: ToastrService, private bannerEvents: BannerEventsService) {}

  publishError(err: CustomError) {
    if (err.message !== 'Invalid Token!') {
      this.toastr.error(err.message, err.title, {
        payload: { icon: 'assets/icons/dark/Circle-Error.svg', error: true }
      });
    }
  }

  publishErrorMessage(title: string, mess: string = '') {
    this.toastr.error(mess, title, { payload: { icon: 'assets/icons/dark/Circle-Error.svg', error: true } });
  }

  publishSuccessMessage(title: string, mess: string = '') {
    this.toastr.success(mess, title, { payload: { icon: 'assets/icons/dark/Circle-Check.svg', success: true } });
  }

  publishFileMessage(title: string, mess: string = '') {
    this.toastr.success(mess, title, { payload: { icon: 'assets/icons/dark/File.svg' } });
  }

  publishInfoMessage(title: string, mess: string = '') {
    this.toastr.info(mess, title);
  }

  publishUndoMessage(title: string) {
    this.toastr.info('', title, { payload: { undo: true } });
  }

  publishWarningMessage(title: string, mess: string = '') {
    this.toastr.warning(mess, title, { payload: { icon: 'assets/icons/dark/Triangle.svg' } });
  }

  publishBannerSuccess(mess: string) {
    this.bannerEvents.success(mess);
  }

  publishBannerFailed(mess: string) {
    this.bannerEvents.error(mess);
  }
}
