<div class="auth-flow-subtext">
  <div class="header" i18n>{{ 'Set a New Password' }}</div>
  <div class="subtext" i18n>
    {{
      'Set a new password to access your account. You will have to sign back in to your account using your new password once it has been reset. '
    }}
  </div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  [applyDisabledStylesToAllElements]="true"
  [customErrorMessageMap]="passwordErrorMap"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    [inputName]="'disabled-email'"
    [placeholder]="''"
    [label]="'Email' | localization"
    [showRequiredAstrix]="false"
    [bindingProperty]="'email'"
    [disabled]="true"
    [showDisabledLock]="true"
    [required]="true">
  </lib-reactive-form-email>

  <lib-reactive-form-string-number
    [inputName]="'code'"
    [integersOnly]="true"
    [required]="true"
    [showRequiredAstrix]="false"
    [label]="'Code' | localization"
    [customValueParser]="null"
    [disabled]="true"
    [hintText]="'Welcome to my PR This is just an example'"
    [placeholder]="''"
    [bindingProperty]="'code'">
  </lib-reactive-form-string-number>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    [showRequiredAstrix]="false"
    [creatingOrUpdatingPassword]="true"
    [label]="'New Password' | localization"
    [hintText]="passwordHintText"
    [placeholder]="''"
    [bindingProperty]="'newPassword'">
  </lib-reactive-form-password>

  <lib-reactive-form-password
    [inputName]="'confirmPassword'"
    [required]="true"
    [showRequiredAstrix]="false"
    [confirmPassword]="true"
    [label]="'Confirm Password' | localization"
    [placeholder]="''">
  </lib-reactive-form-password>
</lib-reactive-form-group>

<lib-button-primary class="w-100 pt-3" i18n (buttonClicked)="form.submitForm()">
  {{ 'Set New Password' }}
</lib-button-primary>
