<app-member-search-input
  [dispersedKey]="dispersedKey"
  [inputName]="'selectedMember'"
  [required]="required"
  [label]="label"
  [disabled]="disabled"
  label="Member Search"
  i18n-label
  [odataFunction]="memberSearchFunction"
  [bindingProperty]="'selectedMember'"
  [selectedMember]="selectedMember$ | async"
  (memberSelected)="setSelectedMember($event)">
</app-member-search-input>
