import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';
import * as timezone from 'moment-timezone';

@Pipe({
  name: 'formattedDateStringInCST'
})
export class FormattedDateStringInCSTPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const releaseDateInUTC = value.substring(0, value.length - 1);
    const convertedDateTimeCST = timezone
      .tz(releaseDateInUTC, 'UTC')
      .tz('America/Regina')
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
    const formattedDate = moment(convertedDateTimeCST).format('MMM D[,] YYYY');
    const time = convertedDateTimeCST.split('T')[1];
    const formattedTime = moment(time.substring(0, time.length - 1), ['H:mm']).format('h:mm A');
    return formattedDate + ' at ' + formattedTime + ' CST';
  }
}
