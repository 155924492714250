import { Deserializable } from '../../protocols/deserializable';
import * as moment from 'moment';
import { EmploymentStatusType } from '../../types/dto/employment-status-type';
import { LeaveDetails } from './leave-details';

export class EmploymentHistory implements Deserializable {
  id!: number;
  memberId!: number;
  employerId!: number;
  employer!: string;
  employmentStatusId!: number;
  employmentStatus!: EmploymentStatusType;
  leaveDetailsId!: number | null;
  leaveDetails!: LeaveDetails | null;
  startDate!: Date;
  endDate!: Date | null;

  // Not From API
  public formattedStartDate!: string;
  public formattedEndDate!: string | null;

  constructor() {
    this.onDeserialize();
  }

  onDeserialize(): void {
    if (!!this.startDate) {
      const date = moment(this.startDate.toString().split('T')[0]);
      this.startDate = new Date(this.startDate);
      this.formattedStartDate = date.format('DD / MM / YYYY');
    }
    if (!!this.endDate) {
      const date = moment(this.endDate.toString().split('T')[0]);
      this.endDate = new Date(this.endDate);
      this.formattedEndDate = date.format('DD / MM / YYYY');
    }
    if (!!this.employmentStatus) {
      this.employmentStatus = window?.injector?.Deserialize?.instanceOf(EmploymentStatusType, this.employmentStatus);
    }
    if (!!this.leaveDetails) {
      this.leaveDetails = window?.injector?.Deserialize?.instanceOf(LeaveDetails, this.leaveDetails);
    }
    if (!this.leaveDetails) {
      this.leaveDetails = new LeaveDetails();
    }
  }
}
