import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-section-header',
  templateUrl: './filter-section-header.component.html',
  styleUrls: ['./filter-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSectionHeaderComponent {
  @Input() sectionTitle: string = '';
  @Output() resetClicked = new EventEmitter<void>();
}
