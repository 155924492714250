import { BaseRemittance } from './base-remittance';
import { Deserializable } from '../protocols/deserializable';
import { RemittanceFrequency, RemittanceFrequencyEnum } from './remittance-frequency';
import { ScheduledRemittanceComment } from './scheduled-remittance-comment';
import { ScheduledRemittanceContribution } from './scheduled-remittance-contribution';
import { ScheduledRemittanceLinkedRemittance } from './scheduled-remittance-linked-remittance';
import { ScheduledRemittanceHistory } from './scheduled-remittance-history';
import { ScheduledRemittanceContributionSummary } from './scheduled-remittance-contribution-summary';
import { ScheduledRemittanceStatusPill } from '../shared/scheduled-remittance-status-pill';
import { ScheduledRemittanceStatusId } from '../enum/shared/scheduled-remittance-status.id';

export class ScheduledRemittance extends BaseRemittance implements Deserializable {
  public frequencyId!: RemittanceFrequencyEnum;
  public frequency!: RemittanceFrequency;
  public name!: string;
  public startDate!: Date;
  public endDate!: Date | null;
  public contributionSummary!: ScheduledRemittanceContributionSummary | null;
  public comments: ScheduledRemittanceComment[] = [];
  public contributions: ScheduledRemittanceContribution[] = [];
  public history: ScheduledRemittanceHistory[] = [];
  public linkedRemittances!: ScheduledRemittanceLinkedRemittance[];

  // not from API
  public statusPill: ScheduledRemittanceStatusPill | undefined;

  public contributionEntriesAreEditable(): boolean {
    return this.statusId !== ScheduledRemittanceStatusId.Past;
  }

  onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    this.frequency = Deserialize?.instanceOf(RemittanceFrequency, this.frequency);
    this.startDate = new Date(this.startDate);
    if (this.endDate) this.endDate = new Date(this.endDate);
    if (this.contributionSummary) {
      Deserialize?.instanceOf(ScheduledRemittanceContributionSummary, this.contributionSummary);
    }
    this.comments = Deserialize?.arrayOf(ScheduledRemittanceComment, this.comments ?? []);
    this.contributions = Deserialize?.arrayOf(ScheduledRemittanceContribution, this.contributions ?? []);
    this.linkedRemittances = Deserialize?.arrayOf(ScheduledRemittanceLinkedRemittance, this.linkedRemittances ?? []);
    if (!!this.status) this.statusPill = new ScheduledRemittanceStatusPill(this.status.id, this.status.name);
  }
}
