import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { LookAheadItem } from '../search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
// @ts-ignore
import { PopperContent } from 'ngx-popper';
import { OdataTypeaheadSearchViewModel } from './odata-typeahead-search-view-model';
import { PopperUtils } from '../../../../utils/popper-utils';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-odata-typeahead-search',
  templateUrl: './odata-typeahead-search.component.html',
  styleUrls: ['./odata-typeahead-search.component.scss'],
  providers: [OdataTypeaheadSearchViewModel]
})
export class OdataTypeaheadSearchComponent extends BaseComponent implements OnChanges {
  @Input() disablePopper: boolean = false;
  @Input() placeHolder: string = 'Search';
  @Input() nLookAheadItems: number = 5;
  @Input() lookAheadItems: LookAheadItem[] = [];
  @Input() label: string = '';
  @Input() searchedProperties!: string[];
  @Input() useProfileListItems: boolean = false;
  @Input() disabled: boolean = false;
  @Input() odataFunction!: (searchString: string) => Observable<LookAheadItem[]>;
  @Input() hasErrors: boolean = false;
  @Input() popperClass: string = 'look-ahead-popper';
  @Input() customItemTemplate: TemplateRef<any> | undefined;
  @Input() showNoResults: boolean = false;
  @Input() displayValueInline: boolean = false;
  @Input() hideLabel: boolean = false;
  @Output() searchText = new EventEmitter(true);
  @Output() lookAheadSelected = new EventEmitter<any>(true);
  @Output() lookAheadCleared = new EventEmitter<any>(true);
  @ViewChild('popperContent') popperContent: PopperContent;
  @ViewChild('searchBar') searchBar!: ElementRef<HTMLInputElement>;

  private viewModel = inject(OdataTypeaheadSearchViewModel);

  private currentSearchText: string = '';

  public isLoading$ = this.viewModel.isLoading$;
  public loadingOptions$ = this.viewModel.loadingOpts$;

  public selectedItem: LookAheadItem | null = null;

  constructor() {
    super();
  }

  setupBindings(): void {
    this.searchText.pipe(takeUntil(this.onDestroy)).subscribe(text => this.viewModel.connectToSearchText(text));

    this.viewModel.lookAheadHits$.pipe(takeUntil(this.onDestroy)).subscribe(hits => {
      if (hits?.length > 0 && !this.disablePopper) {
        this.popperContent.show();
      }

      if (hits?.length === 0 && !this.disablePopper && !this.showNoResults) {
        this.popperContent.hide();
      }

      if (hits?.length === 0 && this.showNoResults && this.currentSearchText === '') {
        this.popperContent.hide();
      }

      this.lookAheadItems = hits?.slice(0, this.nLookAheadItems);
    });

    this.viewModel.isLoading$.pipe(takeUntil(this.onDestroy)).subscribe(isLoading => {
      if (isLoading) this.popperContent.show();
    });
  }

  setupViews(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.odataFunction) this.viewModel.connectToOdataFunction(this.odataFunction);
  }

  public searchChanged(e: Event) {
    const target = e.target as HTMLInputElement;
    this.currentSearchText = target.value;
    this.searchText.emit(target.value);
  }

  public clearSearch() {
    this.searchText.emit('');
    this.searchBar.nativeElement.value = '';
  }

  public itemSelected(item: LookAheadItem) {
    this.lookAheadSelected.emit(item);
    this.clearSearch();
    this.popperContent.hide();
    if (this.displayValueInline && item.lookAheadDisplayValue) {
      this.selectedItem = item;
      this.searchBar.nativeElement.value = item.lookAheadDisplayValue();
    }
  }

  public clearSelectedItem(): void {
    this.selectedItem = null;
    this.searchBar.nativeElement.value = '';
    this.lookAheadCleared.emit();
  }

  public handleClickOutside() {
    this.popperContent.hide();
  }

  public popperModifier = [PopperUtils.flipModifier(['bottom', 'top']), PopperUtils.offsetModifier([0, 0])];
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };
  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;
  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;
}
