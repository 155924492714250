import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-primary-back-button',
  templateUrl: './primary-back-button.component.html',
  styleUrls: ['./primary-back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryBackButtonComponent {
  @Input() paddingBottomRem = 0;
  @Input() label = $localize`Back`;
  @Output() backClicked = new EventEmitter(true);
}
