import { RemittanceFileRecord } from './remittance-file-record';
import { ContributionEntry } from './contribution-entry';
import { Deserializable } from '../protocols/deserializable';
import { DateUtils } from '../../utils/date-utils';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { BaseRemittanceContribution } from './base-remittance-contribution';
import { RemittanceHistory } from './remittance-history';

export class Contribution extends BaseRemittanceContribution implements Deserializable, UniquelyIdentifiable {
  public remittanceId!: number;
  public memberId: number | null = null;
  public history: RemittanceHistory[] = [];

  constructor(record: RemittanceFileRecord) {
    super();
    this.socialInsuranceNumber = record.SIN.toString(10);
    this.memberFirstName = record['FIRST NAME'];
    this.memberLastName = record['LAST NAME'];
    this.birthDate = DateUtils.convertCSVDateStringToUTCDate(record['BIRTH DATE']).toISOString();
    this.entries = [new ContributionEntry(record)];
    this.history = window?.injector?.Deserialize?.arrayOf(RemittanceHistory, this.history ?? []);
  }

  public getRemittanceId(): number {
    return this.remittanceId;
  }

  getUniqueIdentifier(): string {
    return `${super.getUniqueIdentifier()}-
    ${this.remittanceId}-
    ${this.memberId}-
    `;
  }
}
