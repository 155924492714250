<div
  [ngClass]="{
    active: viewModel.isNavItemActive$ | async
  }"
  class="nav-item active custom-nav-item"
  ngbAccordion>
  <div class="mobile-dropdown-accordion" ngbAccordionItem [collapsed]="true">
    <div ngbAccordionToggle>
      <div *ngIf="viewModel.hasIcons$ | async" class="nav-item-icon">
        <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon" />
        <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled" />
      </div>
      <div class="nav-item-label" [ngClass]="{ 'multi-line-label': viewModel.multiLineNavItem$ | async }">
        {{ navItem.name }}
      </div>
      <div class="nav-item-drop-down-icon">
        <img [src]="'assets/nav/drop-down-icon.svg'" alt="icon" class="nav-bar-icon" />
        <img [src]="'assets/nav/drop-down-icon-filled.svg'" alt="icon-filled" class="nav-bar-icon-filled" />
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <ul class="sub-item-list">
              <ng-container *ngFor="let item of navItem.subItems">
                <ng-container>
                  <app-mobile-nav-sub-item [navItem]="navItem" [subItem]="item"> </app-mobile-nav-sub-item>
                </ng-container>
              </ng-container>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
