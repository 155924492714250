<div class="alert-container" [style.background-color]="backgroundColour$ | async">
  <div class="alert-body">
    <div [style.color]="titleColour$ | async" class="alert-title">{{ title$ | async }}</div>
    <div [style.color]="textColour$ | async" class="alert-description">
      {{ description$ | async }}
    </div>
    <div class="button-container">
      <button
        *ngIf="!!(buttonText$ | async)"
        [style.background-color]="accentColour$ | async"
        (click)="handleClick()"
        class="alert-button"
        type="button">
        {{ buttonText$ | async }}
      </button>
    </div>
  </div>
</div>
