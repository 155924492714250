import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmployersDomainModel } from '../../../../domainModels/employers-domain-model';
import { EmployerSearchResult } from '../../../../models/shared/employer-search-result';

@Component({
  selector: 'app-employer-search',
  templateUrl: './employer-search.component.html',
  styleUrls: ['./employer-search.component.scss']
})
export class EmployerSearchComponent {
  @Input() dispersedKey = '';
  @Output() selectedEmployer = new EventEmitter<EmployerSearchResult>();

  private employersDomainModel = inject(EmployersDomainModel);

  public employerSearchFunction = (searchString: string) =>
    this.employersDomainModel.searchEmployersByIdOrName(searchString);

  private _selectedEmployer = new BehaviorSubject<EmployerSearchResult | null>(null);
  public selectedEmployer$ = this._selectedEmployer as Observable<EmployerSearchResult | null>;
  public setSelectedEmployer(employer: EmployerSearchResult | null) {
    this._selectedEmployer.next(employer);
    if (!!employer) this.selectedEmployer.next(employer);
  }
}
