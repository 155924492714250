<div class="auth-title-text">
  {{ authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  {{ authFlowSubtext$ | async }}
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-birthday
    [inputName]="'birthdate'"
    label="Birthdate"
    i18n-label
    [bindingProperty]="'birthdate'"
    [required]="true"
    [placeholder]="''"
    [showRequiredAstrix]="false"
    [hintText]="'DD / MM / YYYY'">
  </lib-reactive-form-birthday>

  <lib-reactive-form-masked-input
    [inputName]="'sin'"
    [required]="true"
    label="Last 3 Digits of Your SIN"
    i18n-label
    [placeholder]="'••• ••• 000'"
    (keydown.enter)="form.submitForm()"
    [bindingProperty]="'sin'"
    [mask]="mask">
  </lib-reactive-form-masked-input>
</lib-reactive-form-group>

<div class="action-button-container">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()" i18n>Continue</lib-button-primary>
</div>
<div class="member-support-button-container">
  <span i18n>Having trouble?</span>
  <button class="paddingless-text-button support-text mt-2" (click)="openContactPage()" i18n>
    Click here for support
  </button>
</div>
