<div class="viewing-container">
  <span i18n>Viewing</span>
  <div class="top" (click)="toggleDropdown()" clickOutside (onClickOutside)="closeDropdown()">
    <span>{{ top }}</span>
    <img [src]="(showDropdown$ | async) ? 'assets/icons/dark/Chevron Up.svg' : 'assets/icons/dark/Chevron Down.svg'" />
  </div>
  <span i18n>Rows</span>
</div>
<div class="dropdown">
  <div class="dropdown-content" *ngIf="showDropdown$ | async">
    <ng-container *ngFor="let option of options">
      <div
        class="top-item"
        (click)="setSelection(option)"
        [ngClass]="{ selected: top === option }"
        [style.padding-left]="top === option ? '0.5rem' : '1rem'">
        <img *ngIf="top === option" [src]="'assets/icons/grey/Check.svg'" />
        <span>{{ option }}</span>
      </div>
    </ng-container>
  </div>
</div>
