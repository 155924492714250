import { Component, Input } from '@angular/core';
import { TabBarItem } from '../tab-bar-item';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss']
})
export class TabContentComponent extends BaseComponent {
  @Input() tab!: TabBarItem;
  @Input() showContent: boolean = true;
  @Input() useDefaultHeight: boolean = false;

  setupBindings(): void {}

  setupViews(): void {}
}
