<div>
  <lib-reactive-form-group
    #searchForm
    [bindTo]="searchForm$ | async"
    [applyDisabledStylesToAllElements]="true"
    [includeIconWithError]="true"
    (formSubmitted$)="formSubmitted($event)">
    <ng-container *ngIf="!(useDatePicker$ | async)">
      <lib-reactive-form-text
        [disabled]="disabled"
        [inputName]="(bindingProperty$ | async) ?? ''"
        [bindingProperty]="(bindingProperty$ | async) ?? ''"
        [placeholder]="(placeholder$ | async) ?? ''"
        (valueChanged)="searchForm.submitForm()">
      </lib-reactive-form-text>
    </ng-container>

    <ng-container *ngIf="useDatePicker$ | async">
      <lib-reactive-form-masked-input
        #dateInput
        [disabled]="disabled"
        [inputName]="(bindingProperty$ | async) ?? ''"
        [mask]="dateMask"
        [placeholder]="(placeholder$ | async) ?? ''"
        [bindingProperty]="(bindingProperty$ | async) ?? ''"
        [customValueParser]="StringExtensions.convertDateFormatForBackend"
        [showCalButton]="true"
        [editable]="editable"
        [calButtonIconHeightRem]="1.25"
        [calButtonIconTopPercent]="55"
        [showingCalendar]="shouldShowDatePicker"
        [programmaticallyChangeValue]="selectedDate$ | async"
        [customValidators]="[validDateDirective]"
        (valueChanged)="searchForm.submitForm(); checkDateValue($event)"
        (calButtonClicked)="calendarClicked()">
      </lib-reactive-form-masked-input>
      <div
        #datePicker
        class="date-picker-wrapper"
        [style.display]="'none'"
        clickOutside
        (onClickOutside)="onClickOutside()">
        <app-date-picker [initialSelectedDates]="selectedDates$ | async" (dateSelected)="handleDateSelection($event)">
        </app-date-picker>
      </div>
    </ng-container>
  </lib-reactive-form-group>
</div>
