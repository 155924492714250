import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemBanner } from '../../../../models/shared/system-banner';

@Component({
  selector: 'app-system-banner',
  templateUrl: './system-banner.component.html',
  styleUrls: ['./system-banner.component.scss']
})
export class SystemBannerComponent {
  @Input() banner!: SystemBanner;
  @Input() dismissIconSrc: string = 'assets/icons/blue/X.svg';
  @Output() dismissBannerClicked = new EventEmitter<void>();
  @Output() textButtonClicked = new EventEmitter<void>();
}
