import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { MembersAPI } from '../api/members-api';
import { UserDomainModel } from './user-domain-model';
import { EmployerUser } from '../models/account/dto/employer-user';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MemberUser } from '../models/account/dto/member-user';
import { CreateMemberRequest } from '../models/account/requests/create-member-request';
import { MemberOnboardingInfoRequest } from '../models/account/requests/member-onboarding-info-request';
import { SessionService } from '../services/session-service';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { HttpResponse } from '@angular/common/http';
import { MemberUserSearchResult } from '../models/account/dto/member-user-search-result';
import { MemberRecordCommentRequest } from '../models/account/requests/member-record-comment-request';
import { MemberRecordComment } from '../views/records/components/members/view-member-record/member-record-comments/member-record-comment';
import { Mentionable } from '../models/base/mentionable';
import { ODataResponse } from '../models/protocols/odata-response';
import { EmploymentHistory } from '../models/account/dto/employment-history';
import { ResendMemberInviteRequest } from '../models/account/requests/resend-member-invite-request';
import { MostRecentInvite } from '../models/account/dto/most-recent-invite';

// Provided by Logged In Scope
@Injectable()
export class MembersDomainModel extends BaseDomainModel {
  constructor(private membersAPI: MembersAPI, private session: SessionService) {
    super();
    this.init();
  }

  private userDomainModel = inject(UserDomainModel);

  private readonly employerUser$ = this.userDomainModel.user$.pipe(map(user => user as EmployerUser));
  private employerId$ = this.employerUser$.pipe(map(user => user?.employerId.toString()));

  public createMember(req: CreateMemberRequest): Observable<MemberUser> {
    return this.employerId$.pipe(
      switchMap(employerId => {
        return this.membersAPI.createMember(req, employerId);
      })
    );
  }

  public addCommentToMemberRecord(req: MemberRecordCommentRequest): Observable<MemberRecordComment[]> {
    return this.membersAPI.addCommentToMemberRecord(req).pipe(
      map(c => {
        return c;
      })
    );
  }

  public updateMemberOnboardingInformation(req: MemberOnboardingInfoRequest): Observable<MemberUser> {
    return this.membersAPI.updateMemberOnboardingInformation(req).pipe(
      map(u => {
        this.session.updateUser(u);
        return u;
      })
    );
  }

  public getMemberById(memberId: string, odataQueryOptions: ODataQueryOptions): Observable<MemberUser> {
    return this.membersAPI.getMemberById(memberId, odataQueryOptions);
  }

  public getMemberCommentMentionables(odataQueryOptions: ODataQueryOptions): Observable<Mentionable[]> {
    return this.membersAPI.getMemberCommentMentionables(odataQueryOptions).pipe(map(r => r.value));
  }

  public forceChangeEmail(u: MemberUser, req: ChangeEmailRequest): Observable<MemberUser> {
    return this.membersAPI.forceChangeEmail(u, req);
  }

  public forceChangePassword(u: MemberUser): Observable<HttpResponse<any>> {
    return this.membersAPI.forceChangePassword(u);
  }

  public searchMembersByIdOrName(searchString: string): Observable<MemberUserSearchResult[]> {
    const queryOptions = new ODataQueryOptions();
    const searchStringIsId = !isNaN(Number(searchString));
    if (searchStringIsId) {
      queryOptions.setFilter(`id eq ${searchString}`);
    } else {
      queryOptions.setFilter(`contains(Name, '${searchString}')`);
    }
    return this.membersAPI.searchMembersByIdOrName(queryOptions).pipe(map(r => r.value));
  }

  public getAllMembers(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<MemberUser>> {
    return this.membersAPI.getAllMembers(odataQueryOptions);
  }

  public updateEmployerHistory(req: EmploymentHistory): Observable<EmploymentHistory> {
    return this.membersAPI.updateMemberEmploymentHistory(req);
  }

  public createEmployerHistory(req: EmploymentHistory): Observable<EmploymentHistory> {
    return this.membersAPI.createEmployerHistory(req, req.memberId.toString());
  }

  public getMemberEmploymentHistory(
    memberId: string,
    odataOptions: ODataQueryOptions
  ): Observable<EmploymentHistory[]> {
    return this.membersAPI.getEmployerHistoryForMember(memberId, odataOptions);
  }

  public resendMemberInvite(memberId: string, req: ResendMemberInviteRequest): Observable<MostRecentInvite> {
    return this.membersAPI.resendMemberInvite(memberId, req);
  }
}
