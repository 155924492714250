<div class="modal-body sticky modal-container" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row justify-content-between">
      <div class="modal-title">{{ title }}</div>
      <app-close-button *ngIf="!hideClose" (closeClicked)="closeClicked.emit()"></app-close-button>
    </div>
  </div>
  <div class="sticky-body-container">
    <ng-content></ng-content>
  </div>
  <div class="sticky-footer-container modal-footer-flex-end">
    <ng-container *ngIf="!hideSecondary">
      <app-secondary-button
        [size]="BaseButtonSizeEnum.sm"
        [colour]="BaseButtonColourEnum.Blue"
        *ngIf="secondaryButtonStyle === SecondaryButtonStyleEnum.Secondary"
        [ngClass]="{ 'secondary-button': !hidePrimary }"
        (buttonClicked)="secondaryClicked.emit()">
        {{ secondaryLabel }}
      </app-secondary-button>
      <app-tertiary-button
        [size]="BaseButtonSizeEnum.sm"
        [colour]="BaseButtonColourEnum.Blue"
        *ngIf="secondaryButtonStyle === SecondaryButtonStyleEnum.Tertiary"
        [ngClass]="{ 'secondary-button': !hidePrimary }"
        (buttonClicked)="secondaryClicked.emit()">
        {{ secondaryLabel }}
      </app-tertiary-button>
    </ng-container>

    <app-primary-button
      [size]="BaseButtonSizeEnum.sm"
      [colour]="BaseButtonColourEnum.Blue"
      (buttonClicked)="primaryClicked.emit()"
      appThrottleClick
      *ngIf="!hidePrimary">
      {{ primaryLabel }}
    </app-primary-button>
  </div>
</div>
