import { inject, Injectable } from '@angular/core';
import { RemittanceFrequencyEnum } from '../../../models/remittances/remittance-frequency';
import { ScheduledRemittance } from '../../../models/remittances/scheduled-remittance';
import { RemittanceDomainModel } from '../../../domainModels/remittance-domain-model';
import { Observable, of } from 'rxjs';
import { ScheduledRemittanceStatusId } from '../../../models/enum/shared/scheduled-remittance-status.id';
import * as moment from 'moment';

@Injectable()
export class NextRemittanceDatesService {
  private remittanceDomainModel = inject(RemittanceDomainModel);

  public getNextSubmissionDates(
    frequency: RemittanceFrequencyEnum,
    startDate: string,
    endDate: string = '',
    schedule?: ScheduledRemittance
  ): Observable<string[]> {
    if (schedule?.statusId === ScheduledRemittanceStatusId.Past) return of([]);

    const startDateIsInThePast = startDate && moment(startDate).isBefore(moment().startOf('day'));
    if (schedule?.statusId === ScheduledRemittanceStatusId.Pending && startDateIsInThePast) {
      return of([]);
    }

    if (!!frequency && frequency !== RemittanceFrequencyEnum.Once && startDate) {
      return this.remittanceDomainModel.getNextFiveRemittanceDates(frequency, startDate, endDate);
    } else {
      return of([]);
    }
  }
}
