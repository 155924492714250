import { Deserializable } from '../protocols/deserializable';
import { ContributionEntry } from './contribution-entry';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export abstract class BaseRemittanceContribution implements Deserializable, UniquelyIdentifiable {
  public id!: string;
  public socialInsuranceNumber!: string;
  public memberId!: number | null;
  public memberFirstName!: string;
  public memberLastName!: string;
  public memberFullName!: string;
  public birthDate!: string;
  public internalUserVerified!: boolean;
  public entrySumAmount!: number | null;
  public entries: ContributionEntry[] = [];

  public abstract getRemittanceId(): number;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.entries = Deserialize?.arrayOf(ContributionEntry, this.entries ?? []);
  }

  getUniqueIdentifier(): string {
    return `${this.id}-
    ${this.socialInsuranceNumber}-
    ${this.memberId}-
    ${this.memberFirstName}-
    ${this.memberLastName}-
    ${this.memberFullName}-
    ${this.birthDate}-
    ${this.internalUserVerified}-
    ${this.entrySumAmount}-
    ${this.entries.map(e => e.getUniqueIdentifier()).join(',')}`;
  }
}
