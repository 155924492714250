import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { EmployersAPI } from '../api/employers-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { EmployerUser } from '../models/account/dto/employer-user';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CreateEmployerUserRequest } from '../models/account/requests/create-employer-user-request';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { HttpResponse } from '@angular/common/http';
import { CreateMemberRequest } from '../models/account/requests/create-member-request';
import { MemberUser } from '../models/account/dto/member-user';
import { UserDomainModel } from './user-domain-model';
import { EmployerSearchResult } from '../models/shared/employer-search-result';
import { Employer } from '../models/shared/employer';

// Provided by Logged In Scope
@Injectable()
export class EmployersDomainModel extends BaseDomainModel {
  constructor(private employersAPI: EmployersAPI) {
    super();
  }

  private userDomainModel = inject(UserDomainModel);

  private _employerUsers = new BehaviorSubject<EmployerUser[] | null>(null);
  public employerUsers$ = this._employerUsers as Observable<EmployerUser[]>;

  private employerId$ = this.userDomainModel.user$.pipe(map(u => (u as EmployerUser)?.employerId?.toString(10)));

  private _employees = new BehaviorSubject<MemberUser[] | null>(null);
  public employees$ = this._employees as Observable<MemberUser[]>;

  private _employerUsersTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public employerUsersTotalCount$ = this._employerUsersTotalCount as Observable<number | undefined>;

  private _employeesTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public employeesTotalCount$ = this._employeesTotalCount as Observable<number | undefined>;

  public getEmployerUsers(
    oDataQueryOptions: ODataQueryOptions,
    employerId: string
  ): Observable<ODataResponse<EmployerUser>> {
    return this.employersAPI.getUsersForEmployers(oDataQueryOptions, employerId).pipe(
      tap(odataRes => this._employerUsersTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._employerUsers.next(odataRes.value)),
      catchError(err => {
        this._employerUsers.next([]);
        this._employerUsersTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getEmployees(oDataQueryOptions: ODataQueryOptions, employerId: string): Observable<ODataResponse<MemberUser>> {
    return this.employersAPI.getMembersForEmployer(oDataQueryOptions, employerId).pipe(
      tap(odataRes => this._employeesTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._employees.next(odataRes.value)),
      catchError(err => {
        this._employees.next([]);
        this._employeesTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getFilteredEmployees(filterString: string): Observable<MemberUser[]> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setFilter(`contains(firstName, '${filterString}')` + `or contains(lastName, '${filterString}')`);
    return this.employerId$.pipe(
      switchMap(id => {
        return this.employersAPI.getMembersForEmployer(queryOptions, id).pipe(map(res => res.value));
      })
    );
  }

  public getFilteredEmployeeIds(filterString: string): Observable<MemberUser[]> {
    const queryOptions = new ODataQueryOptions();
    queryOptions.setFilter(`contains(cast(id, 'Edm.String'), '${filterString}')`);
    return this.employerId$.pipe(
      switchMap(id => {
        return this.employersAPI
          .getMembersForEmployer(queryOptions, id)
          .pipe(map(res => res.value.sort((a, b) => a.id - b.id)));
      })
    );
  }

  public getEmployerUserById(employerId: string, employerUserId: string): Observable<EmployerUser> {
    return this.employersAPI.getEmployerUserById(employerId, employerUserId);
  }

  public getEmployerById(employerId: string): Observable<Employer> {
    return this.employersAPI.getEmployerById(employerId);
  }

  public createEmployerUser(req: CreateEmployerUserRequest, employerId: string): Observable<EmployerUser> {
    return this.employersAPI.createEmployerUser(req, employerId);
  }

  public createMemberUser(req: CreateMemberRequest, employerId: string): Observable<MemberUser> {
    return this.employersAPI.createMemberUser(req, employerId);
  }

  public updateEmployerUser(u: EmployerUser): Observable<EmployerUser> {
    return this.employersAPI.updateEmployerUser(u);
  }

  public forceChangeEmail(u: EmployerUser, req: ChangeEmailRequest): Observable<EmployerUser> {
    return this.employersAPI.forceChangeEmail(u, req);
  }

  public forceChangePassword(u: EmployerUser): Observable<HttpResponse<any>> {
    return this.employersAPI.forceResetPassword(u);
  }

  public searchEmployersByIdOrName(searchString: string): Observable<EmployerSearchResult[]> {
    const queryOptions = new ODataQueryOptions();
    const searchStringIsId = !isNaN(Number(searchString));
    if (searchStringIsId) {
      queryOptions.setFilter(`id eq ${searchString}`);
    } else {
      queryOptions.setFilter(`contains(Name, '${searchString}')`);
    }
    return this.employersAPI.searchEmployersByIdOrName(queryOptions).pipe(map(r => r.value));
  }
}
