import { Deserializable } from '../../protocols/deserializable';

export class SubmissionPermissionType implements Deserializable {
  public id!: number;
  public name!: string;
  public icon!: string;
  public tooltipText!: string;

  onDeserialize() {}
}
