export class ConfirmationOptions {
  // Text
  public title: string = '';
  public bodyText: string = '';
  public bodyTextAsHtml: boolean = false;
  // CTAs
  public cancelText: string = $localize`Cancel`;
  public showContinue: boolean = true;
  public showCancel: boolean = true;
  public continueText: string = $localize`Continue`;
}
