import { BaseRemittanceContribution } from './base-remittance-contribution';
import { RemittanceNewMemberDetails } from './base-remittance-new-member';
import { ScheduledRemittanceHistory } from './scheduled-remittance-history';

export class ScheduledRemittanceContribution extends BaseRemittanceContribution {
  public scheduledRemittanceId!: number;
  public history: ScheduledRemittanceHistory[] = [];
  public newMemberDetails!: RemittanceNewMemberDetails | null;

  public getRemittanceId(): number {
    return this.scheduledRemittanceId;
  }

  public onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    this.history = window?.injector?.Deserialize?.arrayOf(ScheduledRemittanceHistory, this.history ?? []);
    if (this.newMemberDetails) Deserialize?.instanceOf(RemittanceNewMemberDetails, this.newMemberDetails);
  }

  public getUniqueIdentifier(): string {
    return `${super.getUniqueIdentifier()}-
    ${this.scheduledRemittanceId}-`;
  }
}
