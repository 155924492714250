import { Deserializable } from '../protocols/deserializable';
import { RemittanceSource } from './remittance-source';
import { Attachment } from '../submissions/attachment';
import { BaseType } from '../base/base-type';
import { PortalType } from '../enum/shared/portal-type';

export abstract class BaseRemittance implements Deserializable {
  public id!: number;
  public employerId!: number;
  public employerName!: string;
  public statusId!: number;
  public sourceId!: number;
  public source!: RemittanceSource;
  public status!: BaseType;
  public submittedDate!: Date | null;
  public taxationYear!: Date | null;
  public totalRemittanceAmount!: number | null;
  public contributionCount!: number | null;
  public contributionEntryCount!: number | null;
  public attachmentForUpload!: Attachment | null;
  public lastUpdatedDate!: Date;
  public lastStatusUpdateDate!: Date;

  public abstract contributionEntriesAreEditable(portal?: PortalType): boolean;

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    if (this.source) Deserialize?.instanceOf(RemittanceSource, this.source);
    if (this.status) Deserialize?.instanceOf(BaseType, this.status);
    if (this.submittedDate) this.submittedDate = new Date(this.submittedDate);
    if (this.taxationYear) this.taxationYear = new Date(this.taxationYear);
    if (this.attachmentForUpload) Deserialize?.instanceOf(Attachment, this.attachmentForUpload);
    if (this.lastUpdatedDate) this.lastUpdatedDate = new Date(this.lastUpdatedDate);
    if (this.lastStatusUpdateDate) this.lastStatusUpdateDate = new Date(this.lastStatusUpdateDate);
  }
}
