import { BaseComponent } from '../../../../../models/base/base-component';
import { BaseMemberRecordCardViewModel } from './base-member-record-card-view-model';
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MemberUser } from '../../../../../models/account/dto/member-user';
import { AccordionStatusService } from '../../../../profile/shared/services/accordion-status-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScreenService } from '../../../../../services/screen-service.service';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  template: ''
})
export abstract class BaseMemberRecordCardComponent extends BaseComponent implements OnChanges {
  protected abstract viewModel: BaseMemberRecordCardViewModel;

  public abstract panelName: string;

  @Input() user!: MemberUser;
  @Input() submissionPending!: boolean;
  @Output() userUpdated = new EventEmitter<MemberUser>();

  protected accordionStatusService = inject(AccordionStatusService);
  private screenService = inject(ScreenService);

  public abstract loadingOpts$: Observable<LoadingOptions>;
  public abstract isLoading$: Observable<boolean>;
  public isMobile$ = this.screenService.isMobile$;

  public expanded$ = new BehaviorSubject<boolean>(false);

  private _disableAccordion = new BehaviorSubject<boolean>(false);
  public disableAccordion$ = this._disableAccordion as Observable<boolean>;

  abstract formSubmitted(req: any): void;

  public toggleEditMode() {
    this.accordionStatusService.toggleAccordion(this.panelName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.viewModel.setUser(this.user);
    }
  }

  setupBindings(): void {
    this.accordionStatusService.accordionStatuses$.subscribeWhileAlive({
      owner: this,
      next: m => {
        const disable = Array.from(m.entries()).some(([key, value]) => key !== this.panelName && value);
        this._disableAccordion.next(disable);
        this.expanded$.next(m.get(this.panelName) ?? false);
      }
    });

    this.viewModel.userUpdated$.subscribeWhileAlive({
      owner: this,
      next: user => {
        if (!!user) {
          this.userUpdated.emit(user);
          this.accordionStatusService.closeAllAccordions();
        }
      }
    });
  }

  setupViews(): void {}
}
