import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss']
})
export class BaseCardComponent {
  @Input() public height: string = '100%';
  @Input() public maxWidth: string = '100%';
  @Input() public maxHeight: string = '100%';
  @Input() public display: string = 'block';
  @Input() public flexDirection: string = 'row';
  @Input() public width: string = '100%';
  @Input() public padding: string = '2rem';
  @Input() public gap: string = '0';
  @Input() public backgroundColor: string = '#ffffff';
  @Input() public borderColor: string = '#cacaca';
  @Input() public boxShadow: string =
    '0 0 1px 0 rgba(129, 114, 75, 0.07), 0 10px 13px 0 rgba(129, 114, 75, 0.01),\n' +
    '  0 10px 11px 0 rgba(129, 114, 75, 0.02), 0 2px 8px 0 rgba(129, 114, 75, 0.03), 0 0 4px 0 rgba(129, 114, 75, 0.1)';
  @Input() public useBorder: boolean = false;
  @Input() public borderRadius: string = '0.875rem';
}
