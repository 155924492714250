import { Deserializable } from '../protocols/deserializable';
import { Attachment } from './attachment';

export class SubmissionAttachment implements Deserializable {
  public id!: string;
  public memberSubmissionId!: string;
  public attachmentId!: string;
  public attachment!: Attachment;
  public uploadedDuringSubmission!: boolean;

  onDeserialize(): void {
    if (!!this.attachment) {
      this.attachment = window?.injector?.Deserialize.instanceOf(Attachment, this.attachment);
    }
  }
}
