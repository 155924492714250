import { Deserializable } from '../protocols/deserializable';

export abstract class BaseRemittanceContributionSummary implements Deserializable {
  public totalContributionCount!: number;
  public totalEntryCount!: number;
  public totalAmount!: number;
  public entrySummaries!: EntrySummary[];

  onDeserialize(): void {}
}

export class EntrySummary implements Deserializable {
  public entryTypeId!: number;
  public entryType!: string;
  public entryCount!: number;
  public amount!: number;

  onDeserialize() {}
}
