import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PortalService } from '../../../../../../services/portal/portal.service';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { PortalType } from '../../../../../../models/enum/shared/portal-type';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { AuthWrapperViewModel } from '../../../../viewModels/auth-wrapper-view-model';

@Component({
  selector: 'app-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss', '../../auth.component.scss']
})
export class AuthWrapperComponent extends BaseComponent implements OnChanges {
  // View Properties
  private _splashImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly splashImage$ = this._splashImage.asObservable();

  @Input() loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(public viewModel: AuthWrapperViewModel, public portalService: PortalService) {
    super();
  }

  setupBindings(): void {}

  setupViews(): void {
    this.portalService.portalType$.once(type => {
      switch (type) {
        case PortalType.Member:
          this._splashImage.next('assets/img/auth/spp-splash.svg');
          break;
        case PortalType.Employer:
          this._splashImage.next('assets/img/auth/spp-splash.svg');
          break;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.loadingOpts) this.viewModel.setLoadingOpts(this.loadingOpts);
  }
}
