import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';

export class ModalUtils {
  public static confirmationModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.mediumStatic(injector);
  }

  public static defaultModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.mediumStatic(injector);
  }

  public static defaultLargeModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.largeStatic(injector);
  }

  static reorderModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  public static defaultExtraLargeModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.extraLargeStatic(injector);
  }

  public static ignoreEscapeModalOptions(injector: Injector): NgbModalOptions {
    return {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  private static mediumStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  // @ts-ignore
  private static largeStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  // @ts-ignore
  private static extraLargeStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      injector
    };
  }
}
